import {RProfile} from "../../../types";
import {create} from "zustand";
import {devtools} from "zustand/middleware";
import {DeepClone} from "../../../utils/DeepClone";
import axios from "axios";
import URLConstants from "../../../api/URLConstants";
import {useDeleteResponse} from "./DeleteResponseAlert";
import {useProfileAdmin} from "./ProfileAdminState";

interface ProfileDeleteModalState {
  profile: RProfile | undefined
  isOpen: boolean
  open: (profile: RProfile) => void
  close: () => void
  delete: () => void
}

const useProfileDelete = create<ProfileDeleteModalState>()(devtools((set,get) => ({
  profile: undefined,
  isOpen: false,
  open: (profile: RProfile) => {
    set({profile: DeepClone.clone(profile), isOpen:true})
  },
  close: () => {
    set({profile: undefined, isOpen: false})
  },
  delete: () => {
    axios.delete<boolean>(`${URLConstants.getURL()}/portal/profile/user/${get().profile.id}`)
      .then(r => {
        if(r.status===200){
          useProfileAdmin.getState().deleteSharedProfile(get().profile.id)
          useDeleteResponse.getState().open(get().profile.name, true)
        }else{
          useDeleteResponse.getState().open(get().profile.name, false)
        }
      })
  }
})))

export {useProfileDelete}