import {combineReducers} from "@reduxjs/toolkit";
import positions from "./slices/positionSlice"


const global = combineReducers({
    positions
})

export type RootState = ReturnType<typeof global>

export default global;