import {combineReducers} from "redux";
import AdminConst from "../AdminConst";

const customer = (state={data:[]}, action=null) => {
    let idx = 0;
    switch(action.type){
        case AdminConst.NM_FETCH_CUSTOMER:
            return {...state, data:action.payload};
        case AdminConst.ADD_NAME_MAPPING:
            idx = state.data.findIndex((item) => item.entity.id === action.payload.entity.id);
            return {
                ...state,
                data: [ ...state.data.slice(0, idx), action.payload, ...state.data.slice(idx+1, state.data.length)]
            };
        case AdminConst.REMOVE_NAME_MAPPING:
            idx = state.data.findIndex((item) => item.entity.id === action.payload.entity.id);
            return {
                ...state,
                data: [ ...state.data.slice(0, idx), action.payload, ...state.data.slice(idx+1, state.data.length)]
            };
        default:
            return state;
    }
};

const adminNameMapping = combineReducers({customer});
export  {adminNameMapping};