import AppConst from "../../../AppConst";
import RequestEntity from "../../../api/RequestEntity";
import URLConstants from "../../../api/URLConstants";
import PositionManagementConst from "../const/PositionManagementConst";

const BASE_URL = URLConstants.getURL();

const PositionManagementActions = {
    fetchCustomer: () => {
        return {type: AppConst.AJAX_CALL, entity: new RequestEntity(BASE_URL + "/portal/customer/list", "GET", PositionManagementConst.ADMIN_POSITIONS_FETCH_CUSTOMER, null)}
    },
    fetchPositionsByCustomer: () => {
        return (dispatch, getState) => {
            dispatch({type: AppConst.AJAX_CALL, entity: new RequestEntity(BASE_URL + "/portal/admin/job/" + getState().admin.positionManagement.selectedCustomer.customer.entity.id + "/list", "GET", PositionManagementConst.ADMIN_POSITIONS_FETCH_POSITIONS)})
        }
    },
    fetchPositionByCustomerAndPortaluser: () => {
        return (dispatch, getState) => {
            let pu = getState().admin.positionManagement.selectedPortaluser.portaluser.id;
            let cust = getState().admin.positionManagement.selectedCustomer.customer.entity.id;
            dispatch({type: AppConst.AJAX_CALL, entity: new RequestEntity(BASE_URL + "/portal/admin/job/customer/" + cust + "/portaluser/"  + pu + "/list", "GET", PositionManagementConst.ADMIN_POSITIONS_FETCH_POSITIONS_BY_PORTALUSER)})
        }
    },
    fetchPortaluserByCustomer:(customer) => {
        return {type:AppConst.AJAX_CALL, entity: new RequestEntity(BASE_URL + "/portal/user/" + customer.entity.id + "/list", "GET", PositionManagementConst.ADMIN_POSITIONS_FETCH_PORTALUSER)}
    },
    selectCustomer: (customer) => {
        return (dispatch, getState) => {
            dispatch({type: PositionManagementConst.ADMIN_POSITIONS_SELECT_CUSTOMER, payload: {customer:customer}});
            dispatch(PositionManagementActions.fetchPortaluserByCustomer(customer));
        };
    },
    selectPortaluser: (portaluser) => {
        return (dispatch, getState) => {
            dispatch({type: PositionManagementConst.ADMIN_POSITIONS_SELECT_PORTALUSER, payload: {portaluser: portaluser}});
            dispatch(PositionManagementActions.fetchPositionsByCustomer());
            dispatch(PositionManagementActions.fetchPositionByCustomerAndPortaluser());
        };
    },
    addPosition:(jobId) => {
        return (dispatch, getState) => {
            let cust = getState().admin.positionManagement.selectedCustomer.customer.entity.id;
            let pUser = getState().admin.positionManagement.selectedPortaluser.portaluser.id;
            dispatch({type: AppConst.AJAX_CALL, entity: new RequestEntity(BASE_URL + "/portal/admin/job/" + jobId + "/customer/" + cust + "/portaluser/" + pUser + "/create",
                    "POST",
                    PositionManagementConst.ADMIN_POSITIONS_ADD_POSITION_FOR_PORTALUSER)
            });
        }
    },
    removePosition: (jobId) => {
        return (dispatch, getState) => {
            let cust = getState().admin.positionManagement.selectedCustomer.customer.entity.id;
            let pUser = getState().admin.positionManagement.selectedPortaluser.portaluser.id;
            dispatch({type: AppConst.AJAX_CALL, entity: new RequestEntity(BASE_URL + "/portal/admin/job/" + jobId + "/customer/" + cust + "/portaluser/" + pUser + "/delete",
                    "DELETE",
                    PositionManagementConst.ADMIN_POSITIONS_DELETE_POSITION_FOR_PORTALUSER)
            });
        }
    }
};

export default PositionManagementActions;