import {RProfile, RProfileUser} from "../../../types";
import {create} from "zustand";
import {devtools} from "zustand/middleware";
import {DeepClone} from "../../../utils/DeepClone";
import {useEditResponse} from "./EditResponseAlert";
import axios from "axios";
import URLConstants from "../../../api/URLConstants";
import {useProfileAdmin} from "./ProfileAdminState";

interface ProfileEditModalState {
  profile: RProfile | undefined
  isOpen: boolean
  open: (profile: RProfile) => void
  close: () => void
  update: (profile: RProfile) => void
  save: () => void
}


const useProfileEdit = create<ProfileEditModalState>()(devtools((set,get) => ({
  profile: undefined,
  isOpen: false,
  open: (profile: RProfile) => {
    set({profile: DeepClone.clone(profile), isOpen: true})
  },
  close:() => {
    set({profile: undefined, isOpen: false})
  },
  update: (profile:RProfile) => {
    set({profile:profile})
  },
  save:() => {
    axios.put<RProfileUser>(`${URLConstants.getURL()}/portal/profile/user/${get().profile.id}`, get().profile)
      .then(r => {
        if(r.status === 200){
          useProfileAdmin.getState().replaceSharedProfile(r.data);
          useEditResponse.getState().open(`${r.data.profile.name}`, true)
          set({profile: DeepClone.clone(r.data.profile)})
        }else{
          useEditResponse.getState().open(`${r.data.profile.name}`, false)
        }
      })
  }
})))

export {useProfileEdit}