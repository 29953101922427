import AppConst from "../../../AppConst";
import RequestEntity from "../../../api/RequestEntity";
import URLConstants from "../../../api/URLConstants";
import AdminConst from "../AdminConst";

const BASE_URL = URLConstants.getURL();

const AdminNameMappingActions = {
    fetchCustomers: () => {
        return {type: AppConst.AJAX_CALL, entity: new RequestEntity(BASE_URL + "/portal/customer/sync", "GET", AdminConst.NM_FETCH_CUSTOMER)}
    },
    addMapping:(id) => {
        return {type: AppConst.AJAX_CALL, entity: new RequestEntity(BASE_URL + "/portal/customer/" + id + "/nmadd", "GET", AdminConst.ADD_NAME_MAPPING)}
    },
    removeMapping:(id) => {
        return {type: AppConst.AJAX_CALL, entity: new RequestEntity(BASE_URL + "/portal/customer/" + id + "/nmremove", "GET", AdminConst.REMOVE_NAME_MAPPING)}
    }
};

export default AdminNameMappingActions;