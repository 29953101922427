/**
 * Created by stephanpudras on 29.10.18.
 */

import React from 'react';
import {connect} from 'react-redux';
import {withNamespaces, withTranslation} from "react-i18next";

const mapStateToProps = (state) => {
    return {
    }
};

const mapDispatchToProps = (dispatch) => {
    return {}
};

class HelpPopover extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="popoverHelp">
                <h4>{this.props.t("help_popover:" + this.props.module + "." + this.props.element + ".header")}</h4>
                <div>
                    <p>{this.props.t("help_popover:" + this.props.module + "." + this.props.element + ".text")}</p>
                    <div>
                        {
                            Object.keys(this.props.t("help_popover:" + this.props.module + "." + this.props.element + ".elements", {returnObjects:true})).map((key, idx) => {
                                return <div key={this.props.module + "_" + this.props.element + "_" + idx}>
                                    <h5>{this.props.t("help_popover:" + this.props.module + "." + this.props.element + ".elements." + key + ".header")}</h5>
                                    <p>{this.props.t("help_popover:" + this.props.module + "." + this.props.element + ".elements." + key + ".text")}</p>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
}
const helpPopoverTrans = withTranslation(["help_popover"])(HelpPopover);
export default connect(mapStateToProps, mapDispatchToProps)(helpPopoverTrans)