import ListFormContext from "../../context/ListFormContext";
import * as React from "react";

class SingleFileInput extends React.Component {
    static contextType = ListFormContext
    constructor(props,context) {
        super(props, context);
        this.validate = this.validate.bind(this);
        this.reset = this.reset.bind(this);
        this.state = {valueValid: true, validate: false, value:"", inputKey:""}
        this.elValid = this.props.validation.elements.indexOf(this.props.name) > 0;
        this.parentValid = this.props.validation.validate;
    }

    validate(e){
        let valueValid = this.context.schema.components.find(item => item.name === this.props.name).validation(e);
        this.setState({valueValid: valueValid, value:e});
        this.props.onChange(e);
    }

    reset(e){
        let randomString = Math.random().toString(36);
        this.setState({inputKey:randomString})
        this.props.onChange(null)
    }

    componentDidMount() {

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props !== prevProps){
            this.setState({
                validate: this.state.valueValid && this.props.validation.elements.indexOf(this.props.name) > 0 && this.props.validation.validate
            })
        }

    }

    render(){
        return(
            <div className="form-group">
                <label className="form-check-label" htmlFor="fileInput">{this.props.label}</label>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="inputGroupFileAddon01" onClick={(e) => this.reset(e)}>Reset</span>
                    </div>
                    <div className="custom-file">
                        <input key={this.state.inputKey || ''} name="fileInput" onChange={(e) => this.validate(e.target.files)} type="file" className="form-control custom-file-input"/>
                        <label className="custom-file-label" htmlFor="fileInput">{this.props.file ? this.props.file.fileName : ""}</label>
                    </div>
                    <div>
                        {
                            this.state.validate ?
                                <div className="animate__animated animate__bounceIn" style={{color:"red", fontSize:"0.8rem"}}>
                                    {
                                        this.props.lang.toUpperCase() === "DE" ?
                                            this.context.schema.components.find(item => item.name===this.props.name).message.de
                                            :
                                            this.context.schema.components.find(item => item.name===this.props.name).message.en
                                    }
                                </div>
                                :
                                null
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default SingleFileInput;