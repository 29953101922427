import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from "react-table-6/react-table";
import "react-table-6/react-table.css";
import Select from "react-select";
import ResultsActions from './actions/ResultsActions';
import {format, getTime, parseISO} from "date-fns";
import {de} from "date-fns/locale";

const tableHeaders = () => {
    return [
        {
            Header: "Identification",
            accessor: "identification"
        },
        {
            Header: "Started",
            accessor: "started",
            sortMethod: (a, b) => {
                let oA = getTime(parseISO(a));
                let oB = getTime(parseISO(b));
                if(oA < oB)
                    return 1;
                else
                    return -1;
            },
            Cell: row => {
                return format(parseISO(row.original.started), "dd.MM.yyyy HH:mm:ss",{locale: de})
            }
        },
        {
            Header: "Block",
            accessor: 'block'
        }
    ];
};

 class Inwork extends Component {

  componentDidMount(){
    this.props.fetchPositions();
  }

  render() {
    const columns = tableHeaders();
    return (
      <div>
        <div className="col-12">
          <div className="row">
            <div className="col-12">
              <h4>In Bearbeitung</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-6 form-group">
              <Select
                placeholder={"Position"}
                value={this.props.selectedPosition}
                onChange={(e) => this.props.selectPositionInwork(e)}
                options={this.props.positions}              />
            </div>
            <div className="col-6">

            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <ReactTable
                data={this.props.inwork}
                columns={columns}
                defaultPageSize={10}
                keyField='identification'
                className="-striped -highlight"
                previousText={"Zurück"}
                nextText={'Vorwärts'}
                loadingText={'Lade...'}
                noDataText={"Keine Daten gefunden..."}
                pageText={'Seite'}
                ofText={'von'}
                rowsText={'Zeilen'}
                defaultSorted={[
                    {
                        id: "started",
                        desc: false
                    }
                ]}
                filterable
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const positions = (positionState) => {
    let posArr = [];
    positionState.map(position => {
      posArr.push({value: position.entity.id, label: position.ref.name})
    });
    return posArr
};

const started = (startedState) => {
  let staArr = [];
  startedState.map(startedItem => {
    staArr.push(startedItem.ref)
  });
  return staArr;
};

const mapStateToProps = (state) => ({
  inwork: state.results.inwork,
  positions: positions(state.results.positions.positions),
  selectedPosition: state.results.positions.selectedInwork
});

const mapDispatchToProps = (dispatch) => ({
  selectPositionInwork: (e) => dispatch(ResultsActions.selectPositionInwork(e)),
  fetchPositions: () => dispatch(ResultsActions.fetchPositions())
});

export default connect(mapStateToProps, mapDispatchToProps)(Inwork);
