import AdminConst from '../module/admin/AdminConst';
import {combineReducers} from 'redux';
import {interviewManagement} from "../module/admin/reducer/InterviewManagementReducer";
import {positionManagement} from "../module/admin/reducer/PositionManagementReducer";
import {adminNameMapping} from "../module/admin/reducer/AdminNameMappingReducer";


const customer = (state={data:[]}, action=null) => {
  switch(action.type){
    case AdminConst.FETCH_CUSTOMER:
      return Object.assign({}, state, {data: action.payload})
    default:
      return state;
  }
};

const recruiter = (state={data:[]}, action=null) => {
  switch(action.type){
    case AdminConst.FETCH_RECRUITER:
      return Object.assign({}, state, {data: action.payload})
    default:
      return state;
  }
};

const profiles = (state={data:[]}, action=null) => {
  let newState = null;
  switch(action.type){
    case AdminConst.FETCH_PROFILES_CUSTOMER:
      return Object.assign({}, state, {data:action.payload});
    //case AdminConst.ADD_PROFILE:
    //  newState = Object.assign({}, state);
    //  newState.data.find(item => item.entity.id === action.payload.profileId).entity = action.payload;
    //  return newState;
    //case AdminConst.DELETE_PROFILE:
    //  newState = Object.assign({}, state);
    //  newState.data.filter(item => item.entity !== null).find(item => item.entity.id === action.payload.id).entity = null;
    //  return newState;
    default:
      return state;
  }
};

const associatedProfiles = (state={data:[]}, action=null) => {
  switch(action.type){
    case AdminConst.FETCH_PROFILES_ASSOCIATED:
      return Object.assign({}, {data:action.payload});
    case AdminConst.ADD_PROFILE:
      return {...state, data: [...state.data, action.payload]};
      case AdminConst.DELETE_PROFILE:
        let idx = state.data.findIndex(item => item.id === action.payload.id);
        return {...state, data: [
                ...state.data.slice(0, idx),
                ...state.data.slice(idx + 1)
            ]};
    default:
      return state;
  }
};

const selectedCustomer = (state={customer:null}, action=null) => {
  switch(action.type){
    case AdminConst.SELECT_CUSTOMER:
      return Object.assign({}, state, {customer: action.payload.customerId});
    default:
      return state;
  }
};

// @ts-ignore
const admin = combineReducers({customer, recruiter, profiles, selectedCustomer, interviewManagement, positionManagement, associatedProfiles, adminNameMapping});
export default admin;
