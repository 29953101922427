import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Timespan} from "../../../const/Timespan";
import ListActions from "../actions/ListActions";
import checkboxHOC from "react-table-6/lib/hoc/selectTable";
import ReactTable from "react-table-6/react-table";
import {format, parseISO} from "date-fns";
import "react-table-6/react-table.css";
import {de} from "date-fns/locale";
import {enGB as en} from "date-fns/locale";
import {withNamespaces, withTranslation} from "react-i18next";
import Base64Handler from "../../../utils/Base64Handler";
import Input from "../../../components/form/Input";
import ExtSelect from "../../../components/form/ExtSelect";
import {ListFormProvider} from "../../../context/ListFormProvider";
import SingleFileInput from "../../../components/form/SingleFileInput";

const getLocalizedDateShort = (date, locale) => {
    switch(locale){
        case "de":
            return format(parseISO(date), "DD.MM.YYYY HH:mm:ss", {locale: de});
        case "en":
            return format(parseISO(date), "MM/DD/YYYY hh:mm:ss", {locale: en});
        default:
            return format(parseISO(date), "MM/DD/YYYY hh:mm:ss", {locale: en});
    }
};

const CheckboxTable = checkboxHOC(ReactTable);

const profiles = (props) => {
    let options = [];
    if(props.profiles.length > 0){
        if(props.profiles.length > 0) {
            props.profiles.map(item => {
                options.push({
                    label: item.entity.name,
                    value: item.entity.extId
                })
            });
        }
    }
    return options;
}

const tests = (data) => {
    let tests = [];
    data.map(item => {
        tests.push({
            label: item,
            value: item
        })
    })
    return tests;
}

const attendances = (attendances, testvariant) => {
    let attendancesList = [];
    if(attendances.length > 0){
        if(testvariant != null){
            attendances.map(item => {
                if(item.ref.testname === testvariant.value){
                    attendancesList.push(
                        {
                            identification: item.ref.identification,
                            finishedAt: item.ref.finishedAt,
                            locale: item.ref.locale,
                            testname: item.ref.testname,
                            position: item.ref.position
                        }
                    )
                }
            })
        }else{
            attendances.map(item => {
                attendancesList.push(
                    {
                        identification: item.ref.identification,
                        finishedAt: item.ref.finishedAt,
                        locale: item.ref.locale,
                        testname: item.ref.testname,
                        position: item.ref.position
                    }
                )

            })

        }
    }
    return attendancesList
}

const mapStateToProps = (state) => ({
    filter: state.result.list.filter,
    profiles: profiles(state.result.base.profiles),
    selected: state.result.list.selected,
    attendances: attendances(state.result.list.attendances, state.result.list.filter.testvariant),
    tests: tests(state.result.list.tests),
    listDownloads: state.result.list.downloadList,
    listFormValidation: state.result.list.listFormValidation
});

const mapDispatchToProps = (dispatch) => ({
    setListFilterTimespan: (timespan) => dispatch(ListActions.setListFilterTimespan(timespan)),
    setListFilterProfile: (profile) => dispatch(ListActions.setListFilterProfile(profile)),
    setListFilterPosition: (position) => dispatch(ListActions.setListFilterPosition(position)),
    setListFilterFile: (file) => dispatch(ListActions.setListFilterFile(file)),
    setListFilterSort: (sort) => dispatch(ListActions.setListFilterSort(sort)),
    setListFilterLocale: (locale) => dispatch(ListActions.setListFilterLocale(locale)),
    setListFilterTestvariant: (testvariant) => dispatch(ListActions.setListFilterTestvariant(testvariant)),
    setListTableSelect: (key) => dispatch(ListActions.setListTableSelect(key)),
    setListTableSelectAll: (a,b) => dispatch(ListActions.setListTableSelectAll(a,b)),
    requestList: (cb) => dispatch(ListActions.requestList(cb)),
    startListDownload: (interval) => dispatch(ListActions.startListDownload(interval)),
    pollListDownload: () => dispatch(ListActions.pollListDownload())
});

/**
 * created by stephanpudras at 08.04.20
 */
class List extends Component {
    constructor(props) {
        super(props);
        this.getFilename = this.getFilename.bind(this);
        this.getTableDataFlat = this.getTableDataFlat.bind(this);
        this.requestList = this.requestList.bind(this);
        this.startDownload = this.startDownload.bind(this);
        this.state = {byRef: false, tableEntries:[], files:[]};
        this.Base64Handler = new Base64Handler()
        this.validate = this.validate.bind(this);
        this.formRef = React.createRef();

    }

    componentDidMount() {
    }

    requestList(){
        this.props.requestList(this.startDownload)
    }

    startDownload(){
        this.props.startListDownload(setInterval(this.props.pollListDownload, 1500))
    }

    getFilename(e){
        let b64 = new Base64Handler()
        if(e != null && e !== "undefined" && e.length > 0){
            b64.handleFile(e, this.props.setListFilterFile)
        }else{
            this.props.setListFilterFile(null)
        }
    }

    getTableDataFlat(){
        let data = []
        this.props.attendances.map(item => {
            if(item.hasOwnProperty("entity")){
                data.push({
                    identification: item.ref.identification,
                    finishedAt: item.ref.finishedAt,
                    locale: item.ref.locale,
                    testname: item.ref.testname,
                    position: item.ref.position
                });
            }
        });
        return data;
    }

    timespan = () => {
        let timespan = [];
        timespan.push({label: "1 Woche", value:Timespan.ONE_WEEK})
        timespan.push({label: "2 Wochen", value:Timespan.TWO_WEEKS})
        timespan.push({label: "4 Wochen", value:Timespan.FOUR_WEEKS})
        timespan.push({label: "2 Monate", value:Timespan.TWO_MONTHS})
        timespan.push({label: "3 Monate", value:Timespan.THREE_MONTHS})
        timespan.push({label: "6 Monate", value:Timespan.SIX_MONTHS})
        timespan.push({label: "12 Monate", value:Timespan.TWELVE_MONTHS})
        return timespan;
    }

    toggleSelection = (key, shift, row) => {
        let k = key.split("-")
        this.props.setListTableSelect(k[1]);
    };

    toggleAll = () =>{
        const selectAll = !this.props.selected.selectAll;
        const selection = [];
        if (selectAll) {
            // we need to get at the internals of ReactTable
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            // the 'sortedData' property contains the currently accessible records based on the filter and sort
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            // we just push all the IDs onto the selection array
            currentRecords.forEach(item => {
                selection.push(item._original.identification);
            });
        }
        this.props.setListTableSelectAll(selection, selectAll);
        //this.setState({ selectAll, selection });
    };

    isSelected = key => {
        /*
          Instead of passing our external selection state we provide an 'isSelected'
          callback and detect the selection state ourselves. This allows any implementation
          for selection (either an array, object keys, or even a Javascript Set object).
        */
        return this.props.selected.selection.includes(key);
    };

    tableHeaders = () => {
        return [
            {
                Header: this.props.t("tHeadLabels.logincode"),
                accessor: "identification"
            },
            {
                Header: this.props.t("tHeadLabels.finished"),
                accessor: "finishedAt",
                sortMethod: (a, b) => {
                    let oA = datefns.getTime(a);
                    let oB = datefns.getTime(b);
                    if(oA < oB)
                        return 1;
                    else
                        return -1;
                },
                Cell: row => {
                    return getLocalizedDateShort(new Date(row.original.finishedAt), this.props.i18n.language)
                }


            },
            {
                Header: this.props.t("tHeadLabels.locale"),
                accessor: "locale"
            },
            {
                Header: this.props.t("tHeadLabels.test"),
                accessor: "testname"
            }
        ];
    };

    validate(){
        //this.formRef.current != null ? this.formRef.current.validate() : null
    }

    render() {
        let data = this.state.byRef ? this.props.attendances : this.props.attendances;
        const columns = this.tableHeaders();
        const { toggleSelection, toggleAll, isSelected } = this;
        const selectAll = this.props.selected.selectAll;
        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox",
            getTrProps: (s, r) => {
                // someone asked for an example of a background color change
                // here it is...
                const selected = r ? r !=="undefined" && r !== null ? r.hasOwnProperty("original") ? this.isSelected(r.original.identification) : false : false : false;
                return {
                    style: {
                        backgroundColor: selected ? "lightgreen" : "inherit"
                        // color: selected ? 'white' : 'inherit',
                    }
                };
            }
        };
        return (

                <div>

                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <ListFormProvider>
                                    <div className="col-xl-2 col-md-5 col-xs-12">
                                        <ExtSelect
                                            name="attendance"
                                            label={"Teilnahmen aus Zeitspanne"}
                                            placeholder={"Zeit"}
                                            value={this.props.filter.timespan}
                                            onChange={this.props.setListFilterTimespan}
                                            options={this.timespan()}
                                            isClearable={true}
                                            validation={this.props.listFormValidation}
                                        />
                                        <div className="row">
                                            <div className="col-12">
                                                <ExtSelect
                                                    placeholder={"Testvariant"}
                                                    value={this.props.filter.testvariant}
                                                    onChange={this.props.setListFilterTestvariant}
                                                    options={this.props.tests}
                                                    isClearable={true}
                                                    label={"Testvariante"}
                                                    validation={this.props.listFormValidation}
                                                    name="testvariant"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-10 col-md-7 col-xs-12">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <ExtSelect
                                                        placeholder={"Anforderungsprofil"}
                                                        value={this.props.filter.profile}
                                                        onChange={this.props.setListFilterProfile}
                                                        options={this.props.profiles}
                                                        isClearable={true}
                                                        label={"Anforderungsprofil"}
                                                        validation={this.props.listFormValidation}
                                                        name="profile"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <ExtSelect
                                                        placeholder={"Sortierung"}
                                                        value={this.props.filter.sort}
                                                        onChange={this.props.setListFilterSort}
                                                        options={[
                                                            {
                                                                label:"Alphabetisch",
                                                                options:[{
                                                                    label: "Aufsteigend",
                                                                    value: "ALPHA_ASC",
                                                                    isDisabled: this.props.filter.file == null
                                                                },
                                                                    {
                                                                        label: "Absteigend",
                                                                        value: "ALPHA_DESC",
                                                                        isDisabled: this.props.filter.file == null
                                                                    }]
                                                            },
                                                            {
                                                                label: "Logincode",
                                                                options:[
                                                                    {
                                                                        label: "Aufsteigend",
                                                                        options: "IDENTIFICATION_ASC"
                                                                    },
                                                                    {
                                                                        label: "Absteigend",
                                                                        options: "IDENTIFICATION_DESC"
                                                                    }
                                                                ]
                                                            },
                                                            {
                                                                label: "Datum",
                                                                options: [
                                                                    {
                                                                        label: "Aufsteigend",
                                                                        options: "FINISHED_DATE_ASC"
                                                                    },
                                                                    {
                                                                        label: "Absteigend",
                                                                        options: "FINISH_DATE_DESC"
                                                                    }
                                                                ]
                                                            },
                                                            {
                                                                label: "Sprache",
                                                                options: [
                                                                    {
                                                                        label: "Aufsteigend",
                                                                        options: "LOCALE_ASC"
                                                                    },
                                                                    {
                                                                        label: "Absteigend",
                                                                        options: "LOCALE_DESC"
                                                                    }
                                                                ]
                                                            }
                                                        ]}
                                                        isClearable={true}
                                                        label={"sort"}
                                                        validation={this.props.listFormValidation}
                                                        name="sort"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                        <Input name="position"
                                                               value={this.props.filter.position}
                                                               onChange={this.props.setListFilterPosition}
                                                               label={"Position"}
                                                               notNull={true}
                                                               type={"text"}
                                                               validation={this.props.listFormValidation}
                                                        />
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <ExtSelect
                                                        placeholder={"Sprache"}
                                                        value={this.props.filter.locale}
                                                        onChange={this.props.setListFilterLocale}
                                                        options={[
                                                            {label: "Deutsch", value: "de" },
                                                            {label: "Englisch", value: "en" }
                                                        ]}
                                                        label={"Sprache"}
                                                        validation={this.props.listFormValidation}
                                                        name="locale"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-6">
                                                <SingleFileInput
                                                    label={"Naming-File"}
                                                    name="file"
                                                    validation={this.props.listFormValidation}
                                                    file={this.props.filter.file}
                                                    onChange={this.getFilename}
                                                />
                                            </div>
                                            <div className="col-6">
                                                {
                                                    this.props.listDownloads.ready ?
                                                        <button className="btn btn-outline-success" onClick={(e) => this.Base64Handler.saveList(this.props.listDownloads.files)}>Liste Speichern</button>
                                                        :
                                                        (
                                                            !this.props.listDownloads.ready && this.props.listDownloads.pending ?
                                                                <button className="btn btn-outline-info">Liste wird generiert ...</button>
                                                                :
                                                                <button className="btn btn-outline-primary" onClick={(e) => this.requestList()}>Liste Downloaden</button>
                                                        )
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </ListFormProvider>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <CheckboxTable
                                        ref={r => (this.checkboxTable = r)}
                                        data={data}
                                        columns={columns}
                                        defaultPageSize={10}
                                        keyField='identification'
                                        className="-striped -highlight"
                                        previousText={this.props.t("tableControls.backward")}
                                        nextText={this.props.t("tableControls.forward")}
                                        loadingText={this.props.t("tableControls.loading") + '...'}
                                        noDataText={this.props.t("tableControls.nodata") + "..."}
                                        pageText={this.props.t("tableControls.page")}
                                        ofText={this.props.t("tableControls.of")}
                                        rowsText={this.props.t("tableControls.rows")}
                                        defaultSorted={[
                                            {
                                                id: "finishedAt",
                                                desc: false
                                            }
                                        ]}
                                        filterable
                                        {...checkboxProps}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


        )
    }
}
const listTrans = withTranslation(["common"])(List);
export default connect(mapStateToProps, mapDispatchToProps)(listTrans);