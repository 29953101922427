import React, {useEffect} from "react"
import {useDispatch} from "react-redux";
import {fetchPositions} from "../../../../bootstrap/slices/positionSlice";

export default function Manage():JSX.Element{

    return(
        <div>
            Manage
        </div>
    )
}