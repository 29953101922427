/**
 * Created by stephanpudras on 15.10.18.
 */

import React from 'react';
import {connect} from 'react-redux';
import Select from "react-select";
import InterviewManagementActions from "./actions/InterviewManagementActions";
import ReactTable from "react-table-6/react-table";
import {getYear, subYears} from "date-fns";

const mapStateToProps = (state) => {
    return {
        customer: state.admin.customer.data,
        selectedCustomer: state.admin.interviewManagement.selectedCustomer,
        interviewDTO: state.admin.interviewManagement.interviewManagementDTO,
        selectedMonth: state.admin.interviewManagement.selectedMonth,
        selectedYear: state.admin.interviewManagement.selectedYear
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        selectCustomer: (customerId) => dispatch(InterviewManagementActions.selectCustomer(customerId)),
        selectMonth: (month) => dispatch(InterviewManagementActions.selectMonth(month)),
        selectYear: (year) =>dispatch(InterviewManagementActions.selectYear(year)),
        fetchInterviewManagementDTO: () => dispatch(InterviewManagementActions.fetchInterviewManagementDTO())
    }
};

const MONTHS = [
    {
        label: "Januar",
        value: 1
    },
    {
        label: "Februar",
        value: 2
    },
    {
        label: "März",
        value: 3
    },
    {
        label: "April",
        value: 4
    },
    {
        label: "Mai",
        value: 5
    },
    {
        label: "Juni",
        value: 6
    },
    {
        label: "Juli",
        value: 7
    },
    {
        label: "August",
        value: 8
    },
    {
        label: "September",
        value: 9
    },
    {
        label: "Oktober",
        value: 10
    },
    {
        label: "November",
        value: 11
    },
    {
        label: "Dezember",
        value: 12
    }
];

const YEAR = () => {
    let date = new Date();
    let yearArr = [];
    yearArr.push(getYear(date));
    yearArr.push(getYear(subYears(date, 1)));
    yearArr.push(getYear(subYears(date, 2)));
    yearArr.push(getYear(subYears(date, 3)));
    yearArr.push(getYear(subYears(date, 4)));
    let selectArr = [];
    yearArr.map(year => {
        selectArr.push({value: year, label: year})
    });
    return selectArr;
};

class Interviews extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.getCustomer = this.getCustomer.bind(this);
        this.getInterviewDTO = this.getInterviewDTO.bind(this);
        this.getMonths = this.getMonths.bind(this);
        this.state = {
            yearArr: YEAR(),
            monthArr: MONTHS
        }
    }

    componentDidMount(){
        YEAR();
    }

    getCustomer(){
        let arr = [];
        this.props.customer.map(item => {
            arr.push({value:item.entity.id, label:item.entity.name})
        });
        return arr;
    }

    getInterviewDTO(){
        let arr = [];
        this.props.interviewDTO.map(item => {
            arr.push({
                dateDownloaded: item.entity.dateDownloaded,
                attCodeId: item.entity.attCodeId
            })
        });
        return arr;
    }

    getMonths(){
        datefns.isSameMonth()
    }

    tableHeaders = () => {
        return [
            {
                Header: "Downloaded",
                accessor: "entity.dateDownloaded",
                Cell: row => {
                    return datefns.format(row.original.entity.dateDownloaded, "DD.MM.YYYY HH:mm:ss")
                }

            },
            {
                Header: "LoginCode",
                accessor: "entity.attCode.identification",
            }
        ];
    };

    render() {
        return (
            <div className="main">
                <div className="row">
                    <div className="col-12">
                        <h5>Interviewleitfaden-Management</h5>
                    </div>
                </div>
                <div className="row form-group">
                    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 form-group">
                        <label>Kunde</label>
                        <Select
                            placeholder={"Kunde"}
                            value={this.props.selectedCustomer.customerId}
                            onChange={(e) => this.props.selectCustomer(e != null ? e : null)}
                            options={this.getCustomer()}
                            title={"Kunden..."}
                            id={"colFormLabelSm"}
                        />
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 form-group">
                        <label>Monat</label>
                        <Select
                            placeholder={"Monat"}
                            value={this.props.selectedMonth.month}
                            onChange={(e) => this.props.selectMonth(e != null ? e : null)}
                            options={this.state.monthArr}
                            title={"Monat..."}
                            id={"colFormLabelSm"}
                        />
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-xs-12 form-group">
                        <label>Jahr</label>
                        <Select
                            placeholder={"Jahr"}
                            value={this.props.selectedYear.year}
                            onChange={(e) => this.props.selectYear(e != null ? e : null)}
                            options={this.state.yearArr}
                            title={"Jahr..."}
                            id={"colFormLabelSm"}
                        />
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-xs-12 form-group d-flex">
                        <div className="flex-grow-1"> </div>
                        <button onClick={() => this.props.fetchInterviewManagementDTO()} className="btn btn-md btn-primary btn-block align-self-end">Laden</button>
                    </div>
                </div>
                <div className="row form-group">
                    <div className="col-12">
                        <ReactTable
                            columns={this.tableHeaders()}
                            data={this.props.interviewDTO}
                            defaultPageSize={10}
                            keyField='identification'
                            className="-striped -highlight"
                            previousText={"Zurück"}
                            nextText={'Vorwärts'}
                            loadingText={'Lade...'}
                            noDataText={"Keine Daten gefunden..."}
                            pageText={'Seite'}
                            ofText={'von'}
                            rowsText={'Zeilen'}
                            filterable
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Interviews)