import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import store, {history} from './store';
import registerServiceWorker from './registerServiceWorker';
import {Provider} from 'react-redux'
import {Redirect, Route, withRouter} from 'react-router'
import { BrowserRouter as Router} from "react-router-dom";
import i18n from './i18n'; // initialized i18next instance
import {I18nextProvider} from "react-i18next";
import register from "./registerServiceWorker";

//register();


// from https://github.com/dasniko/keycloak-reactjs-demo/blob/master/src/index.js
/**
 * React-router supports authentication for it's path matching. Maybe that can be combined with KCs flow?
 * Also, authentication could be a React Module. Possibly, we can use the webpack2 async options (loading specific react components on demand)
 *
 * Could be better to move the auth parts into App.js, as that's a component with lifecycle methods (componentDidMount etc.) to check, if there's
 * already a token in localStorage. LocalStorage isn't a good place to store it, anyway, as it is persisted.
 * SessionStorage would be better, as it'll make sure that the token isn't persisted but lost after browser shutdown.
 *
 */

//const app = withRouter(App);
const app = <App i18n={i18n}/>;
const wR = withRouter(app);
ReactDOM.render(
    <Suspense fallback="loading">
        <I18nextProvider i18n={ i18n }>
            <Provider store={store}>
                <Router>
                    <Route path={"/"} component={withRouter(App)}/>
                </Router>
            </Provider>
        </I18nextProvider>
    </Suspense>

    , document.getElementById("app")
);
