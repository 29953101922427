import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormGroup, TextField} from "@mui/material";
import {Close} from "@mui/icons-material";
import React from "react";
import {useProfileEdit} from "../state/ProfileEditModalState";
import {useTranslation} from "react-i18next";

export const ProfileEditModal = () => {
  const [ profile,
          isOpen,
          close,
          update,
          save] = useProfileEdit((state) => [
      state.profile,
      state.isOpen,
      state.close,
      state.update,
      state.save
    ])
  const {t,i18n} = useTranslation("common")

  return (
    <Dialog maxWidth={"md"} fullWidth={true} open={isOpen} onClose={() => close()}>
      <DialogTitle>
        <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
          <Box>
            {t("dialogTitles.editProfile")+":"} <br/><strong style={{color: "red"}}>{profile ? profile.name : ""}</strong>
          </Box>
          <Box>
            <Button sx={{color: "darkgray"}} onClick={() => close()}><Close/></Button>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <FormGroup>
          <TextField variant={"standard"} label={t("labels.name")} value={profile ? profile.name : ""} onChange={(e) =>
            profile !== undefined ? update({...profile, name:e.target.value}):null}/>
          <br/>
          <TextField variant={"standard"} multiline={true} label={t("labels.description")} value={profile ? profile.description : ""} onChange={(e) =>
            profile !== undefined ? update({...profile, description:e.target.value}):null}/>
        </FormGroup>
        <br/>
      </DialogContent>
      <DialogActions>
        <Button variant={"contained"} color={"primary"} onClick={() => save()}>{t("button.saveEdit")}</Button>
        <Button
          variant={"contained"}
          color={"secondary"}
          onClick={() => {close()}}>{t("button.close")}</Button>
      </DialogActions>
    </Dialog>
  )
}