import axios from "axios"

let customerId: number | string = "";

let token: string = "";

export function setToken(token:string){
    axios.defaults.headers.common["Authorization"] = "Bearer " + token
}

export function getToken(){
    return token;
}

export function getCustomerId(): number | string{
    return customerId
}

export function setCustomerId(id: number | string){
    customerId = id;
}

export function setAuthToken(t: string){
    token = t;
}