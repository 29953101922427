import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch, AppThunk} from "../../../../store";
import {Position} from "../../../../types";

const initialState:Position = {
    entity: null,
    ref: null
}

const selectedPositionSlice = createSlice({
    name: "selectedPosition",
    initialState,
    reducers: {
        selectPosition(state, action:PayloadAction<Position | any>){
            if(action.payload == undefined){
                return {
                    entity: null,
                    ref: null
                }
            }else {
                return action.payload
            }

        }
    }
})

export const selectPosition = (position:Position | undefined | null ):AppThunk => async (dispatch: AppDispatch) => {
    dispatch(selectedPositionSlice.actions.selectPosition(position))
}

export default selectedPositionSlice.reducer;