import ResultsConst from '../const/ResultsConst';
import AppConst from '../../../AppConst';
import RequestEntity from '../../../api/RequestEntity';
import URLConstants from "../../../api/URLConstants";
import ReportType from "../../../const/ReportType";
import ReportState from "../../../const/ReportState";

const BASE_URL = URLConstants.getURL();

const ResultsActions = {
    setFormat: (value, reportState) => {
        return {type: ResultsConst.SET_FILE_FORMAT, payload:{format: value, reportState: reportState}}
    },
  fetchInwork: (jobId, customerId) => {
    return {type: AppConst.AJAX_CALL, entity: new RequestEntity(BASE_URL + "/portal/attendance/" + customerId + "/list?jobId=" + jobId + "&active=true", "GET", ResultsConst.FETCH_INWORK)}
  },
  fetchStarted: (jobId, customerId) => {
    return {type: AppConst.AJAX_CALL, entity: new RequestEntity(BASE_URL + "/portal/attendance/" + customerId + "/list?jobId=" + jobId + "&unfinished=true", "GET", ResultsConst.FETCH_STARTED)}
  },
  fetchFinished: (jobId, customerId) => {
    return {type:AppConst.AJAX_CALL, entity: new RequestEntity(BASE_URL + "/portal/attendance/" + customerId + "/list?jobId=" + jobId + "&finished=true&feedback=true", "GET", ResultsConst.FETCH_FINISHED)}
  },
  fetchPositions: () => {
    return (dispatch, getState) => {
      let customerId = null;
      if(getState().app.portal.userInfo != null){
        let customerId = getState().app.portal.userInfo.customer.id;
        dispatch({type: AppConst.AJAX_CALL, entity: new RequestEntity(BASE_URL + "/portal/personal/job/" + customerId + "/list", "GET", ResultsConst.FETCH_POSITIONS, null, {customerId:customerId})})
      }else{
        dispatch({type: ResultsConst.FETCH_POSITIONS_ERROR})
      }
    }
  },
  selectPositionFinished: (e) => {
    return (dispatch, getState) => {
      dispatch(() => {
        let jobId = e !== null ? e.value : null;
        let customerId = null;
        if(getState().app.portal.userInfo != null){
          customerId = getState().app.portal.userInfo.customer.id;
          dispatch(ResultsActions.fetchFinished(jobId, customerId));
        }else{
          dispatch({type: ResultsConst.FETCH_FINISHED_ERROR})
        }
      });
      dispatch({type:ResultsConst.SELECT_POSITION_FINISHED, payload:e})
    }
  },
  selectPositionStarted: (e) => {
    return (dispatch, getState) => {
      dispatch(() => {
        let jobId = e.value;
        let customerId = null;
        if(getState().app.portal.userInfo != null){
          customerId = getState().app.portal.userInfo.customer.id;
          dispatch(ResultsActions.fetchStarted(jobId, customerId));
        }else{
          dispatch({type: ResultsConst.FETCH_STARTED_ERROR})
        }
      });
      dispatch({type:ResultsConst.SELECT_POSITION_STARTED, payload:e})
    }
  },
  selectPositionInwork: (e) => {
    return (dispatch, getState) => {
      dispatch(() => {
        let jobId = e.value;
        let customerId = null;
        if(getState().app.portal.userInfo != null){
          customerId = getState().app.portal.userInfo.customer.id;
          dispatch(ResultsActions.fetchInwork(jobId, customerId));
        }else{
          dispatch({type: ResultsConst.FETCH_INWORK_ERROR})
        }
      });
      dispatch({type:ResultsConst.SELECT_POSITION_INWORK, payload:e})
    }
  },
  selectAllFinished: (array, toggleAll) => {
      return {type: ResultsConst.FINISHED_ALL_ROWS_SELECTED, payload:{keys: array, toggleAll: toggleAll}}
  },
  selectRowFinished: (key) => {
    return {type: ResultsConst.FINISHED_ROW_SELECTED, payload:{key:key}}
  },
  chooseLanguage: (lang) => {
    return {type: ResultsConst.CHOOSE_LANGUAGE, payload:{lang: lang}}
  },
  fetchProfiles: () => {
    return (dispatch, getState) => {
      let customerId = getState().app.portal.userInfo.customer.id;
      dispatch({type: AppConst.AJAX_CALL, entity: new RequestEntity(BASE_URL + "/portal/profile/" + customerId + "/list", "GET", ResultsConst.FETCH_PROFILES)});
    }
  },
  chooseProfile: (id) => {
    return {type:ResultsConst.CHOOSE_PROFILE, payload:id};
  },
  startReportDownload: (interval) => {
      return (dispatch, getState) => {
          dispatch({type: ResultsConst.START_REPORT_DOWNLOAD, payload:{
                  interval: interval,
                  exportFormat: getState().results.finished.format,
                  reportType: getState().results.reportType.reportType.value}
          });
      };

  },
  startReportDownloadZip: (interval) => {
      return (dispatch, getState) => {
          dispatch({type: ResultsConst.START_REPORT_DOWNLOAD_ZIP, payload:{
                  interval: interval,
                  exportFormat: getState().results.finished.format,
                  reportType: getState().results.reportType.reportType.value}
          });
      };

  },
  pollReport: () => {
      return (dispatch, getState) => {
          let URL = "";
          let reportType = getState().results.reportType.reportType.value;
          switch(reportType){
              case ReportType.REPORT:
                  URL = BASE_URL + "/portal/reports/download/" + getState().results.downloadReport.token;
                  break;
              case ReportType.RANKING:
                  URL = BASE_URL + "/portal/ranking/download/" + getState().results.downloadReport.token;
                  break;
              case ReportType.INTERVIEW:
                  URL = BASE_URL + "/portal/interview/download/" + getState().results.downloadReport.token;
                  break;
          }
          dispatch({type: AppConst.AJAX_CALL, entity: new RequestEntity(URL, "GET", ResultsConst.FETCH_REPORTS, null)})
      }
  },
  pollReportZip: () => {
      return (dispatch, getState) => {
          let URL = "";
          let reportType = getState().results.reportType.reportType.value;
          switch(reportType){
              case ReportType.REPORT:
                  URL = BASE_URL + "/portal/reports/download/zip/" + getState().results.downloadReport.token;
                  break;
              case ReportType.RANKING:
                  URL = BASE_URL + "/portal/ranking/download/zip/" + getState().results.downloadReport.token;
                  break;
              case ReportType.INTERVIEW:
                  URL = BASE_URL + "/portal/interview/download/zip/" + getState().results.downloadReport.token;
                  break;
          }
          dispatch({type: AppConst.AJAX_CALL, entity: new RequestEntity(URL, "GET", ResultsConst.FETCH_REPORTS_ZIP, null)})
      }
  },
  downloadReport: (cb, reportState, byRef) => {
    return (dispatch, getState) => {
        let reportType = getState().results.reportType.reportType.value;
        let URL = "";
        let CONTEXT = "";
        switch(reportType){
            case ReportType.REPORT:
              URL = BASE_URL + "/portal/reports/request";
              break;
            case ReportType.RANKING:
              URL = BASE_URL + "/portal/ranking/request";
              break;
            case ReportType.INTERVIEW:
              URL = BASE_URL + "/portal/interview/request";
              break;
        }
        let attendances = [];

        let resultProperties = {};
        switch(reportState){
            case ReportState.INWORK:
                resultProperties = getState().results.resultProperties.inwork;
                break;
            case ReportState.STARTED:
                resultProperties = getState().results.resultProperties.started;
                break;
            case ReportState.FINISHED:
                resultProperties = getState().results.resultProperties.finished;
                break;
        }
        getState().results.tableSelection.selection.map(selected => {
            let oAttendance = byRef ? getState().home.finished.data.find(item => item.ref.identification === selected).ref : getState().results.finished.find(item => item.ref.identification === selected).ref;
            if(oAttendance != null)
                attendances.push(oAttendance);
        });
        dispatch({
            type: AppConst.AJAX_CALL,
            entity: new RequestEntity(URL, "POST", ResultsConst.REQUEST_REPORTS, cb),
            payload:{
              exportFormat: resultProperties.format.value,
              reportType: resultProperties.reportType.value,
              locale: resultProperties.locale.value,
              customerName: getState().app.portal.userInfo.customer.name,
              customerId: getState().app.portal.userInfo.customer.id,
              positionId: resultProperties.positionId !== null && resultProperties.positionId !== "undefined" && resultProperties.positionId.value ? resultProperties.positionId.value : -1,
              positionName: resultProperties.positionId !== null && resultProperties.positionId !== "undefined" && resultProperties.positionId.value ? getState().results.positions.positions.find((p) => p.entity.id === resultProperties.positionId.value).entity.name : "",
              interviewType: resultProperties.interviewType !== null && resultProperties.interviewType !== "undefined" && resultProperties.interviewType.value ? resultProperties.interviewType.value: null,
              userNameFile: getState().results.mappingFile.file,
              attendances: attendances,
              profileId: resultProperties.profileId !== null && resultProperties.profileId !== "undefined" && resultProperties.profileId.value ? resultProperties.profileId.value : -1
            }
        })
    }
  },
  downloadReportZip: (cb, reportState, byRef) => {
    return (dispatch, getState) => {
        let reportType = getState().results.reportType.reportType.value;
        let URL = "";
        let CONTEXT = "";
        switch(reportType){
            case ReportType.REPORT:
              URL = BASE_URL + "/portal/reports/request/zip";
              break;
            case ReportType.RANKING:
              URL = BASE_URL + "/portal/ranking/request/zip";
              break;
            case ReportType.INTERVIEW:
              URL = BASE_URL + "/portal/interview/request/zip";
              break;
        }
        let attendances = [];

        let resultProperties = {};
        switch(reportState){
            case ReportState.INWORK:
                resultProperties = getState().results.resultProperties.inwork;
                break;
            case ReportState.STARTED:
                resultProperties = getState().results.resultProperties.started;
                break;
            case ReportState.FINISHED:
                resultProperties = getState().results.resultProperties.finished;
                break;
        }
        getState().results.tableSelection.selection.map(selected => {
            let oAttendance = byRef ? getState().home.finished.data.find(item => item.ref.identification === selected).ref : getState().results.finished.find(item => item.ref.identification === selected).ref;
            if(oAttendance != null)
                attendances.push(oAttendance);
        });
        dispatch({
            type: AppConst.AJAX_CALL,
            entity: new RequestEntity(URL, "POST", ResultsConst.REQUEST_REPORTS_ZIP, cb),
            payload:{
              exportFormat: resultProperties.format.value,
              reportType: resultProperties.reportType.value,
              locale: resultProperties.locale.value,
              customerName: getState().app.portal.userInfo.customer.name,
              customerId: getState().app.portal.userInfo.customer.id,
              positionId: resultProperties.positionId !== null && resultProperties.positionId !== "undefined" && resultProperties.positionId.value ? resultProperties.positionId.value : -1,
              positionName: resultProperties.positionId !== null && resultProperties.positionId !== "undefined" && resultProperties.positionId.value ? getState().results.positions.positions.find((p) => p.entity.id === resultProperties.positionId.value).entity.name : "",
              interviewType: resultProperties.interviewType !== null && resultProperties.interviewType !== "undefined" && resultProperties.interviewType.value ? resultProperties.interviewType.value: null,
              userNameFile: getState().results.mappingFile.file,
              attendances: attendances,
              profileId: resultProperties.profileId !== null && resultProperties.profileId !== "undefined" && resultProperties.profileId.value ? resultProperties.profileId.value : -1
            }
        })
    }
  },
  setLocale: (value, reportState) => {
    return {type:ResultsConst.CHOOSE_LANGUAGE, payload:{locale:value, reportState:reportState}}
  },
  setPostion: (value, reportState) => {
      return (dispatch, getState) => {
          dispatch(() => {
              let jobId = value.value;
              let customerId = null;
              if(getState().app.portal.userInfo != null){
                  customerId = getState().app.portal.userInfo.customer.id;
                  dispatch(ResultsActions.fetchFinished(jobId, customerId));
              }else{
                  dispatch({type: ResultsConst.FETCH_FINISHED_ERROR})
              }
          });
          dispatch({type:ResultsConst.SET_POSITION, payload:{positionId:value, reportState:reportState}})
      };
  },
  setReportType: (value, reportState) => {
    return {type:ResultsConst.SET_REPORT_TYPE, payload:{reportType:value, reportState:reportState}}
  },
  setInterviewType: (value, reportState) => {
    return {type:ResultsConst.SET_INTERVIEW_TYPE, payload:{interviewType:value, reportState:reportState}}
  },
  setProfile: (value, reportState) => {
    return {type:ResultsConst.CHOOSE_PROFILE, payload:{profileId:value, reportState:reportState}}
  },
  fetchRange: (identification, finishedAt) => {
      return (dispatch, getState) => {
          let state = getState().results.percentRanges;
          if(state.find((item) => item.loginCode === identification) == null || state.length === 0)
              return dispatch({type:AppConst.AJAX_CALL, entity:new RequestEntity(BASE_URL + "/portal/range/" + identification + "?finishedAt=" + finishedAt, "GET", ResultsConst.GET_RANGES, null)});
      };
  },
    setFinishedExpanded: (newExpanded, index, event) => {
        return {type:ResultsConst.SET_FINISHED_EXPANDED, payload:{newExpanded: newExpanded, index: index, event: event}};
    },
    dropMappingFile: (file) => {
        return {type: ResultsConst.DROP_FILE, payload:{file: file}}
    },
    closeDialog:(state)  => {
        return {type: ResultsConst.SET_DIALOG_CLOSE, payload: state};
    },
    openDialog:(state) => {
        return {type: ResultsConst.SET_DIALOG_OPEN, payload: state};
    },
    setMissingProps: (state) => {
        return {type: ResultsConst.SET_DIALOG_MISSING_PROPS, payload: state};
    }
};

export default ResultsActions;
