interface Props {

}

export function LegalContentEn(props: Props){
    return(
        <div style={{marginTop:"2em"}} className="offset-xl-2 offset-lg-2 offset-md-1 col-xl-8 col-lg-8 col-md-10 col-sm-11 col-xs-12 privacy">
            <h4>Privacy Notice</h4>
            <p>
                In this Privacy Notice, we, Harald Ackerschott GmbH, Adenauerallee 168, 53113 Bonn, Germany, provide information on whether and, if so, which personal data will be collected during the use of the psychometric online procedure and how such will be processed and used.
            </p>
            <strong>Table of Contents:</strong>
            <ol>
                <li><a href="#data_responsibility">1. Who is responsible for my data?</a></li>
                <li><a href="#data_anonymity">2. Will my participation remain anonymous?</a></li>
                <li><a href="#data_raisedData">3. Which data will be collected?</a></li>
                <li><a href="#data_usage">4. How will the data be used?</a></li>
                <li><a href="#data_deletion">5. When will my data be deleted?</a></li>
                <li><a href="#data_location">6. Where will my data be stored?</a></li>
                <li><a href="#data_tracking">7. Will I be tracked?</a></li>
                <li><a href="#data_cookies">8. Which Cookies will be used?</a></li>
                <li><a href="#data_questions">9. Whom may I contact in case of questions and concerns?</a></li>
            </ol>
            <h4 id="data_responsibility">1. Who is responsible for my data?</h4>
            <p>
                Our client, i.e. the company which provided you with access data and for which we perform the procedure, will be responsible for the data collected in the context of the procedure in the sense of a responsible body as defined in data privacy laws.
                We, Harald Ackerschott GmbH, are the technical service provider and contact for performing the procedure and are in our activities strictly bound by the instructions given and purposes specified by our client.
            </p>
            <h4 id="data_anonymity">2. Will my participation remain anonymous?</h4>
            <p>
                While you complete the procedure online, we will not collect any direct data about you which would
                enable us to identify you, i.e. neither your name nor your address or email address.
                You will receive an individual access code from our client through which you can take part in the
                procedure.
                We do not know which access code belongs to which participant. It is only our client who invited you,
                who has that type of information and will thus be able to allocate the results of the procedure personally
                to you. We are only able to take over the personal allocation of the codes to the names in the
                assessment on behalf of our client when we have concluded Agreements on Contract Data Processing
                (CDP). In these cases, the client will inform us about the allocation.
            </p>
            <h4 id="data_raisedData">3. Which data will be collected?</h4>
            <p>
                We will record your answers to questions and tasks in the period from when you log in, until you
                complete the procedure, including the start, your control entries, the end and the language setting you
                selected.
            </p>
            <h4 id="data_usage">4. How will the data be used?</h4>
            <p>
                We will use the data for an assessment and will provide the results of this assessment to our client. The
                assessments contain an analysis of your decisions and the reply alternatives you selected according to
                different dimensions and the comparison of your results with a relevant standard - and comparison
                samples.
                In addition, we will store all answers in an anonymous form, i.e. without allocating the name, IP address
                or access code. We use these data to evaluate and further develop our procedures. Insofar as findings
                from such are made public, e.g. in scientific publications, this data will be used exclusively in an
                aggregated form.
                Our clients will use the data for decisions and measures in the field of human resources, such as e.g. for
                decisions on hiring, promoting, placing or developing their employees.
            </p>
            <h4 id="data_deletion">5. When will my data be deleted?</h4>
            <p>
                We delete data which can be attributed to a certain participant normally six months after the completion
                of the psychometric procedure, unless we received other instructions from our client. For information on
                how data are stored by the client, please contact them directly.
                We are able to store data, in particular, answers to tasks, in an anonymous manner also for a longer
                period of time.
            </p>
            <h4 id="data_location">6. Where will my data be stored?</h4>
            <p>
                We will exclusively store and process your data on computers in Germany that were certified according
                to DIN ISO 27001.
            </p>
            <h4 id="data_tracking">7. Will I be tracked?</h4>
            <p>
                We collect and store information in Log Files that your browser automatically transmits to us. This
                includes:
            </p>
            <ul>
                <li>Browser type / Browser version</li>
                <li>Operating system used</li>
                <li>Referrer URL</li>
                <li>Host name of accessing computer</li>
                <li>Time of server request</li>
            </ul>
            <p>
                These data cannot be allocated to certain persons. We will also not link these data with your access code
                or your answers.
                We will store the IP address of your computer in our log files. The IP address is a number which your
                internet service provider temporarily or permanently allocates to your computer. We exclusively use the
                log file data to ensure the functioning of our services (e.g. error analysis, to ensure the system security
                and to protect against abuse). Log Files will be deleted automatically after 30 days.
            </p>
            <h4 id="data_cookies">8. Which Cookies will be used?</h4>
            <p>
                When you start a procedure, our server will place a “Cookie“ with an identifier on your computer. A
                Cookie is a small text file that is stored on your computer. The contents of this file will be transferred to
                our server whenever you call a website. We will use the Cookie exclusively to be able to recognise you as
                a participant over the course of the procedure (so-called Session Cookie). You may set your Browser to
                avoid the storage of Cookies. But you will then not be able to participate in the procedure.
            </p>
            <h4 id="data_questions">9. Whom may I contact in case of questions and concerns?</h4>
            <p>
                If you wish to know which data we have stored about you, please do not hesitate to contact us:
                <a href="mailto:Datenschutz@ackerschott.eu">Datenschutz@ackerschott.eu</a>
            </p>
            <p>
                Status of this Privacy Notice: [05-04-2017]
            </p>
        </div>
    )
}