import {create} from "zustand";
import {devtools} from "zustand/middleware";
import {GroupUser, RProfile, SharedProfile} from "../../../types";
import {DeepClone} from "../../../utils/DeepClone";
import axios from "axios";
import URLConstants from "../../../api/URLConstants";
import {useShareResponse} from "./ShareResponseAlert";
import {useProfileAdmin} from "./ProfileAdminState";
import {saveAs} from "file-saver";

interface ProfileShareModalState {
  userId: string
  groupId: string
  profile: RProfile | undefined
  share: SharedProfile[]
  isOpen: boolean
  activeUser: GroupUser | undefined
  open: (profile: RProfile, userId: string, groupId: string,share?: SharedProfile[]) => void
  close: () => void
  setActiveUser: (user: GroupUser) => void
  toggleCanShare:(userId: string) => void
  toggleCanEdit: (userId: string) => void
  toggleCanDelete: (userId: string) => void
  save: () => void
}

const useProfileShare = create<ProfileShareModalState>()(devtools((set,get) => ({
  userId: "",
  groupId: "",
  profile: undefined,
  share: [],
  isOpen: false,
  activeUser: undefined,
  open: (profile: RProfile, userId: string, groupId:string, share?: SharedProfile[]) => {
    set({profile: DeepClone.clone(profile), userId: userId, groupId:groupId, share:share ? [...share]:[], isOpen:true})
  },
  close: () => {
    set({share:[], isOpen:false})
  },
  setActiveUser: (user: GroupUser) => {
    set({activeUser: user})
  },
  toggleCanShare: (userId: string) => {
    let idx = get().share.findIndex(it => it.userId === userId)
    if(idx != -1){
      set({
        share: [
          ...get().share.slice(0,idx),
          ...get().share.slice(idx+1)
        ]
      })
    }else{
      set({share:[...get().share, {userId: userId, userEmail: get().activeUser.email, profileId: get().profile.id, username: get().activeUser.userName, canDelete: false, canEdit:false}]})
    }
  },
  toggleCanEdit: (userId: string) => {
    let idx = get().share.findIndex(it => it.userId === userId)
    if(idx > -1){
      let nShare = DeepClone.clone(get().share[idx])
          nShare.canEdit = !get().share[idx].canEdit
      set({share: [
          ...get().share.slice(0,idx),
          nShare,
          ...get().share.slice(idx+1)
        ]})
    }
  },
  toggleCanDelete: (userId: string) => {
    let idx = get().share.findIndex(it => it.userId === userId)
    if(idx > -1){
      let nShare = DeepClone.clone(get().share[idx])
      nShare.canDelete = !get().share[idx].canDelete
      set({share: [
          ...get().share.slice(0,idx),
          nShare,
          ...get().share.slice(idx+1)
        ]})
    }
  },
  save: () => {
    axios.post<SharedProfile[]>(`${URLConstants.getURL()}/portal/profile/share/${get().profile.id}`, get().share)
      .then(r => {
        if(r.status===200){
          useProfileAdmin.getState().replaceShares(r.data, get().profile.id)
          useShareResponse.getState().open(get().profile.name, true)
        }else{
          useShareResponse.getState().open(get().profile.name, false)
        }
      })
  }

})))

export {useProfileShare}