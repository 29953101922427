import React, {Component} from 'react';
import {connect} from 'react-redux';
import {format, getTime, parseISO} from "date-fns";
import {de} from "date-fns/locale";
import {enGB as en} from "date-fns/locale";
import checkboxHOC from "react-table-6/lib/hoc/selectTable";
import ReactTable from "react-table-6/react-table";
import ListActions from "../actions/ListActions";
import Base64Handler from "../../../utils/Base64Handler";
import {Timespan} from "../../../const/Timespan";
import {ListFormProvider} from "../../../context/ListFormProvider";
import ExtSelect from "../../../components/form/ExtSelect";
import Input from "../../../components/form/Input";
import SingleFileInput from "../../../components/form/SingleFileInput";
import {withNamespaces, withTranslation} from "react-i18next";
import ListFormSchema from "../../../schema/ListFormSchema";
import Tooltip from "rc-tooltip";
import HelpPopover from "../../../components/help/popover/HelpPopover";

const getLocalizedDateShort = (date, locale) => {
    switch(locale){
        case "de":
            return format(date, "DD.MM.YYYY HH:mm:ss", {locale: de});
        case "en":
            return format(date, "MM/DD/YYYY hh:mm:ss A", {locale: en});
        default:
            return format(date, "MM/DD/YYYY hh:mm:ss A", {locale: en});
    }
};

const CheckboxTable = checkboxHOC(ReactTable);

const profiles = (props) => {
    let options = [];
    if(props.profiles.length > 0){
        if(props.profiles.length > 0) {
            props.profiles.map(item => {
                options.push({
                    label: item.entity.name,
                    value: item.entity.extId
                })
            });
        }
    }
    return options;
}

const tests = (data) => {
    let tests = [];
    data.map(item => {
        tests.push({
            label: item,
            value: item
        })
    })
    return tests;
}

const attendances = (attendances, testvariant) => {
    let attendancesList = [];
    if(attendances.length > 0){
        if(testvariant != null){
            attendances.map(item => {
                if(item.ref.testname === testvariant.value){
                    attendancesList.push(
                        {
                            identification: item.ref.identification,
                            finishedAt: item.ref.finishedAt,
                            locale: item.ref.locale,
                            testname: item.ref.testname,
                            position: item.ref.position
                        }
                    )
                }
            })
        }else{
            attendances.map(item => {
                attendancesList.push(
                    {
                        identification: item.ref.identification,
                        finishedAt: item.ref.finishedAt,
                        locale: item.ref.locale,
                        testname: item.ref.testname,
                        position: item.ref.position
                    }
                )

            })

        }
    }
    return attendancesList
}

const mapStateToProps = (state) => ({
    filter: state.result.list.filter,
    profiles: profiles(state.result.base.profiles),
    selected: state.result.list.selected,
    attendances: attendances(state.result.list.attendances, state.result.list.filter.testvariant),
    tests: tests(state.result.list.tests),
    listDownloads: state.result.list.downloadList,
    listFormValidation: state.result.list.listFormValidation
});

const mapDispatchToProps = (dispatch) => ({
    setListFilterTimespan: (timespan) => dispatch(ListActions.setListFilterTimespan(timespan)),
    setListFilterProfile: (profile) => dispatch(ListActions.setListFilterProfile(profile)),
    setListFilterPosition: (position) => dispatch(ListActions.setListFilterPosition(position)),
    setListFilterFile: (file) => dispatch(ListActions.setListFilterFile(file)),
    setListFilterSort: (sort) => dispatch(ListActions.setListFilterSort(sort)),
    setListFilterLocale: (locale) => dispatch(ListActions.setListFilterLocale(locale)),
    setListFilterTestvariant: (testvariant) => dispatch(ListActions.setListFilterTestvariant(testvariant)),
    setListTableSelect: (key) => dispatch(ListActions.setListTableSelect(key)),
    setListTableSelectAll: (a,b) => dispatch(ListActions.setListTableSelectAll(a,b)),
    setListFilterFormat: (format) => dispatch(ListActions.setListFilterFormat(format)),
    requestList: (cb) => dispatch(ListActions.requestList(cb)),
    startListDownload: (interval) => dispatch(ListActions.startListDownload(interval)),
    pollListDownload: () => dispatch(ListActions.pollListDownload())
});

/**
 * created by stephanpudras at 14.05.20
 */
class ListForm extends Component {
    constructor(props) {
        super(props);
        this.getFilename = this.getFilename.bind(this);
        this.getTableDataFlat = this.getTableDataFlat.bind(this);
        this.requestList = this.requestList.bind(this);
        this.startDownload = this.startDownload.bind(this);
        this.state = {byRef: false, tableEntries:[], files:[], divClass:""};
        this.Base64Handler = new Base64Handler()
    }

    componentDidMount(){
        //this.setState({
        //    divClass: "row form-group animate__animated animate__flipInX"
        //})
        //setTimeout(() => {this.setState({
        //    divClass:"row form-group"
        //})},2000)
    }



    requestList(){
        this.props.requestList(this.startDownload)
    }

    startDownload(){
        this.props.startListDownload(setInterval(this.props.pollListDownload, 1500))
    }

    getFilename(e){
        let b64 = new Base64Handler()
        if(e != null && e !== "undefined" && e.length > 0){
            b64.handleFile(e, this.props.setListFilterFile)
        }else{
            this.props.setListFilterFile(null)
        }
    }

    getTableDataFlat(){
        let data = []
        this.props.attendances.map(item => {
            if(item.hasOwnProperty("entity")){
                data.push({
                    identification: item.ref.identification,
                    finishedAt: item.ref.finishedAt,
                    locale: item.ref.locale,
                    testname: item.ref.testname,
                    position: item.ref.position
                });
            }
        });
        return data;
    }

    timespan = () => {
        let timespan = [];
        console.log(this.props.i18next)
        if(this.props.i18next !== undefined && this.props.i18next.language !== undefined){
            switch(this.props.i18next.language.toUpperCase()){
                case "DE":
                    timespan.push({label: "1 Tag", value:Timespan.ONE_DAY})
                    timespan.push({label: "1 Woche", value:Timespan.ONE_WEEK})
                    timespan.push({label: "2 Wochen", value:Timespan.TWO_WEEKS})
                    timespan.push({label: "4 Wochen", value:Timespan.FOUR_WEEKS})
                    timespan.push({label: "2 Monate", value:Timespan.TWO_MONTHS})
                    timespan.push({label: "3 Monate", value:Timespan.THREE_MONTHS})
                    timespan.push({label: "6 Monate", value:Timespan.SIX_MONTHS})
                    break
                case "EN":
                    timespan.push({label: "1 day", value:Timespan.ONE_DAY})
                    timespan.push({label: "1 week", value:Timespan.ONE_WEEK})
                    timespan.push({label: "2 weeks", value:Timespan.TWO_WEEKS})
                    timespan.push({label: "4 weeks", value:Timespan.FOUR_WEEKS})
                    timespan.push({label: "2 months", value:Timespan.TWO_MONTHS})
                    timespan.push({label: "3 months", value:Timespan.THREE_MONTHS})
                    timespan.push({label: "6 months", value:Timespan.SIX_MONTHS})
                    break
            }
        }
        return timespan;
    }

    toggleSelection = (key, shift, row) => {
        let k = key.split("-")
        this.props.setListTableSelect(k[1]);
    };

    toggleAll = () =>{
        const selectAll = !this.props.selected.selectAll;
        const selection = [];
        if (selectAll) {
            // we need to get at the internals of ReactTable
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            // the 'sortedData' property contains the currently accessible records based on the filter and sort
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            // we just push all the IDs onto the selection array
            currentRecords.forEach(item => {
                selection.push(item._original.identification);
            });
        }
        this.props.setListTableSelectAll(selection, selectAll);
        //this.setState({ selectAll, selection });
    };

    isSelected = key => {
        /*
          Instead of passing our external selection state we provide an 'isSelected'
          callback and detect the selection state ourselves. This allows any implementation
          for selection (either an array, object keys, or even a Javascript Set object).
        */
        return this.props.selected.selection.includes(key);
    };

    tableHeaders = () => {
        return [
            {
                Header: this.props.t("tHeadLabels.logincode"),
                accessor: "identification"
            },
            {
                Header: this.props.t("tHeadLabels.finished"),
                accessor: "finishedAt",
                sortMethod: (a, b) => {
                    let oA = getTime(parseISO(a));
                    let oB = getTime(parseISO(b));
                    if(oA < oB)
                        return 1;
                    else
                        return -1;
                },
                Cell: row => {
                    return getLocalizedDateShort(new Date(row.original.finishedAt), this.props.i18n.language)
                }


            },
            {
                Header: this.props.t("tHeadLabels.locale"),
                accessor: "locale"
            },
            {
                Header: this.props.t("tHeadLabels.test"),
                accessor: "testname"
            }
        ];
    };

    render() {
        return (
                <div className={this.state.divClass}>
                    <div className="col-12">
                        <div className="row">
                            <ListFormProvider formSchema={{locale:this.props.lng, schema: ListFormSchema}}>
                                <div className="col-xl-2 col-md-5 col-xs-12">
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <ExtSelect
                                                name="attendance"
                                                label={this.props.t("resultsListForm.labels.timespan")}
                                                placeholder={this.props.t("resultsListForm.labels.timespan")}
                                                value={this.props.filter.timespan}
                                                onChange={this.props.setListFilterTimespan}
                                                options={this.timespan()}
                                                isClearable={true}
                                                validation={this.props.listFormValidation}
                                                customValue = {true}
                                                lang={this.props.i18next.language}
                                            />
                                        </div>
                                        <div className="flex-grow-0 popover-help-wrapper-low">
                                            <Tooltip placement={"bottom"} overlay={<HelpPopover module="extended_list" element="timespan"/>} trigger={"click"}><span className="icon-help-with-circle"></span></Tooltip>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <ExtSelect
                                                        placeholder={this.props.t("resultsListForm.labels.testvariant")}
                                                        value={this.props.filter.testvariant}
                                                        onChange={this.props.setListFilterTestvariant}
                                                        options={this.props.tests}
                                                        isClearable={true}
                                                        label={this.props.t("resultsListForm.labels.testvariant")}
                                                        validation={this.props.listFormValidation}
                                                        name="testvariant"
                                                        lang={this.props.i18next.language}
                                                    />
                                                </div>
                                                <div className="flex-grow-0 popover-help-wrapper-low">
                                                    <Tooltip placement={"bottom"} overlay={<HelpPopover module="extended_list" element="test_variant"/>} trigger={"click"}><span className="icon-help-with-circle"></span></Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <ExtSelect
                                                        placeholder={this.props.t("resultsListForm.labels.format")}
                                                        value={this.props.filter.format}
                                                        onChange={this.props.setListFilterFormat}
                                                        options={[{value:"XLSX", label: "Excel"},{value:"PDF", label: "PDF"}]}
                                                        isClearable={true}
                                                        label={this.props.t("resultsListForm.labels.format")}
                                                        validation={this.props.listFormValidation}
                                                        name="format"
                                                        lang={this.props.i18next.language}
                                                    />
                                                </div>
                                                <div className="flex-grow-0 popover-help-wrapper-low">
                                                    <Tooltip placement={"bottom"} overlay={<HelpPopover module="extended_list" element="format"/>} trigger={"click"}><span className="icon-help-with-circle"></span></Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-10 col-md-7 col-xs-12">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <ExtSelect
                                                            placeholder={this.props.t("resultsListForm.labels.profile")}
                                                            value={this.props.filter.profile}
                                                            onChange={this.props.setListFilterProfile}
                                                            options={this.props.profiles}
                                                            isClearable={true}
                                                            label={this.props.t("resultsListForm.labels.profile")}
                                                            validation={this.props.listFormValidation}
                                                            name="profile"
                                                            lang={this.props.i18next.language}
                                                        />
                                                    </div>
                                                    <div className="flex-grow-0 popover-help-wrapper-low">
                                                        <Tooltip placement={"bottom"} overlay={<HelpPopover module="extended_list" element="profile"/>} trigger={"click"}><span className="icon-help-with-circle"></span></Tooltip>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <ExtSelect
                                                            placeholder={this.props.t("resultsListForm.labels.sort")}
                                                            value={this.props.filter.sort}
                                                            onChange={this.props.setListFilterSort}
                                                            options={[
                                                                {
                                                                    label:this.props.t("resultsListForm.sort.labels.alpha"),
                                                                    options:[{
                                                                        label: <div><span className="icon icon-arrow-bold-up"> </span>{this.props.t("resultsListForm.sort.labels.alpha")+" "+this.props.t("resultsListForm.sort.asc")}</div>,
                                                                        value: "ALPHA_ASC",
                                                                        isDisabled: this.props.filter.file == null
                                                                    },
                                                                        {
                                                                            label: <div><span className="icon icon-arrow-bold-down"> </span>{this.props.t("resultsListForm.sort.labels.alpha")+" "+this.props.t("resultsListForm.sort.desc")}</div>,
                                                                            value: "ALPHA_DESC",
                                                                            isDisabled: this.props.filter.file == null
                                                                        }]
                                                                },
                                                                {
                                                                    label: this.props.t("resultsListForm.sort.labels.identification"),
                                                                    options:[
                                                                        {
                                                                            label: <div><span className="icon icon-arrow-bold-up"> </span>{this.props.t("resultsListForm.sort.labels.identification")+" "+this.props.t("resultsListForm.sort.asc")}</div>,
                                                                            value: "IDENTIFICATION_ASC"
                                                                        },
                                                                        {
                                                                            label: <div><span className="icon icon-arrow-bold-down"> </span>{this.props.t("resultsListForm.sort.labels.identification")+" "+this.props.t("resultsListForm.sort.desc")}</div>,
                                                                            value: "IDENTIFICATION_DESC"
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    label: this.props.t("resultsListForm.sort.labels.date"),
                                                                    options: [
                                                                        {
                                                                            label: <div><span className="icon icon-arrow-bold-up"> </span>{this.props.t("resultsListForm.sort.labels.date") + " " + this.props.t("resultsListForm.sort.asc")}</div>,
                                                                            value: "FINISH_DATE_ASC",
                                                                            icon: <span className="icon icon-arrow-bold-up"> {this.props.t("resultsListForm.sort.labels.date") + " " + this.props.t("resultsListForm.sort.asc")}</span>,
                                                                        },
                                                                        {
                                                                            label: <div><span className="icon icon-arrow-bold-down"> </span>{this.props.t("resultsListForm.sort.labels.date") + " " +this.props.t("resultsListForm.sort.desc")}</div>,
                                                                            value: "FINISH_DATE_DESC",
                                                                            icon:<span className="icon icon-arrow-bold-up"> </span>,
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    label: this.props.t("resultsListForm.sort.labels.locale"),
                                                                    options: [
                                                                        {
                                                                            label: <div><span className="icon icon-arrow-bold-up"> </span>{this.props.t("resultsListForm.sort.labels.locale")+" "+this.props.t("resultsListForm.sort.asc")}</div>,
                                                                            value: "LOCALE_ASC"
                                                                        },
                                                                        {
                                                                            label: <div><span className="icon icon-arrow-bold-down"> </span>{this.props.t("resultsListForm.sort.labels.locale")+" "+this.props.t("resultsListForm.sort.desc")}</div>,
                                                                            value: "LOCALE_DESC"
                                                                        }
                                                                    ]
                                                                }
                                                            ]}
                                                            isClearable={true}
                                                            label={this.props.t("resultsListForm.labels.sort")}
                                                            validation={this.props.listFormValidation}
                                                            name="sort"
                                                            lang={this.props.i18next.language}
                                                        />
                                                    </div>
                                                    <div className="flex-grow-0 popover-help-wrapper-low">
                                                        <Tooltip placement={"left"} overlay={<HelpPopover module="extended_list" element="sort"/>} trigger={"click"}><span className="icon-help-with-circle"></span></Tooltip>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <Input name="position"
                                                           value={this.props.filter.position}
                                                           onChange={this.props.setListFilterPosition}
                                                           label={this.props.t("resultsListForm.labels.position")}
                                                           notNull={true}
                                                           type={"text"}
                                                           validation={this.props.listFormValidation}
                                                           lang={this.props.i18next.language}
                                                    />
                                                </div>
                                                <div className="flex-grow-0 popover-help-wrapper-low">
                                                    <Tooltip placement={"bottom"} overlay={<HelpPopover module="extended_list" element="position"/>} trigger={"click"}><span className="icon-help-with-circle"></span></Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <ExtSelect
                                                            placeholder={this.props.t("resultsListForm.labels.locale")}
                                                            value={this.props.filter.locale}
                                                            onChange={this.props.setListFilterLocale}
                                                            options={[
                                                                {label: this.props.t("locale.long.de"), value: "de" },
                                                                {label: this.props.t("locale.long.en"), value: "en" }
                                                            ]}
                                                            label={this.props.t("resultsListForm.labels.locale")}
                                                            validation={this.props.listFormValidation}
                                                            name="locale"
                                                            lang={this.props.i18next.language}
                                                        />
                                                    </div>
                                                    <div className="flex-grow-0 popover-help-wrapper-low">
                                                        <Tooltip placement={"left"} overlay={<HelpPopover module="extended_list" element="locale"/>} trigger={"click"}><span className="icon-help-with-circle"></span></Tooltip>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row form-group">
                                        <div className="col-6">
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <SingleFileInput
                                                        label={this.props.t("resultsListForm.labels.naming_file")}
                                                        name="file"
                                                        validation={this.props.listFormValidation}
                                                        file={this.props.filter.file}
                                                        onChange={this.getFilename}
                                                        lang={this.props.i18next.language}
                                                    />
                                                </div>
                                                <div className="flex-grow-0 popover-help-wrapper-low">
                                                    <Tooltip placement={"bottom"} overlay={<HelpPopover module="extended_list" element="file"/>} trigger={"click"}><span className="icon-help-with-circle"></span></Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            {
                                                this.props.listDownloads.ready ?
                                                    <button style={{marginTop: "1.5rem"}} className="btn btn-outline-success btn-block" onClick={(e) => this.Base64Handler.saveList(this.props.listDownloads.files)}>
                                                        {this.props.t("resultsListForm.labels.button_finished")}
                                                    </button>
                                                    :
                                                    (
                                                        !this.props.listDownloads.ready && this.props.listDownloads.pending ?
                                                            <button style={{marginTop: "1.5rem"}} className="btn btn-outline-info btn-block">
                                                                {this.props.t("resultsListForm.labels.button_working")}
                                                            </button>
                                                            :
                                                            <button style={{marginTop: "1.5rem"}} className="btn btn-outline-primary btn-block" onClick={(e) => this.requestList()}>
                                                                <span className="icon-download"> </span>{this.props.t("resultsListForm.labels.button_generate")}&nbsp;<span className="icon-download"> </span>
                                                            </button>
                                                    )
                                            }

                                        </div>
                                    </div>
                                </div>
                            </ListFormProvider>
                        </div>
                    </div>
                </div>
        )
    }
}
const listFormTrans = withTranslation(["common", "help_popover"])(ListForm);
export default connect(mapStateToProps, mapDispatchToProps)(listFormTrans);