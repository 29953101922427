import ResultsConst from "../const/ResultsConst";
import RequestEntity from "../../../api/RequestEntity";
import URLConstants from "../../../api/URLConstants";
import AppConst from "../../../AppConst";

const BASE_URL = URLConstants.getURL();

const BaseActions = {
    fetchPositions:() => {
        return (dispatch, getState) => {
            let customerId = getState().app.portal.userInfo.customer.id;
            dispatch({type:AppConst.AJAX_CALL, entity:new RequestEntity(BASE_URL + "/portal/personal/job/" + customerId + "/list", "GET", ResultsConst.FETCH_POSITIONS)})
        }
    },
    fetchProfiles: () => {
        return (dispatch, getState) => {
            let customerId = getState().app.portal.userInfo.customer.id;
            dispatch({type: AppConst.AJAX_CALL, entity: new RequestEntity(BASE_URL + "/portal/profile/" + customerId + "/list", "GET", ResultsConst.FETCH_PROFILES)});
        }
    },
    switchForm: (form) => {
        return {type: "SWITCH_FORM", payload: form}
    }
};

export default BaseActions;