/**
 * Application reducer. State changing functions, which are used app wide.
 */
import AppConst from "../AppConst";
import {combineReducers} from "redux";
import assert from "assert";
import {Keycloak} from "../types";

/**
 * Basic reducer (no functions yet, really). Will react on ajax calls made through @Link api.js
 * Change it as needed.
 *
 * @param  {Object} [state={kc_token: null]        String
 * @param  {[type]} kc_refreshToken   String
 * @param  {[type]} [action=null]     Filler for the real action as the store initially receives a function with empty action
 * @return {[type]}                   the state.
 */
const sb = [

        {
            topic: "sidebar.started",
            link: "/results/started",
            role: "READ_ATTENDANCE_RESULT",
            validate: (userInfo:any) => {
                return !!(userInfo !== "undefined" && userInfo != null && userInfo.customer && userInfo.recruiterId);
            },
        },
        {
            topic: "sidebar.results",
            link: "/results/finished",
            role: "READ_ATTENDANCE_RESULT",
            validate: (userInfo:any) => {
                return !!(userInfo !== "undefined" && userInfo != null && userInfo.customer && userInfo.recruiterId);
            },
        },
        {
            topic: "sidebar.profile",
            link: "/profile/overview",
            role: "READ_ATTENDANCE_RESULT",
            validate: (userInfo:any) => {
                return !!(userInfo !== "undefined" && userInfo != null && userInfo.customer && userInfo.recruiterId);
            },
        },
    {
        topic: "sidebar.attendance.create",
        link: "/attendance/create",
        role: "READ_ATTENDANCE_RESULT",
        validate: (userInfo:any) => {
            return !!(userInfo !== "undefined" && userInfo != null && userInfo.customer && userInfo.recruiterId);
        },
    },
        {
            topic: "sidebar.administration",
            link: "/admin",
            role: "ROLE_OWNER_ADMIN",
            subitems:[
                {
                    topic: "sidebar.synchronize",
                    link: "/bootstrap"
                },
                {
                    topic: "sidebar.profilemanagement",
                    link: "/management"
                },
                {
                    topic: "sidebar.interviews",
                    link: "/interviews"
                },
                {
                    topic: "sidebar.positions",
                    link: "/positions"
                },
                {
                    topic: "sidebar.nameMapping",
                    link: "/nameMapping"
                },
                {
                    topic: "sidebar.testvariants",
                    link: "/testvariants"
                }
            ]
        }
    ];



const keycloak = (state:Keycloak = {
    keycloak: undefined,
    userProfile: undefined
} , action: any) => {
    if(action !== null){
        switch (action.type) {
            case AppConst.LOGIN:
                return Object.assign({}, state, {
                    keycloak: action.payload
                });
            case AppConst.USERPROFILE:
                return Object.assign({}, state, {
                    userProfile: action.payload
                });
            case AppConst.LOGOUT:
                return Object.assign({}, state, {
                    kc_token: null,
                    kc_refreshToken: null
                });
            case AppConst.REFRESH_TOKEN:
                return Object.assign({}, state, {
                    kc_token: action.payload.token.kc_token,
                    kc_refreshToken: action.payload.token.kc_refreshToken
                });
            default:
                return state;
        }
    }else{
        return state;
    }
};

const cust = (state = [], action:any) => {
    switch (action.type) {
        case AppConst.GET_CUSTOMER:
            return Object.assign([], state, action.payload);
        default:
            return state;
    }
};

const portal = (state = {userInfo: null}, action:any) => {
    switch (action.type) {
        case AppConst.GET_PORTALUSERINFO:
            return Object.assign({}, state, {userInfo: action.payload});
        default:
            return state;
    }
};

const loadingButton = (state = {pressed: false}, action:any) => {
    switch (action.type) {
        case AppConst.GET_CUSTOMER:
            return Object.assign({}, state, {pressed: true});
        default:
            return state;
    }
};

const sidebar = (state=sb, action:any) => {
  switch(action.type){
    default:
        return state;
  }
}

const sidebar_state = (state={active:null, activeLink:null}, action:any) => {
  switch(action.type){
    case AppConst.SIDEBAR_OPEN_TOPIC:
      return Object.assign({}, state,
          {
              active: action.payload.topic === state.active ? null : action.payload.topic,
              activeLink: action.payload.link === state.activeLink ? null : action.payload.link
          });
    default:
      return state;
  }
};

const localizedTooltip = (state = {}, action:any) => {
    switch(action.type){
        case AppConst.LOAD_LOCALIZED_TOOLTIP:
            return Object.assign({}, action.payload);
        default:
            return state;
    }
};



const app = combineReducers({keycloak, portal, cust, loadingButton, sidebar, sidebar_state});
export default app;
