import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, ModalRoot} from "@mui/material";
import {useAbciProfileDocument} from "../state/AbciProfileDocument";
import {Suspense, useEffect} from "react";
import {AbciProfileDocumentView} from "../components/document/profile/AbciProfileDocumentView";
import {Close, PictureAsPdf, Print} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

export const ProfileDocumentModal = ():JSX.Element => {
  const [document, profileId, profile,isOpen, open, fetch] = useAbciProfileDocument((state) => [state.document, state.profileId, state.profile, state.isOpen, state.open, state.fetch])
  const {i18n, t} = useTranslation("common")

  return (
    <Dialog maxWidth={"lg"}  classes={{paperFullScreen: "prePrint"}} fullScreen open={isOpen} onClose={() => open()}>
      <DialogTitle className="no-print">
        <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
          <Box>{t("modal.title.requirementProfile")}</Box>
          <Box><Button onClick={() => open()}><Close/></Button></Box>
        </Box>
      </DialogTitle>
      <DialogContent id="DialogPrint" className="do-print">
        {
          document != undefined ?
            <Suspense fallback={"Loading..."}>
              <AbciProfileDocumentView document={document} profile={profile}/>
            </Suspense>
            :
            null
        }
      </DialogContent>
      <DialogActions className="no-print">
        <div className="no-print">
          <Button className="no-print" variant={"contained"} color={"primary"} onClick={() => print()}><Print/>&nbsp;{t("button.print")}</Button>
          &nbsp;
          <Button className="no-print" variant={"contained"} color={"info"}><PictureAsPdf/>&nbsp;PDF</Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}