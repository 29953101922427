import {Snackbar} from "@mui/material";
import {Alert} from "./BasicAlert";
import React from "react";
import {useShareResponse} from "../state/ShareResponseAlert";
import {useTranslation} from "react-i18next";

export const ProfileShareToast = () => {
  const [isOpen, isSuccess, message, close] = useShareResponse((state) => [state.isOpen, state.isSuccess, state.message, state.close])
  const {t,i18n} = useTranslation("common")
  return (
    <Snackbar open={isOpen} autoHideDuration={6000} onClose={close}>
      <Alert onClose={close} severity={isSuccess ? "success":"error"} sx={{ width: '100%' }}>
        {isSuccess ? t("snackbar.profile.share.success", {profileName:message}):t("snackbar.profile.share.error", {profileName:message})}
      </Alert>
    </Snackbar>
  )
}