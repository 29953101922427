import FileSaver from "file-saver";
import base64js  from 'base64-js'


class Base64Handler {
  constructor(props){
    this.getBase64 = this.getBase64.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.saveReports = this.saveReports.bind(this);
    this.base64toBlob = this.base64toBlob.bind(this);
    this.getBlobDataAtOnce = this.getBlobDataAtOnce.bind(this);
    this.getBlobDataSliced = this.getBlobDataSliced.bind(this);
    this.saveList = this.saveList.bind(this);
  }

  base64toBlob(base64Data, contentType, sliceSize) {
    let byteCharacters,
        byteArray,
        byteNumbers,
        blobData,
        blob;

    contentType = contentType || '';

    byteCharacters = atob(base64Data);

    // Get blob data sliced or not
    blobData = sliceSize ? this.getBlobDataSliced(byteCharacters, sliceSize) : this.getBlobDataAtOnce(byteCharacters);

    blob = new Blob(blobData, {type: contentType});

    return blob;
}

getBlobDataAtOnce(byteCharacters){
    let byteNumbers;
    let byteArray;
    /*
     * Get blob data in one slice.
     * => Fast in IE on new Blob(...)
     */
    byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    byteArray = new Uint8Array(byteNumbers);

    return [byteArray];
}

getBlobDataSliced(byteCharacters, sliceSize){
    /*
     * Get blob data in multiple slices.
     * => Slow in IE on new Blob(...)
     */
    let byteNumbers;
    let byteArray;

    let slice,
        byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        slice = byteCharacters.slice(offset, offset + sliceSize);

        byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        byteArray = new Uint8Array(byteNumbers);

        // Add slice
        byteArrays.push(byteArray);
    }

    return byteArrays;
}

  getBase64(file) {
      let openFile = function(event) {
          let input = event.target;

          let reader = new FileReader();
          reader.onload = function(){
              let text = reader.result;
          };
          reader.readAsArrayBuffer(input.files[0]);
      };
      return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsArrayBuffer(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
      });
  }

  handleFile(e, cb){
          let reader = new FileReader();
          let setFile = cb;
          let file = e[0];
          reader.onload = function(){
              let text = reader.result;
              let binary = '';
              let bytes = new Uint8Array( text );
              let len = bytes.byteLength;
              for (let i = 0; i < len; i++) {
                  binary += String.fromCharCode( bytes[ i ] );
              }
              let s = base64js.fromByteArray(binary);
              setFile({file: text, fileName: file.name, mimetype: file.type});

          };
          reader.readAsDataURL(e[0]);
  }

  saveReports(files){
      if(files.length > 0){
          let saves = [];
          let blobs = [];
          let saveInterval = null;
          files.map(file => {
              blobs.push({blob:this.base64toBlob(file.b64encodedFile,file.fileType, 16), filename: file.fileName})
          });

          blobs.map((blob, index) => {
              setTimeout(() => {
                  let a = document.createElement("a");
                  a.style = "display: none";
                  a.href = window.URL.createObjectURL(blob.blob);
                  a.download = blob.filename;
                  document.body.appendChild(a);
                  a.click();
                  window.URL.revokeObjectURL(a.href);
              }, 1000 * (index+1));
             // saves.push(a);
          });
      }
  }

  saveRanking(payload){
      if(payload.format === "PDF") {
          FileSaver.saveAs(this.base64toBlob(payload.file, "application/pdf", 16), payload.filename);
      }
      if(payload.format === "EXCEL") {
          FileSaver.saveAs(this.base64toBlob(payload.file, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", 16), payload.filename);
      }
  }

  saveList(payload){
      payload.map(item => {
          FileSaver.saveAs(this.base64toBlob(item.b64encodedFile, "application/pdf", 16), item.fileName);
      })
  }


}

export default Base64Handler;
