import {combineReducers} from "redux";
import ResultsConst from "../const/ResultsConst";


const positions = (state={positions:[], selectedFinished:null, selectedInwork:null, selectedStarted:null}, action=null) => {
    switch(action.type){
        case ResultsConst.FETCH_POSITIONS:
            return Object.assign({}, state, {positions: action.payload, selectedFinished:action.payload.length > 0 ? action.payload[0] : null});
        case ResultsConst.SELECT_POSITION_FINISHED:
            return Object.assign({}, state, {selectedFinished: action.payload != null ? action.payload.value : null});
        case ResultsConst.SELECT_POSITION_INWORK:
            return Object.assign({}, state, {selectedInwork: action.payload != null ? action.payload.value : null});
        case ResultsConst.SELECT_POSITION_STARTED:
            return Object.assign({}, state, {selectedStarted: action.payload != null ? action.payload.label : null});
        default:
            return state;
    }
};

const profiles = (state={profiles:[], selected: null}, action=null) => {
    switch(action.type){
        case ResultsConst.FETCH_PROFILES:
            return Object.assign({}, state, {profiles:action.payload});
        case ResultsConst.CHOOSE_PROFILE:
            return Object.assign({}, state, {selected:action.payload});
        default:
            return state;
    }
};

const base = combineReducers({positions, profiles});
export {base};