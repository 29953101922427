import React from "react";
import {Button, Menu, MenuItem, Tooltip} from "@mui/material";
import {AbciDocumentProfileShort, useAbciProfileDocument} from "../../../state/AbciProfileDocument";
import {ManageSearch} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

export interface AbciProfileDocumentMenuProps {
  profile: AbciDocumentProfileShort
}

export const AbciProfileDocumentMenu = (props:AbciProfileDocumentMenuProps) =>{
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [fetch] = useAbciProfileDocument((state) => [state.fetch])
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const {i18n,t} = useTranslation("common")

  const handleClose = (variant: string) => {
    console.log(props.profile)
    fetch(props.profile, variant, i18n.language)
    setAnchorEl(null);
  };

  return (
    <Tooltip title={t("tooltip.profileEdit.overview")} arrow={true} placement={"top"}>
    <div>

        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          variant={"contained"}
          color={"primary"}
          size={"small"}
        >
          <ManageSearch/>
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={() => handleClose("default")}>{t("testvariants.DEFAULT")}</MenuItem>
          <MenuItem onClick={() => handleClose("default_dt")}>{t("testvariants.DEFAULT_DT")}</MenuItem>
          <MenuItem onClick={() => handleClose("azubi")}>{t("testvariants.AZUBI")}</MenuItem>
          <MenuItem onClick={() => handleClose("cognitive")}>{t("testvariants.COGNITIVE")}</MenuItem>
        </Menu>

    </div>
</Tooltip>
  );
}