import {Dispatch, Middleware, MiddlewareAPI} from "redux";

const asyncDispatchMiddleware:Middleware = (api: MiddlewareAPI) => (next:Dispatch) => action => {
    let syncActivityFinished = false;
    let actionQueue:any[] = [];

    function flushQueue() {
        actionQueue.forEach(a => api.dispatch(a)); // flush queue
        actionQueue = [];
    }

    function asyncDispatch(asyncAction:any) {
        actionQueue = actionQueue.concat([asyncAction]);
        if (syncActivityFinished) {
            flushQueue();
        }
    }

    const actionWithAsyncDispatch =
        Object.assign({}, action, {asyncDispatch});

    const res = next(actionWithAsyncDispatch);

    syncActivityFinished = true;
    flushQueue();
    return res;
};

export default asyncDispatchMiddleware;