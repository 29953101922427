import AppConst from "./AppConst";
import RequestEntity from "./api/RequestEntity";
import URLConstants from './api/URLConstants';
import {fetchPositions} from "./bootstrap/slices/positionSlice";

const BASE_URL = URLConstants.getURL();
const JSON_URL = URLConstants.getJson();

/**
 * Actions, which are possibly used app wide.
 *
 * Shows the basic use of action creators. A function should ALWAYS return something (null, if necessary).
 * This js could be imported into any component, which needs those actions.
 * @type {Object}
 */
const AppActions = {
  login:(keycloak) => {
    return (dispatch, getState) => {
      keycloak.loadUserProfile().then(p => {
        dispatch({type: AppConst.USERPROFILE, payload: p})
      });
     dispatch({type:AppConst.LOGIN, login:true, payload: keycloak})
    }
  },
  customer:() => {
    return {type: AppConst.AJAX_CALL, entity: new RequestEntity(BASE_URL + '/portal/customer/sync', "GET", AppConst.GET_CUSTOMER)}
  },
  portalUserInfo:(portalUser) => {
      return {type: AppConst.GET_PORTALUSERINFO, payload: portalUser}
  },
  sidebarOpenTopic:(topic, link) => {
    return {type: AppConst.SIDEBAR_OPEN_TOPIC, payload: {topic: topic, link:link}}
  },
    loadLocalizedTooltip: (locale) => {
        return {type: AppConst.AJAX_CALL, entity: new RequestEntity(JSON_URL + '/locales/de/tooltip.json', "GET", AppConst.LOAD_LOCALIZED_TOOLTIP)}
    },
    loadLocale: (locale) => {
        return {type: AppConst.LOAD_LOCALE, payload:locale}
    },
  logToServer: (error, info) => {
      return {type: AppConst.AJAX_CALL, entity: new RequestEntity(BASE_URL + "/portal/logging/frontend", "POST", null), payload: {error: true, info: error.stack.toString()}}
  }

  /**
   * Doing the same async...
   */
};
export default AppActions;
