import axios from "axios";
import {PortalUser, Position} from "../../types";
import URLConstants from "../../api/URLConstants";

const url = URLConstants.getURL()


interface GetPortalUserInfoResponse {
    result: any
}

export async function loadPortalUserInfo(token: string): Promise<any> {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token
    const response = await axios.get<GetPortalUserInfoResponse>(url + "/portal/user/info");
    return response.data;
}