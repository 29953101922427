import * as React from "react";
import ListFormSchema from "../../schema/ListFormSchema";
import {FormContext} from "../../module/results/view/List";
import ListFormContext from "../../context/ListFormContext";
import FadeInDown from "../style/FadeInDown";

class Input extends React.Component {
    static contextType = ListFormContext
    constructor(props,context) {
        super(props, context);
        this.validate = this.validate.bind(this);
        this.state = {valueValid: true, validate: false, value:""}
        this.elValid = this.props.validation.elements.indexOf(this.props.name) > 0;
        this.parentValid = this.props.validation.validate;
    }

    validate(e){
        let valueValid = this.context.schema.components.find(item => item.name === this.props.name).validation(e.target.value);
        this.setState({valueValid: valueValid, value:e.target.value});
        this.props.onChange(e.target.value);
    }

    componentDidMount() {

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props !== prevProps){
            this.setState({
                validate: this.state.valueValid && this.props.validation.elements.includes(this.props.name) > 0 && this.props.validation.validate
            })

        }

    }

    render(){
        return(
            <div className="form-group">
                <label className="form-check-label" htmlFor={this.props.name}>{this.props.label}</label>
                    <input name={this.props.name}
                           value={this.props.value}
                           onChange={(e) => this.validate(e)}
                           type={this.props.type}
                           className="form-control"/>

                <div>
                    {
                        this.state.validate ?
                        <div className="animate__animated animate__bounceIn" style={{color:"red", fontSize:"0.8rem"}}>
                            {
                                this.props.lang.toUpperCase() === "DE" ?
                                    this.context.schema.components.find(item => item.name===this.props.name).message.de
                                    :
                                    this.context.schema.components.find(item => item.name===this.props.name).message.en
                            }
                        </div>
                        :
                        null
                    }
                </div>
            </div>
        )
    }
}

export default Input;