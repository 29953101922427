import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch, AppThunk} from "../../../../store";
import {AttendanceCreationProperties} from "../../../../types";

const initialState:AttendanceCreationProperties = {
    position: undefined,
    count: 1,
    testvariant: "DEFAULT"
}

const creationPropertiesSlice = createSlice({
    name: "creationProperties",
    initialState,
    reducers: {
        changeProps(state, action:PayloadAction<AttendanceCreationProperties>){
            return action.payload
        }
    }
})

export const changeCreationProperties = (props:AttendanceCreationProperties):AppThunk => async (dispatch: AppDispatch) => {
    dispatch(creationPropertiesSlice.actions.changeProps(props))
}

export default creationPropertiesSlice.reducer;