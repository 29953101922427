import React, {useEffect} from 'react';
import clsx from 'clsx';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import {
    ActionType, Column,
    defaultColumn, Meta, Row, TableInstance, TableState,
    useGlobalFilter,
    usePagination,
    useRowSelect,
    useSortBy,
    useTable,
} from 'react-table'
import TablePaginationActions from "./TablePaginationActions";
import {Button, Grid, TableFooter} from "@mui/material";
import {Attendance, Attendance_Entity, AttendanceCreationProperties, PortalUser, UserProfile} from "../../types";
import {exportToExcel} from "../../logic/excel/ExcelExport";
import {saveAs} from 'file-saver';
import {FileWrite} from "../../utils/FileWrite";
import {useTranslation} from "react-i18next";
import {Save} from "@mui/icons-material";
import {BasicTooltip} from "../BasicTooltip";

interface Props {
    indeterminate?: boolean;
    name: string;
}

const useCombinedRefs = (...refs: React.Ref<any | null>[]): React.MutableRefObject<any> => {
    const targetRef:React.Ref<any> = React.useRef<any | null>(null);

    React.useEffect(() => {
        refs.forEach(ref => {
            if (!ref) return;

            if (typeof ref === 'function') {
                ref(targetRef.current);
            } else {
                (ref.current  as React.MutableRefObject<any>).current = targetRef.current;
            }
        });
    }, [refs]);

    return targetRef;
};

const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, Props>(
    ({ indeterminate, ...rest }, ref: React.Ref<HTMLInputElement>) => {
        const defaultRef = React.useRef(null);
        const combinedRef = useCombinedRefs(ref, defaultRef);

        useEffect(() => {
            if (combinedRef?.current) {
                combinedRef.current.indeterminate = indeterminate ?? false;
            }
        }, [combinedRef, indeterminate]);

        return (
            <React.Fragment>
                <Checkbox ref={combinedRef} {...rest} />
            </React.Fragment>
        );
    }
);

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface TBProps {
    raw: Attendance[]
    portalUser: any
    position?: string | undefined
    creationProperties: AttendanceCreationProperties
}
const EnhancedTableToolbar = (props: TBProps) => {
    const {t} = useTranslation(["common"])
    const tooltipstr:string = t("createAttendance.excel.button.tooltip")
    return (
        <Toolbar >
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Typography  variant="h6" id="tableTitle" component="div">
                    {t("createAttendance.table.toolbar.header")}
                </Typography>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <BasicTooltip title={tooltipstr}>
                    <span>
                        <Button disabled={props.raw.length < 1} variant="outlined" startIcon={<Save/>} onClick={(e) => {FileWrite.writeExcel(props)}}>Excel</Button>
                    </span>
                </BasicTooltip>
            </Grid>
        </Toolbar>
    );
};

interface D {

}

interface TableProps {
    columns: Column[]
    data: D[]
    raw: Attendance[],
    portalUser: any,
    creationProperties: AttendanceCreationProperties
}


export default function BasicTable(props:TableProps) {
    const tableInstance = useTable(
        {
            autoResetHiddenColumns: false,
            columns: props.columns,
            data: props.data,
            defaultColumn
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect

    )

    const {
        getTableProps,
        headerGroups,
        prepareRow,
        page,
        gotoPage,
        setPageSize,
        rows,
        state: { pageIndex, pageSize, selectedRowIds }
        } = tableInstance

    const handleChangePage = (event:any, newPage:number) => {
        gotoPage(newPage)
    }

    useEffect(() => {
        setPageSize(5)
    }, [])
    const handleChangeRowsPerPage = (event:any) => {
        setPageSize(Number(event.target.value))
    }

    const removeByIndexs = (array: typeof Array.prototype, indexs: number[]) =>
        array.filter((_, i) => !indexs.includes(i))

    return (
        <Paper variant="outlined" square>
            <EnhancedTableToolbar raw={props.raw} portalUser={props.portalUser} creationProperties={props.creationProperties}/>
            <TableContainer>
                <Table {...getTableProps()} size="small" >
                    <TableHead>
                        {headerGroups.map(headerGroup => (
                            <TableRow {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <TableCell
                                        {...(column.id === 'selection'
                                            ? column.getHeaderProps()
                                            : column.getHeaderProps(column.getSortByToggleProps()))}
                                    >
                                        <strong>{column.render('Header')}</strong>
                                        {column.id !== 'selection' ? (
                                            <TableSortLabel
                                                active={column.isSorted}
                                                // react-table has a unsorted state which is not treated here
                                                direction={column.isSortedDesc ? 'desc' : 'asc'}
                                            />
                                        ) : null}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody {...getTableProps}>
                        {page.map((row, i) => {
                            prepareRow(row)
                            return (
                                <TableRow {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <TableCell {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            )
                        })}
                            </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[
                                    5
                                ]}
                                colSpan={3}
                                count={props.data.length}
                                rowsPerPage={pageSize}
                                page={pageIndex}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Paper>
    );
}
