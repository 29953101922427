const ListConst = {
    SET_RESULTS_LIST_FILTER_TIMESPAN: "SET_RESULTS_LIST_FILTER_TIMESPAN",
    SET_RESULTS_LIST_FILTER_PROFILE: "SET_RESULTS_LIST_FILTER_PROFILE",
    SET_RESULTS_LIST_FILTER_POSITION: "SET_RESULTS_LIST_FILTER_POSITION",
    SET_RESULTS_LIST_FILTER_FILE: "SET_RESULTS_LIST_FILTER_FILE",
    SET_RESULTS_LIST_FILTER_SORT: "SET_RESULTS_LIST_FILTER_SORT",
    SET_RESULTS_LIST_FILTER_LOCALE: "SET_RESULTS_LIST_FILTER_LOCALE",
    FETCH_RESULTS_LIST_ATTENDANCES: "FETCH_RESULTS_LIST_ATTENDANCES",
    SET_RESULTS_LIST_ROW_SELECTED: "SET_RESULTS_LIST_ROW_SELECTED",
    SET_RESULTS_LIST_ALL_ROWS_SELECTED: "SET_RESULTS_LIST_ALL_ROWS_SELECTED",
    POST_RESULTS_LIST_REQUEST: "POST_RESULTS_LIST_REQUEST",
    SET_RESULTS_LIST_FILTER_TESTVARIANT: "SET_RESULTS_LIST_FILTER_TESTVARIANT",
    START_LIST_DOWNLOAD: "START_LIST_DOWNLOAD",
    START_LIST_DOWNLOAD_ZIP: "START_LIST_DOWNLOAD_ZIP",
    REQUEST_LIST: "REQUEST_LIST",
    REQUEST_LIST_ZIP: "REQUEST_LIST_ZIP",
    FETCH_LIST: "FETCH_LIST",
    POST_RESULTS_LIST_REQUEST_ZIP: "POST_RESULTS_LIST_REQUEST_ZIP",
    SET_RESULTS_LIST_EXPANDED: "SET_RESULTS_LIST_EXPANDED",
    SET_RESULTS_LIST_VALIDATE: "SET_RESULTS_LIST_VALIDATE",
    SET_RESULTS_LIST_FILTER_TIMESPAN_RESET: "SET_RESULTS_LIST_FILTER_TIMESPAN_RESET",
    SET_RESULTS_LIST_FILTER_FORMAT: "SET_RESULTS_LIST_FILTER_FORMAT"


}

export default ListConst;