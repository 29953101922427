import {i18n} from "i18next";
import {LegalContentEn} from "./LegalContentEn";
import {LegalContentDe} from "./LegalContentDe";

interface Props {
    i18next: i18n
}

export function LegalView(props: Props) {
    return (
        <div>
            {
                props.i18next.language.toUpperCase() === "DE" ?
                    <LegalContentDe/>
                    :
                    <LegalContentEn/>
            }
        </div>
    )
}