export const LoadURLByPath = {
  getOpenhr:() => {
      switch(window.location.hostname){
        case "portal-front-eval1.develability.net":
          return "https://openhr-user-eval2.develability.net"
        case "portal-front-eval2-eval2.develability.net":
          return "https://openhr-user-eval1.develability.net"
        case "abciportal.develability.net":
          return "https://anforderungsanalyse.open-hr.io"
        default:
          return "http://localhost:3010"
      }
  }
}