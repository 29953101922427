import axios from "axios";
import {
    Attendance,
    Attendance_Entity,
    AttendanceCreation,
    AttendanceCreationResult,
    Customer, Customer_Flat,
    CustomerTestvariant, Position
} from "../../../../types";
import URLConstants from "../../../../api/URLConstants";

const url = URLConstants.getURL()


interface GetAttendanceCreationResponse {
    result: AttendanceCreationResult
}

interface GetAttendanceAllResponse{
    result: Attendance_Entity[]
}

interface GetAvailableTestvariantsResponse{
    result:CustomerTestvariant[]
}

interface GetAvailablePositions {
    result: Position[]
}

export async function newAttendances(attendanceCreation: AttendanceCreation): Promise<AttendanceCreationResult> {
    const response = await axios.post<GetAttendanceCreationResponse>(url + "/portal/attendance", attendanceCreation);
    return response.data.result;
}

export async function loadAllCreatedAttendances(): Promise<Attendance_Entity[]> {
    const response = await axios.get<GetAttendanceAllResponse>(url + "/portal/attendance/created");
    return response.data.result;
}

export async function loadAvailableTestvariants(customer: Customer_Flat): Promise<CustomerTestvariant[]>{
    const response = await axios.get<GetAvailableTestvariantsResponse>(url + "/portal/testvariant/list/" + customer.id)
    return response.data.result
}

export async function loadAvailablePositions(customer: Customer_Flat): Promise<Position[]>{
    const response = await axios.get<GetAvailablePositions>(url + "/portal/personal/job/" + customer.id + "/response")
    console.log(response)
    return response.data.result
}