import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch, AppThunk} from "../../../../store";
import {
    Attendance, Attendance_Entity
} from "../../../../types";
import {loadAllCreatedAttendances} from "../api/attendanceApi";

const initialState:Attendance_Entity[] = []

const attendanceAllSlice = createSlice({
    name: "attendanceAllSlice",
    initialState,
    reducers: {
        addAttendances(state, action:PayloadAction<Attendance_Entity[]>){
            return action.payload
        }
    }
})

export const fetchCreatedAttendances = ():AppThunk => async (dispatch: AppDispatch) => {
    let attendances:Attendance_Entity[] = await loadAllCreatedAttendances()
    dispatch(attendanceAllSlice.actions.addAttendances(attendances))
}

export default attendanceAllSlice.reducer;