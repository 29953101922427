const ResultsConst = {
  "FETCH_STARTED": "FETCH_STARTED",
  "FETCH_INWORK": "FETCH_INWORK",
  "FETCH_FINISHED": "FETCH_FINISHED",
  "FETCH_FINISHED_ERROR": "FETCH_FINISHED_ERROR",
  FETCH_POSITIONS: "FETCH_POSITIONS",
  FETCH_POSITIONS_AND_FINISHED: "FETCH_POSITIONS_AND_FINISHED",
  "FETCH_POSITIONS_ERROR": "FETCH_POSITIONS_ERROR",
  "SELECT_POSITION_FINISHED": "SELECT_POSITION_FINISHED",
  "SELECT_POSITION_INWORK": "SELECT_POSITION_INWORK",
  SELECT_POSITION_STARTED: "SELECT_POSITION_STARTED",
  "REQUEST_REPORTS": "REQUEST_REPORTS",
  "START_REPORTS_DOWNLOAD": "START_REPORTS_DOWNLOAD",
  "FETCH_REPORTS": "FETCH_REPORTS",
  FINISHED_ALL_ROWS_SELECTED: "FINISHED_ALL_ROWS_SELECTED",
  FINISHED_ROW_SELECTED: "FINISHED_ROW_SELECTED",
  CHOOSE_LANGUAGE: "CHOOSE_LANGUAGE",
  FETCH_PROFILES: "FETCH_PROFILES",
  CHOOSE_PROFILE: "CHOOSE_PROFILE",
  START_RANKING_DOWNLOAD: "START_RANKING_DOWNLOAD",
  REQUEST_RANKING: "REQUEST_RANKING",
  REQUEST_INTERVIEW: "REQUEST_INTERVIEW",
  FETCH_RANKING: "FETCH_RANKING",
  FETCH_INWORK_ERROR: "FETCH_INWORK_ERROR",
  FETCH_STARTED_ERROR: "FETCH_STARTED_ERROR",
  SET_REPORT_TYPE: "SET_REPORT_TYPE",
  SET_INTERVIEW_TYPE: "SET_INTERVIEW_TYPE",
  SET_FILE_FORMAT: "SET_FILE_FORMAT",
  START_REPORT_DOWNLOAD: "START_REPORT_DOWNLOAD",
  FETCH_REPORT: "FETCH_REPORT",
  SET_POSITION: "SET_POSITION",
  GET_RANGES: "GET_RANGES",
  SET_FINISHED_EXPANDED: "SET_FINISHED_EXPANDED",
  FETCH_REPORTS_ZIP: "FETCH_REPORT_ZIP",
  START_REPORT_DOWNLOAD_ZIP: "START_REPORT_DOWNLOAD_ZIP",
  REQUEST_REPORTS_ZIP: "REQUEST_REPORTS_ZIP",
  DROP_FILE: "DROP_FILE",
  SET_FILTER_TIMESPAN: "SET_FILTER_TIMESPAN",
  SET_LIST_FILTER_PROFILE: "SET_LIST_FITER_PROFILE",
  SET_LIST_FILTER_POSITION: "SET_LIST_FILTER_POSITION",
  SET_LIST_FILTER_FILE: "SET_LIST_FILTER_POSITION",
  SET_LIST_FILTER_SORT: "SET_LIST_FILTER_SORT",
  SET_DIALOG_OPEN: "SET_DIALOG_OPEN",
  SET_DIALOG_CLOSE: "SET_DIALOG_CLOSE",
  SET_DIALOG_MISSING_PROPS: "SET_DIALOG_MISSING_PROPS"


};
export default ResultsConst;
