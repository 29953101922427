import React, {useEffect} from "react"
import {
    Button, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl, FormHelperText,
    Grid, InputLabel, Select,
    TextField, Typography
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import { NativeSelect } from "@mui/material";
import {RootState} from "../../../../reducer/rootReducer";
import {changeCreationProperties} from "../slices/creationPropertiesSlice";
import {changeCreationStatus} from "../slices/creationStatusSlice";
import {
    AttendanceCreation,
    AttendanceCreationProperties,
    AttendanceCreationStatus,
    Customer,
    Customer_Flat
} from "../../../../types";
import {createAttendances} from "../slices/attendanceCreationSlice";
import NewAttendanceTable from "./NewAttendanceTable";
import {fetchCreatedAttendances} from "../slices/attendanceAllSlice";
import {FileWrite} from "../../../../utils/FileWrite";
import SaveIcon from '@mui/icons-material/Save';
import {useTranslation} from "react-i18next";
import {allAvailableTestvariants} from "../slices/availableTestvariantsSlice";
import clsx from "clsx";
import {BasicTooltip} from "../../../../components/BasicTooltip";
import {allAvailablePositions} from "../slices/creationPositionsSlice";


export default function Create():JSX.Element{
    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch()
    const {t} = useTranslation(['common'])


    const creationState = useSelector(
        (state:RootState) => state.attendance.creationStatus
    )

    const creationProps = useSelector(
        (state: RootState) => state.attendance.creationProperties
    )

    const selectedPosition = useSelector(
        (state: RootState) => state.attendance.selectedPosition
    )

    const positions = useSelector(
        (state: RootState) => state.global.positions
    )

    const portalUser = useSelector(
        (state: RootState) => state.app.portal?.userInfo
    )

    const attendances = useSelector(
        (state:RootState) => state.attendance.attendances
    )

    const availableTestvariants = useSelector(
        (state:RootState) => state.attendance.availableTestvariants
    )

    const availablePositions = useSelector(
        (state:RootState) => state.attendance.availablePositions
    )

    useEffect(() => {
        if(window.location.pathname === "/attendance/create")
            dispatch(fetchCreatedAttendances())
            dispatch(allAvailableTestvariants(portalUser.customer as Customer_Flat))
            dispatch(allAvailablePositions(portalUser.customer as Customer_Flat))
    },[portalUser])

    const attendancesAll = useSelector(
        (state:RootState) => state.attendance.attendancesAll
    )

    const handleClickOpen = () => {
        let creaStat = {...creationState}
        creaStat.open = true
        dispatch(changeCreationStatus(creaStat))
    };

    const handleClose = () => {
        setOpen(false);
    };

    const variantNames  = [
        {value:"DEFAULT", label: t("testvariants.DEFAULT")},
        {value:"COGNITIVE", label:t("testvariants.COGNITIVE")},
        {value:"AZUBI", label:t("testvariants.AZUBI")},
        {value:"RESEARCH", label:t("testvariants.RESEARCH")},
        {value:"DEFAULT_DT", label:t("testvariants.DEFAULT_DT")}
        ]

    const handleForm = (e:any) => {
        let attProps = {...creationProps}
        if(e.target.name === "position"){
            let pos = positions.find(p => p.entity?.id == e.target.value)
            attProps[e.target.name] = pos
        }else{
            attProps[e.target.name] = e.target.value
        }
        dispatch(changeCreationProperties(attProps))
    }

    const handleDialogProceed = (e:any) => {
        if(creationProps.position && creationProps?.position?.entity?.id){
            let createAttendance: AttendanceCreation = {
                positionId:creationProps.position?.entity?.id,
                testvariant: creationProps.testvariant,
                customerId: portalUser.customer.id,
                count: creationProps.count,
                portalUserId: portalUser.recruiterId
            }
            let creationStatus: AttendanceCreationStatus = {...creationState}
            creationStatus.pending = true
            dispatch(changeCreationStatus(creationStatus))
            dispatch(createAttendances(createAttendance))
        }
    }

    const handleDialogClose = (e:any) => {
        let creationStatus = {...creationState}
        creationStatus.open = false
        creationStatus.finished = false
        creationStatus.pending = false
        creationStatus.success = false
        creationStatus.message = ""
        creationStatus.canceled = false
        dispatch(changeCreationStatus(creationStatus))
    }

    const handleDialogCancel = (e:any) => {
        handleDialogClose(e)
    }

    const handleNew = (e:any) => {
        let attProps:AttendanceCreationProperties = {...creationProps}
        attProps.position = undefined
        attProps.count = 1
        attProps.testvariant = "DEFAULT"
        dispatch(changeCreationProperties(attProps))
    }

    return (
        <Grid container>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                <Grid container>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{marginTop:"18px"}}>
                        <Typography variant="h6" component="h6">
                            {t("createAttendance.form.header")}
                        </Typography>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <FormControl>
                            <TextField
                                id="name"
                                name="count"
                                label={t("createAttendance.form.labels.amount")}
                                type="number"
                                value={creationProps.count}
                                onChange={handleForm}

                            />
                            <FormHelperText>{t("createAttendance.form.helper.amount")}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <FormControl>
                            <InputLabel shrink={!!creationProps.testvariant} htmlFor="testvariant">{t("createAttendance.form.labels.testvariant")}</InputLabel>
                            <NativeSelect
                                value={creationProps.testvariant}
                                onChange={handleForm}
                                id="testvariant"
                                inputProps={{
                                    name: 'testvariant',
                                    id: 'testvariant',
                                }}
                            >
                                {
                                    availableTestvariants.map((tv,i) => {
                                        let name:any = variantNames.find(n => n.value == tv.testvariant)
                                        if(name !== undefined){
                                            return <option value={name.value} key={"sel_tv_" + i}>{name.label}</option>
                                        }
                                    })
                                }
                            </NativeSelect>
                            <FormHelperText>{t("createAttendance.form.helper.testvariant")}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <FormControl>
                            <InputLabel htmlFor="position">{t("createAttendance.form.labels.position")}</InputLabel>
                            <NativeSelect
                                value={creationProps.position && creationProps.position.entity ?
                                    creationProps.position.entity.id : ""}
                                onChange={handleForm}
                                inputProps={{
                                    name: 'position',
                                    id: 'position',
                                }}
                            >
                                <option aria-label="None" value="" />
                                {
                                    availablePositions.length > 0 ? availablePositions.map((pos, idx) => {
                                            if(pos.entity)
                                                return <option key={"att_crea_sel_" + idx} value={pos.entity.id}>
                                                    {pos.entity.name}
                                                </option>
                                        })
                                        :
                                        null
                                }
                            </NativeSelect>
                            <FormHelperText>{t("createAttendance.form.helper.position")}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FormControl>
                            <Button variant="outlined" onClick={handleNew}>
                                {t("labels.new")}
                            </Button>
                        </FormControl>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FormControl >
                            <Button variant="outlined" color="primary" onClick={handleClickOpen} disabled={creationProps.position === undefined}>
                                {t("labels.create")}
                            </Button>
                        </FormControl>
                    </Grid>
                </Grid>
                </Grid>
            <Grid item xl={8} lg={8} md={8} sm={12} xs={12} >
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                    <NewAttendanceTable data={attendances} raw={attendances} portalUser={portalUser} creationProperties={creationProps}/>
                </Grid>
            </Grid>
            <Dialog open={creationState.open} onClose={handleDialogCancel} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{t("createAttendance.dialog.header")}</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <strong>{t("createAttendance.form.labels.amount")}:</strong>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            {creationProps.count}
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <strong>{t("createAttendance.form.labels.testvariant")}: </strong>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            {
                                variantNames.find(v => v.value == creationProps.testvariant)?.label !== undefined ?
                                    <span>{variantNames.find(v => v.value == creationProps.testvariant)?.label}</span>
                                    :
                                    null
                            }
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <strong>{t("createAttendance.form.labels.position")}:</strong>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            {creationProps.position?.entity?.name}
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            {
                                creationState.pending ?
                                    <CircularProgress color="primary"/>
                                    : (
                                        creationState.finished ?
                                            <div>
                                                {creationState.success ?
                                                    <span>{t("createAttendance.dialog.message").replace("[num]", creationProps.count.toString())}</span>
                                                    :
                                                    <span>{t("createAttendance.dialog.error")}</span>
                                                }
                                            </div>
                                            :
                                            null
                                    )
                            }
                        </Grid>
                    </Grid>
                </DialogContent>
                {
                    creationState.pending ?
                        <DialogActions>
                            <Button  size="small" variant={"outlined"} disabled={true} onClick={handleDialogCancel} color="secondary">
                                {t("labels.cancel")}
                            </Button>
                            <Button  size="small" variant={"outlined"} disabled={true} onClick={handleDialogProceed} color="primary">
                                {t("labels.close")}
                            </Button>
                        </DialogActions>
                    :   (
                            creationState.finished ?
                                <DialogActions>
                                    <BasicTooltip title={t("createAttendance.excel.button.tooltip") as string}>
                                        <Button
                                            variant={"outlined"}
                                            onClick={() => {FileWrite.writeExcel({
                                                creationProperties: creationProps, portalUser: portalUser,
                                                raw:attendances
                                            })}}
                                            size="small"
                                            startIcon={<SaveIcon/>}>
                                            Excel
                                        </Button>
                                    </BasicTooltip>
                                    <Button size="small" variant={"outlined"} onClick={handleDialogClose} color="primary">
                                        {t("labels.close")}
                                    </Button>
                                </DialogActions>
                                :
                                <DialogActions>
                                    <Button  size="small" variant={"outlined"} onClick={handleDialogCancel} color="secondary">
                                        {t("labels.cancel")}
                                    </Button>
                                    <Button  size="small" variant={"outlined"} onClick={handleDialogProceed} color="primary">
                                        {t("labels.proceed")}
                                    </Button>
                                </DialogActions>
                        )
                }
            </Dialog>
        </Grid>
    );
}