/**
 * Created by stephanpudras on 02.10.18.
 */

import React from 'react';
import {connect} from 'react-redux';
import ResultsActions from "./actions/ResultsActions";
import ReportType from "../../const/ReportType";
import InterviewType from "../../const/InterviewType";
import ReportState from "../../const/ReportState";
import Select from "react-select";
import Tooltip from 'rc-tooltip';
import HelpPopover from "../../components/help/popover/HelpPopover";
import {withNamespaces, withTranslation} from "react-i18next";
import Dropzone from "react-dropzone";
import Base64Handler from "../../utils/Base64Handler";
import reportState from "../../const/ReportState";
import {useProfileAdminUser} from "../../features/profile/profileAdminUserHook";
import URLConstants from "../../api/URLConstants";


const positions = (positionState) => {
    let posArr = [];
    positionState.map(position => {
        posArr.push({value: position.entity.id, label: position.entity.name})
    });
    return posArr
};

const finished = (finishedState) => {
    let finArr = [];
    finishedState.map(finishedItem => {
        finArr.push(finishedItem.ref)
    });
    return finArr;
};

const mapStateToProps = (state) => {
    return {
        finished: finished(state.results.finished),
        positions: positions(state.results.positions.positions),
        selectedPosition: state.results.positions,
        tableSelection: state.results.tableSelection,
        language: state.results.language,
        profiles: state.results.profiles,
        reportType: state.results.reportType,
        interviewType: state.results.interviewType,
        downloadReport: state.results.downloadReport,
        reportProperties: state.results.resultProperties,
        positionsRaw: state.results.positions.positions,
        finishedExpanded: state.results.finishedExpanded,
        percentRanges: state.results.percentRanges,
        customer: state.app.portal.userInfo.customer.id,
        i18next: state.locale.i18next,
        portal: state.app.portal,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchPositions: () => dispatch(ResultsActions.fetchPositions()),
        fetchProfiles: () => dispatch(ResultsActions.fetchProfiles()),
        setLocale: (value, reportState) => dispatch(ResultsActions.setLocale(value, reportState)),
        setProfile: (value, reportState) => dispatch(ResultsActions.setProfile(value, reportState)),
        setPosition: (value, reportState) => dispatch(ResultsActions.setPostion(value, reportState)),
        setReportType: (value, reportState) => dispatch(ResultsActions.setReportType(value, reportState)),
        setInterviewType: (value, reportState) => dispatch(ResultsActions.setInterviewType(value, reportState)),
        setFormat: (value, reportState) => dispatch(ResultsActions.setFormat(value, reportState)),
        startReportDownloadZip: (interval) => dispatch(ResultsActions.startReportDownloadZip(interval)),
        pollReportZip:() => dispatch(ResultsActions.pollReportZip()),
        getReportZip: (cb, reportState, byRef) => dispatch(ResultsActions.downloadReportZip(cb, reportState, byRef)),
        startReportDownload: (interval) => dispatch(ResultsActions.startReportDownload(interval)),
        pollReport: () => dispatch(ResultsActions.pollReport()),
        getReport: (cb, reportState, byRef) => dispatch(ResultsActions.downloadReport(cb, reportState, byRef)),
        dropMappingFile: (file) => dispatch(ResultsActions.dropMappingFile(file)),
        openDialog: (state) => dispatch(ResultsActions.openDialog(state)),
        setMissingProps: (state) => dispatch(ResultsActions.setMissingProps(state))

    }
};

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};
const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};

class ReportForm extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.getPosition = this.getPosition.bind(this);
        this.getLocale = this.getLocale.bind(this);
        this.getReportType = this.getReportType.bind(this);
        this.getInterviewType = this.getInterviewType.bind(this);
        this.getProfile = this.getProfile.bind(this);
        this.getFormat = this.getFormat.bind(this);
        this.getFilteredProfiles = this.getFilteredProfiles.bind(this);
        this.selectPosition = this.selectPosition.bind(this);
        this.getReport = this.getReport.bind(this);
        this.getReportZip = this.getReportZip.bind(this);
        this.pollReportStatus = this.pollReportStatus.bind(this);
        this.pollReportStatusZip = this.pollReportStatusZip.bind(this);
        this.checkProperties = this.checkProperties.bind(this);
        this.Base64Handler = new Base64Handler();
        this.state = {divClass:"", files:[]}
    }

    checkProperties(){
        let missing = []
        if(this.props.reportProperties.finished.reportType !== null && this.props.reportProperties.finished.reportType !== undefined){

            if(this.props.reportProperties.finished.reportType.value === ReportType.REPORT){
                if(this.props.reportProperties.finished.locale === null || this.props.reportProperties.finished.locale === undefined){
                    missing.push("LOCALE")
                }
            }
            if(this.props.reportProperties.finished.reportType.value === ReportType.RANKING){
                if(this.props.reportProperties.finished.locale === null || this.props.reportProperties.finished.locale === undefined){
                    missing.push("LOCALE")
                }
                if(this.props.reportProperties.finished.profileId === null || this.props.reportProperties.finished.profileId === undefined){
                    missing.push("PROFILE")
                }
                if(this.props.reportProperties.finished.format === null || this.props.reportProperties.finished.format === undefined){
                    missing.push("FORMAT")
                }
            }
            if(this.props.reportProperties.finished.reportType.value === ReportType.INTERVIEW){
                if(this.props.reportProperties.finished.locale === null || this.props.reportProperties.finished.locale === undefined){
                    missing.push("LOCALE")
                }
                if(this.props.reportProperties.finished.profileId === null || this.props.reportProperties.finished.profileId === undefined){
                    missing.push("PROFILE")
                }
                if(this.props.reportProperties.finished.reportType.value === ReportType.INTERVIEW){
                    if(this.props.reportProperties.finished.interviewType === null || this.props.reportProperties.finished.interviewType === undefined){
                        missing.push("INTERVIEW_TYPE")
                    }
                }
            }
            if(this.props.tableSelection.selection.length < 1){
                missing.push("SELECTION")
            }
        }else{
            if(this.props.reportProperties.finished.reportType === null || this.props.reportProperties.finished.reportType === undefined){
                missing.push("REPORT_TYPE")
            }
            if(this.props.tableSelection.selection.length < 1){
                missing.push("SELECTION")
            }
            if(this.props.reportProperties.finished.locale === null || this.props.reportProperties.finished.locale === undefined){
                missing.push("LOCALE")
            }
        }
        if(missing.length > 0){
            this.props.setMissingProps(missing)
            return false
        }else{
            return true
        }
    }
    getReport(){
        if(this.checkProperties()){
            this.props.getReport(this.pollReportStatus, ReportState.FINISHED, this.state.byRef);
        }else{
            this.props.openDialog(true)
        }
    }
    getReportZip(){
        if(this.checkProperties()){
            this.props.getReportZip(this.pollReportStatusZip, ReportState.FINISHED, this.state.byRef);
        }else{
            this.props.openDialog(true)
        }
    }

    pollReportStatus(){
        this.props.startReportDownload(setInterval(this.props.pollReport, 1500));
    }

    pollReportStatusZip(){
        this.props.startReportDownloadZip(setInterval(this.props.pollReportZip, 1500));
    }

    selectPosition(value, reportState){
        this.props.fn();
        this.props.setPosition(value,reportState)
    }

    onDrop(files) {
        this.Base64Handler.handleFile(files, this.props.dropMappingFile);
        this.setState({
            files:files
        });
    }

    getFilteredProfiles(){
        let oProfiles = [];
        this.props.profiles.profiles.map(profile => {
            if(profile.entity) {
                oProfiles.push({value: profile.entity.extId,name:profile.entity.name, label: <div className={"profile-select"}><span className={"abci-logo-20"}></span>&nbsp;&nbsp;{profile.entity.name}</div>})
            }else{
                oProfiles.push({value: profile.ref.id,name:profile.ref.name, label: <div className={"profile-select"}><span className={"openhr-logo-20"}></span>&nbsp;&nbsp;{profile.ref.name}</div>})
            }
        });
        return oProfiles.sort((a,b) => {
            if(a.name.toUpperCase() < b.name.toUpperCase()){
                return -1
            }
            if(a.name.toUpperCase() > b.name.toUpperCase() ){
                return 1
            }
            return 0;
        });
    }

    getPosition(){
        switch(this.props.reportState){
            case ReportState.FINISHED:
                return this.props.reportProperties.finished.positionId;
            case ReportState.INWORK:
                return this.props.reportProperties.inwork.positionId;
            case ReportState.STARTED:
                return this.props.reportProperties.started.positionId;
        }
    }

    getLocale(){
        switch(this.props.reportState){
            case ReportState.FINISHED:
                return this.props.reportProperties.finished.locale;
            case ReportState.INWORK:
                return this.props.reportProperties.inwork.locale;
            case ReportState.STARTED:
                return this.props.reportProperties.started.locale;
        }
    }

    getReportType(){
        switch(this.props.reportState){
            case ReportState.FINISHED:
                return this.props.reportProperties.finished.reportType != null ? this.props.reportProperties.finished.reportType : null;
            case ReportState.INWORK:
                return this.props.reportProperties.inwork.reportType;
            case ReportState.STARTED:
                return this.props.reportProperties.started.reportType;
        }
    }

    getInterviewType(){
        switch(this.props.reportState){
            case ReportState.FINISHED:
                return this.props.reportProperties.finished.interviewType;
            case ReportState.INWORK:
                return this.props.reportProperties.inwork.interviewType;
            case ReportState.STARTED:
                return this.props.reportProperties.started.interviewType;
        }
    }

    getProfile(){
        switch(this.props.reportState){
            case ReportState.FINISHED:
                return this.props.reportProperties.finished.profileId;
            case ReportState.INWORK:
                return this.props.reportProperties.inwork.profileId;
            case ReportState.STARTED:
                return this.props.reportProperties.started.profileId;
        }
    }

    getFormat(){
        switch(this.props.reportState){
            case ReportState.FINISHED:
                return this.props.reportProperties.finished.format;
            case ReportState.INWORK:
                return this.props.reportProperties.inwork.format;
            case ReportState.STARTED:
                return this.props.reportProperties.started.format;
        }

    }

    formatGroupLabel(data) {
        return <div style={groupStyles}>
                 <div>
                     <span><img src={data.label === "abcÎ" ? `${URLConstants.getRedirectURI()}/img/abcilogo.svg`:
                         `${URLConstants.getRedirectURI()}/img/openhr_logo.svg`} width={60}/></span>
                     &nbsp;
                     &nbsp;
                     <span><strong>{data.label}</strong></span>
                 </div>
                 <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
    }

    componentDidMount(){
        this.props.fetchPositions();
        this.props.fetchProfiles();
        //this.setState({
        //    divClass: "row form-group animate__animated animate__flipInX result_form_axis"
        //})
        //setTimeout(() => {this.setState({
        //    divClass:"row form-group"
        //})}, 1000)

    }

    render() {
        return (
           <div className="row form-group">
               <div className="col-9">
                   <form>
                       <div className="row form-group">
                           <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12">
                               <label htmlFor="colFormLabelSm" className="col-form-label col-form-label-sm">{this.props.t("common:labels.positions")}</label>
                           </div>
                           <div className="col-sm-10">
                               <div className="d-flex">
                                   <div className="flex-grow-1">
                                       <Select
                                           placeholder={this.props.t("common:placeholder.results.position")}
                                           value={this.getPosition()}
                                           onChange={(e) => this.selectPosition(e != null ? e : null, this.props.reportState)}
                                           options={this.props.positions}
                                       />
                                   </div>
                                   <div className="flex-grow-0 popover-help-wrapper">
                                       <Tooltip placement={"bottom"} overlay={<HelpPopover module="results" element="position"/>} trigger={"click"}><span className="icon-help-with-circle"></span></Tooltip>
                                   </div>
                               </div>
                           </div>
                       </div>
                       <div className="row form-group">
                           <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12">
                               <label htmlFor="colFormLabelSm" className="col-form-label col-form-label-sm">{this.props.t("common:labels.report")}</label>
                           </div>
                           <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-12">
                               <div className="d-flex">
                                   <div className="flex-grow-1">
                                       <Select
                                           placeholder={this.props.t("common:placeholder.results.report")}
                                           value={this.getReportType()}
                                           onChange={(e) => this.props.setReportType(e != null ? e : null, this.props.reportState)}
                                           options={[{value:ReportType.REPORT, label:this.props.t("common:reportType.select.pprofile")}, {value:ReportType.RANKING, label:this.props.t("common:reportType.select.ranking")}, {value:ReportType.INTERVIEW, label:this.props.t("common:reportType.select.interviewguides")}]}
                                           searchable={false}
                                       />
                                   </div>
                                   <div className="flex-grow-0 popover-help-wrapper">
                                       <Tooltip placement={"bottom"} overlay={<HelpPopover module="results" element="report"/>} trigger={"click"}><span className="icon-help-with-circle"></span></Tooltip>
                                   </div>
                               </div>
                           </div>
                       </div>
                       {
                           this.getReportType() !== null && this.getReportType().value === ReportType.INTERVIEW ?
                               <div className="row form-group">
                                   <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12">
                                       <label htmlFor="colFormLabelSm" className="col-form-label col-form-label-sm">{this.props.t("common:labels.interviewoptions")}</label>
                                   </div>
                                   <div className="col-sm-10 col-xs-12">
                                       <div className="d-flex">
                                           <div className="flex-grow-1">
                                               <Select
                                                   placeholder={this.props.t("common:placeholder.results.interviewtype")}
                                                   value={this.getInterviewType()}
                                                   onChange={(e) => this.props.setInterviewType(e != null ? e : null, this.props.reportState)}
                                                   options={[{value:InterviewType.DEFAULT, label:this.props.t("common:interviewoptions.standard")}, {value:InterviewType.SIMPEL, label:this.props.t("common:interviewoptions.short")}]}
                                                   disabled={this.getReportType() !== ReportType.INTERVIEW}
                                                   searchable={false}
                                               />
                                           </div>
                                           <div className="flex-grow-0 popover-help-wrapper">
                                               <Tooltip placement={"bottom"} overlay={<HelpPopover module="results" element="interview"/>} trigger={"click"}><span className="icon-help-with-circle"></span></Tooltip>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                               :
                               null
                       }
                       <div className="row form-group">
                           <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12">
                               <label htmlFor="colFormLabelSm" className="col-form-label col-form-label-sm">{this.props.t("common:labels.locale")}</label>
                           </div>
                           <div className="col-sm-10 col-xs-12">
                               <div className="d-flex">
                                   <div className="flex-grow-1">
                                       <Select
                                           value={this.getLocale()}
                                           onChange={(e) => this.props.setLocale(e != null ? e : null, this.props.reportState)}
                                           placeholder={this.props.t("common:placeholder.results.locale")}
                                           options={[{value:"DE", label:this.props.t("common:locale.long.de")}, {value:"EN", label:this.props.t("common:locale.long.en")}]}
                                           searchable={false}
                                       />
                                   </div>
                                   <div className="flex-grow-0 popover-help-wrapper">
                                       <Tooltip placement={"bottom"} overlay={<HelpPopover module="results" element="locale"/>} trigger={"click"}><span className="icon-help-with-circle"></span></Tooltip>
                                   </div>
                               </div>
                           </div>
                       </div>


                       {
                           this.getReportType() !== null && this.getReportType().value === ReportType.INTERVIEW || this.getReportType() !== null && this.getReportType().value === ReportType.RANKING ?
                               <div className="row form-group">
                                   <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12">
                                       <label htmlFor="colFormLabelSm" className="col-form-label col-form-label-sm">{this.props.t("common:labels.profile")}</label>
                                   </div>
                                   <div className="col-sm-10 col-xs-12">
                                       <div className="d-flex">
                                           <div className="flex-grow-1">
                                               <Select
                                                   placeholder={this.props.t("common:placeholder.results.profile") + "."}
                                                   value={this.getProfile()}
                                                   onChange={(e) => this.props.setProfile(e != null ? e : null, this.props.reportState)}
                                                   options={this.getFilteredProfiles()}
                                               />
                                           </div>
                                           <div className="flex-grow-0 popover-help-wrapper">
                                               <Tooltip placement={"bottom"} overlay={<HelpPopover module="results" element="profile"/>} trigger={"click"}><span className="icon-help-with-circle"></span></Tooltip>
                                           </div>
                                       </div>

                                   </div>
                               </div>
                               :
                               null
                       }
                       {
                           this.getReportType() !== null && this.getReportType().value === ReportType.RANKING ?
                               <div className="row form-group">
                                   <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12">
                                       <label htmlFor="colFormLabelSm" className="col-form-label col-form-label-sm">{this.props.t("common:labels.format")}</label>
                                   </div>
                                   <div className="col-sm-10 col-xs-12">
                                       <div className="d-flex">
                                           <div className="flex-grow-1">
                                               <Select
                                                   value={this.getFormat()}
                                                   onChange={(e) => this.props.setFormat(e != null ? e : null, this.props.reportState)}
                                                   placeholder={this.props.t("common:placeholder.results.format")}
                                                   options={[{value:"XLSX", label:this.props.t("common:labels.excel")}, {value:"PDF", label:"PDF"}]}
                                                   searchable={false}
                                               />
                                           </div>
                                           <div className="flex-grow-0 popover-help-wrapper">
                                               <Tooltip placement={"bottom"} overlay={<HelpPopover module="results" element="format"/>} trigger={"click"}><span className="icon-help-with-circle"></span></Tooltip>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                               :
                               null
                       }
                   </form>
               </div>
               <div className="col-3">
                   <div className="form-group">
                       {
                           this.props.downloadReport.ready && !this.props.downloadReport.zip ?
                               <button className="btn btn-success btn-block" onClick={() => this.Base64Handler.saveReports(this.props.downloadReport.files)} title={this.props.t("tooltip.finished.download")}>{this.props.t("button.save")}<span className="icon-download"> </span></button>
                               :
                               (
                                   this.props.downloadReport.pending && !this.props.downloadReport.zip ?
                                       <button className="btn btn-warning btn-block" title={this.props.t("tooltip.finished.download")}>{this.props.t("button.generating") + "..."}</button>
                                       :
                                       <button className="btn btn-primary btn-block" onClick={() => this.getReport()} title={this.props.t("tooltip.finished.download")}>{this.props.t("button.download")}</button>
                               )
                       }
                   </div>
                   <div className="form-group">
                       {
                           this.props.downloadReport.ready && this.props.downloadReport.zip ?
                               <button className="btn btn-success btn-block" onClick={() => this.Base64Handler.saveReports(this.props.downloadReport.files)} title={this.props.t("tooltip.finished.downloadZip")}>{this.props.t("button.saveZip")}<span className="icon-download"> </span></button>
                               :
                               (
                                   this.props.downloadReport.pending && this.props.downloadReport.zip ?
                                       <button className="btn btn-warning btn-block" title={this.props.t("tooltip.finished.download")}>{this.props.t("button.generating") + "..."}</button>
                                       :
                                       <button className="btn btn-info btn-block" onClick={() => this.getReportZip()} title={this.props.t("tooltip.finished.downloadZip")}>{this.props.t("button.downloadZip")}</button>
                               )
                       }
                   </div>
                   <section>
                       {
                           this.props.portal.userInfo.customer && this.props.portal.userInfo.customer.nameMapping ?
                               <div>
                                   <div className="dropzone-small">
                                       <Dropzone className="dropzone-small" onDrop={this.onDrop.bind(this)} multiple={false}>
                                           <p>{this.props.t("filedropper.results.innerText")}</p>
                                       </Dropzone>
                                   </div>
                                   <aside>
                                       <strong>{this.props.t("filedropper.results.chosen")}</strong>
                                       <ul>
                                           {
                                               typeof this.state.files !== "undefined" ? this.state.files.map(f => <li key={f.name}>{f.name} - {f.size} bytes</li>) : null
                                           }
                                       </ul>
                                   </aside>
                               </div>
                               :
                               <div>
                                   <div className="dropzone-small-deactivated">
                                       <Dropzone disabled={true} className="dropzone-small" onDrop={this.onDrop.bind(this)} multiple={false}>
                                           <p>{this.props.t("filedropper.results.innerTextDeactivated")}</p>
                                       </Dropzone>
                                   </div>
                                   <aside>
                                       <strong>{this.props.t("filedropper.results.chosen")}</strong>
                                       <ul>
                                           {
                                               typeof this.state.files !== "undefined" ? this.state.files.map(f => <li key={f.name}>{f.name} - {f.size} bytes</li>) : null
                                           }
                                       </ul>
                                   </aside>
                               </div>
                       }
                   </section>
               </div>
           </div>
        )
    }
}
const reportFormTrans = withTranslation(["common", "help_popover"])(ReportForm);
export default connect(mapStateToProps, mapDispatchToProps)(reportFormTrans)