import {Attendance} from "../../types";
import ExcelJS, {Workbook} from "exceljs"
import * as fs from "fs";
import URLConstants from "../../api/URLConstants";

const toDataURL = (url: string) => fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
    }))

const createEmptyWorkbook = async (portalUser: any, pos: string | undefined): Promise<Workbook> => {
    let baseurl:string = URLConstants.getJson()
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('candidates', {
        pageSetup: {paperSize: 9, orientation: "landscape", showGridLines: false, showRowColHeaders: false},
        views: [{showGridLines: false, showRowColHeaders: false, activeCell: 'B11'}]
    })
    //worksheet.mergeCells('A1:B1:C1:D1')
    let aaa_url = await toDataURL(baseurl + "/img/aaa.png")
    const aaa = workbook.addImage({
        base64: aaa_url as string,
        extension: 'png'
    })
    worksheet.getRow(1).height = 30
    worksheet.columns = [
        {width: 31.5},
        {width: 31.5},
        {width: 31.5},
        {width: 31.5}
    ]

    //add "ackerschott & associates" claim image
    worksheet.addImage(aaa, 'A1:D1')

    //add abci logo image
    let abci_img = await toDataURL(baseurl + "/img/abci_klein.png")
    const abci = workbook.addImage(
        {
            base64: abci_img as string,
            extension: 'png'
        }
    )
    worksheet.mergeCells("C6:C9")

    // @ts-ignore
    worksheet.addImage(abci, 'C6:C9')


    //add basic header
    const consultant = worksheet.mergeCells('A2:B2')
    const company = worksheet.mergeCells('A3:B3')
    const position = worksheet.mergeCells('A4:B4')
    const confidential = worksheet.mergeCells('A6:B6')
    const url = worksheet.mergeCells('A8:B8')

    let customerName = ""
    if (portalUser.customer) {
        customerName = portalUser.customer.name
    }

    worksheet.getCell('B2').value = {
        richText: [
            {font: {size: 12, name: 'Calibri', family: 2, scheme: 'minor', bold: true}, text: 'consultant:'},
            {
                'font': {'size': 12, 'name': 'Calibri', 'family': 2, 'scheme': 'minor', 'bold': false},
                'text': ' ackerschott & associates'
            }
        ]
    }
    worksheet.getCell('B3').value = {
        richText: [
            {font: {size: 12, name: 'Calibri', family: 2, scheme: 'minor', bold: true}, text: 'company:'},
            {
                'font': {'size': 12, 'name': 'Calibri', 'family': 2, 'scheme': 'minor', 'bold': false},
                'text': "   " + customerName
            }
        ]
    }
    worksheet.getCell('B4').value = {
        richText: [
            {font: {size: 12, name: 'Calibri', family: 2, scheme: 'minor', bold: true}, text: 'position:'},
            {
                'font': {'size': 12, 'name': 'Calibri', 'family': 2, 'scheme': 'minor', 'bold': false},
                'text': '     ' + (pos != undefined ? pos : "")
            }
        ]
    }

    worksheet.getCell('B8').value = {
        richText: [
            {font: {size: 12, name: 'Calibri', family: 2, scheme: 'minor', bold: true}, text: 'URL:'},
            {
                'font': {'size': 12, 'name': 'Calibri', 'family': 2, 'scheme': 'minor', 'bold': false},
                text: '           http://login.abci.de'
            }
        ]
    }

    worksheet.getCell('B6').value = "confidential - to be used only by recipient"

    worksheet.getCell('D6').value = {
        richText: [
            {
                font: {size: 12, name: 'Calibri', family: 2, scheme: 'minor', color: {argb: '0079C2'}},
                text: 'Harald Ackerschott GmbH'
            }
        ]
    }
    worksheet.getCell('D7').value = {
        richText: [
            {
                font: {size: 12, name: 'Calibri', family: 2, scheme: 'minor', color: {argb: '0079C2'}},
                text: 'team@abci.de'
            }
        ]
    }
    worksheet.getCell('D8').value = {
        richText: [
            {
                font: {size: 12, name: 'Calibri', family: 2, scheme: 'minor', color: {argb: '0079C2'}},
                text: '+49 228 969700'
            }
        ]
    }

    let cellNames = ["A", "B", "C", "D"]

    cellNames.forEach(c => {
        for (let i = 2; i < 10; i++) {
            worksheet.getCell(c + i).fill = {
                type: "pattern",
                pattern: "solid",
                bgColor: {argb: "e4f3f7"},
                fgColor: {argb: "e4f3f7"}
            }
        }
    })

    worksheet.getRow(10).height = 37

    cellNames.forEach(c => {
        worksheet.getCell(c + "10").fill = {
            type: "pattern",
            pattern: "solid",
            bgColor: {argb: "e4f3f7"},
            fgColor: {argb: "0079c2"}
        }
    })

    worksheet.getRow(10).font = {name: 'Calibri', family: 2, size: 16, bold: true, color: {argb: "FFFFFF"}}

    cellNames.forEach(c => {
        worksheet.getCell(c + '10').alignment = {
            vertical: "middle", horizontal: "center"
        }
    })

    worksheet.getCell('A10').value = "user ID"
    worksheet.getCell('B10').value = "name"
    worksheet.getCell('C10').value = "position"
    worksheet.getCell('D10').value = "notes"

    return workbook
}

interface RowData {
    rowNumber: number
    identification: number | string
}

const addLogincodeRow = (workbook: Workbook, rowData: RowData): Workbook => {
    const worksheet = workbook.getWorksheet("candidates")
    const row = worksheet.getRow(rowData.rowNumber)
    const cell = row.getCell(1)
    row.height = 28
    cell.style = {
        fill:{
            type: "pattern",
            pattern: "solid",
            bgColor: {argb: "e4f3f7"},
            fgColor: {argb: "0079c2"}
        },
        font:{name: 'Calibri', family: 2, size: 16, bold: true, color: {argb: "FFFFFF"}},
        alignment:{vertical: "middle", horizontal: "center"},
        protection: {
            locked:true
        }
    }
    cell.value = "" + rowData.identification
    row.getCell(2).style ={
        border:{
            top: {style:'hair'},
            left: {style:'hair'},
            bottom: {style:'hair'},
            right: {style:'hair'}
        }
    }
    row.getCell(3).border = {
        top: {style:'hair'},
        left: {style:'hair'},
        bottom: {style:'hair'},
        right: {style:'hair'}
    }
    row.getCell(4).border = {
        top: {style:'hair'},
        left: {style:'hair'},
        bottom: {style:'hair'},
        right: {style:'hair'}
    }
    return workbook
}

export const exportToExcel = async (raw: Attendance[], portalUser: any, position: string | undefined): Promise<any> => {
    const workbook = await createEmptyWorkbook(portalUser, position)
    const worksheet = workbook.getWorksheet("candidates")
    raw.map((entity, index) => {
        let idx = 1 + index
        addLogincodeRow(workbook, {rowNumber: idx + 10, identification: entity.entity.identification})
    })
    return await workbook.xlsx.writeBuffer()
}


