import {combineReducers} from "redux";
import AppConst from "../AppConst";

const i18next = (state=null, action=null) => {
    switch (action.type) {
        case AppConst.LOAD_LOCALE:
            return Object.assign({}, state, action.payload);
        default:
            return state;
    }
};


const locale = combineReducers({i18next});
export {locale}