import {setAuthToken, setCustomerId, setToken} from "./axiosConfig";
import {AnyAction, Dispatch, Middleware, MiddlewareAPI} from "redux";
import AppConst from "../AppConst";
import {fetchPositions} from "../bootstrap/slices/positionSlice";
import {useDispatch} from "react-redux";

const axiosMiddleware:Middleware = (api:MiddlewareAPI) => (next:Dispatch<AnyAction>) => (action) => {
    if(action.type === AppConst.LOGIN) {
        setToken(action.payload.token);
        setAuthToken(action.payload.token)
    }

    if(action.type === AppConst.GET_PORTALUSERINFO){
        if(action.payload.customer)
        setCustomerId(action.payload.customer.id)
    }
    // continue processing this action
    return next(action);
}

export default axiosMiddleware;