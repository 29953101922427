import {create} from "zustand";
import {devtools} from "zustand/middleware";
import {
  GroupUser,
  Profile,
  ProfileTableData,
  RProfileUser, SharedProfile,
} from "../../../types";
import axios from "axios";
import URLConstants from "../../../api/URLConstants";
import {ProfileTableDataNormalizer} from "../../../utils/ProfileTableDataNormalizer";

interface ProfileAdminState {
  userId: string
  profiles: Profile[]
  sharedProfiles: RProfileUser[]
  tableData: ProfileTableData[]
  groupUsers: GroupUser[]
  initial: boolean
  loading: boolean
  fetch: (customerId: number) => void
  setUserId: (userId: string) => void
  replaceSharedProfile: (profile: RProfileUser) => void
  deleteSharedProfile: (is: number) => void
  replaceShares: (shares: SharedProfile[], profileId: number) => void
}

const useProfileAdmin = create<ProfileAdminState>()(devtools((set,get) => ({
  userId:"",
  profiles:[],
  sharedProfiles:[],
  tableData: [],
  groupUsers: [],
  initial: false,
  loading: false,
  fetch: (customerId: number) => {
    if(!get().loading && !get().initial){
      axios.get<Profile[]>(`${URLConstants.getURL()}/portal/profile/${customerId}/list`)
        .then(profileResponse => {
          set({profiles: profileResponse.data})
          axios.get<RProfileUser[]>(`${URLConstants.getURL()}/portal/profile/user`)
            .then(sharedResponse => {
              set({sharedProfiles:sharedResponse.data, tableData:ProfileTableDataNormalizer.normalize(profileResponse.data, sharedResponse.data)})
              axios.get<GroupUser[]>(`${URLConstants.getURL()}/portal/group/${get().userId}`)
                .then(groupResponse =>
                  set({loading: false, initial:true, groupUsers: [...groupResponse.data.filter(it => it.userId !== get().userId)]
                  }))
            })
        })
    }
  },
  setUserId:(userId: string) => {
    set({userId: userId})
  },
  replaceSharedProfile: (profile:RProfileUser) => {
    let idx = get().sharedProfiles.findIndex(it => it.profile.id === profile.profile.id)
    let tIdx = get().tableData.findIndex(it => it.id === profile.profile.id)
    set({
      sharedProfiles:[
        ...get().sharedProfiles.slice(0,idx),
        profile,
        ...get().sharedProfiles.slice(idx+1)
      ],
      tableData:[
        ...get().tableData.slice(0,tIdx),
        {...get().tableData[tIdx], name:profile.profile.name, description:profile.profile.description, dateUpdated:profile.profile.dateUpdated, profile:profile.profile},
        ...get().tableData.slice(tIdx+1)
      ]
    })
  },
  deleteSharedProfile: (id: number) => {
    let idx = get().sharedProfiles.findIndex(it => it.id === id)
    let tIdx = get().tableData.findIndex(it => it.id === id)
    set({
      sharedProfiles:[
        ...get().sharedProfiles.slice(0,idx),
        ...get().sharedProfiles.slice(idx+1)
      ],
      tableData:[
        ...get().tableData.slice(0,tIdx),
        ...get().tableData.slice(tIdx + 1)
      ]
    })
  },
  replaceShares: (shares,profileId) => {
    let idx = get().sharedProfiles.findIndex(it => it.id === profileId)
    let tIdx = get().tableData.findIndex(it => it.id === profileId)
    set({
      sharedProfiles:[
        ...get().sharedProfiles.slice(0,idx),
        {...get().sharedProfiles[idx], shares:shares},
        ...get().sharedProfiles.slice(idx+1)
      ],
      tableData:[
        ...get().tableData.slice(0,tIdx),
        {...get().tableData[tIdx], shares:shares},
        ...get().tableData.slice(tIdx + 1)
      ]
    })
  }
})))

export {useProfileAdmin}