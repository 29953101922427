import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from "react-table-6/react-table";
import "react-table-6/react-table.css";
import Select from "react-select";
import ResultsActions from './actions/ResultsActions';
import {format, getTime, parseISO} from "date-fns";
import {de} from "date-fns/locale";
import {Link} from "react-router-dom";
import {enGB as en} from "date-fns/locale";
import {withNamespaces, withTranslation} from "react-i18next";


const getLocalizedDateShort = (date, locale) => {
    switch(locale){
        case "de":
            return format(parseISO(date), "dd.MM.yyyy HH:mm:ss", {locale: de});
        case "en":
            return format(parseISO(date), "MM/dd/yyyy hh:mm:ss", {locale: en});
        default:
            return format(parseISO(date), "MM/dd/yyyy hh:mm:ss", {locale: en});
    }
};

 class Started extends Component {
     constructor(props) {
         super(props);
     }

     tableHeaders = () => {
         return [
             {
                 Header: this.props.t("tHeadLabels.logincode"),
                 accessor: "identification"
             },
             {
                 Header: this.props.t("tHeadLabels.started"),
                 accessor: "started",
                 sortMethod: (a, b) => {
                     let oA = getTime(parseISO(a));
                     let oB = getTime(parseISO(b));
                     if(oA < oB)
                         return 1;
                     else
                         return -1;
                 },
                 Cell: row => {
                     return getLocalizedDateShort(row.original.started, this.props.i18n.language)
                 }
             },
             {
                 Header: this.props.t("tHeadLabels.block"),
                 accessor: "block"
             },
             {
                 Header: this.props.t("tHeadLabels.test"),
                 accessor: "testname"
             }
         ];
     };


     componentDidMount(){
         console.log(this.props.selectedPosition)
    this.props.fetchPositions();
  }

  render() {
    const columns = this.tableHeaders();
    return (
      <div>
          <div className="row">
            <div className="col-6 form-group">
              <Select
                placeholder={this.props.t("placeholder.results.position")}
                value={this.props.selectedPosition}
                onChange={(e) => this.props.selectPositionStarted(e)}
                options={this.props.positions}
              />
            </div>
            <div className="col-6">
                <div className="float-right"><Link to="/home">{this.props.t("labels.backtohome")}</Link></div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <ReactTable
                data={this.props.started}
                columns={columns}
                defaultPageSize={10}
                keyField='identification'
                className="-striped -highlight"
                previousText={this.props.t("tableControls.backward")}
                nextText={this.props.t("tableControls.forward")}
                loadingText={this.props.t("tableControls.loading")}
                noDataText={this.props.t("tableControls.nodata")}
                pageText={this.props.t("tableControls.page")}
                ofText={this.props.t("tableControls.of")}
                rowsText={this.props.t("tableControls.rows")}
                filterable
                defaultSorted={[
                    {
                        id: "started",
                        desc: false
                    }
                ]}
              />
            </div>
          </div>
      </div>
    )
  }
}
const positions = (positionState) => {
    let posArr = [];
    positionState.map(position => {
      posArr.push({value: position.entity.id, label: position.ref.name})
    });
    return posArr
};

const started = (startedState) => {
  let staArr = [];
  startedState.map(startedItem => {
    staArr.push(startedItem.ref)
  });
  return staArr;
};

const mapStateToProps = (state) => ({
  started: started(state.results.started),
  positions: positions(state.results.positions.positions),
  selectedPosition: state.results.positions.selectedStarted
});

const mapDispatchToProps = (dispatch) => ({
  fetchStarted: (jobId, customerId) => dispatch(ResultsActions.fetchStarted()),
  fetchPositions: () => dispatch(ResultsActions.fetchPositions()),
  selectPositionStarted:(e) => dispatch(ResultsActions.selectPositionStarted(e))
});

const wStartedTrans = withTranslation(["common"])(Started);
export default connect(mapStateToProps, mapDispatchToProps)(wStartedTrans);
