import React, {Component} from 'react';
import {connect} from 'react-redux';
import AdminNameMappingActions from "../actions/AdminNameMappingActions";
import ReactTable from "react-table-6/react-table";

const mapStateToProps = (state) => ({
    customer: state.admin.adminNameMapping.customer.data
});

const mapDispatchToProps = (dispatch) => ({
    fetchCustomer: () => dispatch(AdminNameMappingActions.fetchCustomers()),
    addNameMapping: (id) => dispatch(AdminNameMappingActions.addMapping(id)),
    removeNameMapping: (id) => dispatch(AdminNameMappingActions.removeMapping(id))
});

/*
 * Created by stephanpudras on 25.03.2019
 */

class AdminNameMapping extends Component {
    constructor(props) {
        super(props);
        this.toggleMapping = this.toggleMapping.bind(this);
    }

    toggleMapping(customerId, nm){
        if(nm === false)
            this.props.addNameMapping(customerId);
        else
            this.props.removeNameMapping(customerId);
    }

    componentDidMount() {
        this.props.fetchCustomer();
    }

    tableHeaders = () => {
        return [
            {
                Header: "Id",
                accessor: "entity.id",
            },
            {
                Header: "Name",
                accessor: "entity.name"
            },
            {
                Header: "Mapping",
                accessor: "entity.nameMapping",
                Cell: row => {
                    return <input type="checkbox" checked={row.original.entity.nameMapping} onChange={(e) => this.toggleMapping(row.original.entity.id, row.original.entity.nameMapping)}/>
                }
            }
        ]
    };

    render() {
        return (
            <div className="row">
                <div className="col-12">
                    <ReactTable
                        columns={this.tableHeaders()}
                        data={this.props.customer}
                        defaultPageSize={10}
                        keyField='identification'
                        className="-striped -highlight"
                        previousText={"Zurück"}
                        nextText={'Vorwärts'}
                        loadingText={'Lade...'}
                        noDataText={"Keine Daten gefunden..."}
                        pageText={'Seite'}
                        ofText={'von'}
                        rowsText={'Zeilen'}
                        filterable
                    />
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminNameMapping);
