import React, {Component} from 'react';
import {connect} from 'react-redux';

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

/**
 * created by stephanpudras at 20.05.20
 */
class FadeInDown extends Component {
    constructor(props) {
        super(props);
        this.state = {className:"row form-group"}
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.children.key !== prevProps.children.key){
            this.setState({className: "row form-group animate__animated animate__fadeIn"});
            setTimeout(() => {this.setState({className: "row form-group"})}, 1000);
        }
    }


    render() {
        return (
            <div className={this.state.className}>
                {
                    this.props.children
                }
            </div>
        )
    }}

export default connect(mapStateToProps, mapDispatchToProps)(FadeInDown);