import {Attendance, AttendanceCreationProperties} from "../types";
import {exportToExcel} from "../logic/excel/ExcelExport";
import {saveAs} from "file-saver";

interface TBProps {
    raw: Attendance[]
    portalUser: any
    position?: string | undefined
    creationProperties: AttendanceCreationProperties
}

export class FileWrite {
    static writeExcel(props:TBProps){
            const t = exportToExcel(props.raw, props.portalUser, props.creationProperties.position?.entity?.name)
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xslx'

            t.then(
                ex => {
                    let date = new Date()
                    let blob = new Blob([ex], {type: fileType})
                    saveAs(blob, "abci_" + date.toLocaleDateString() + "_" + props.creationProperties.position?.entity?.name + ".xlsx")
                }
            )

    }
}