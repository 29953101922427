import Create from "./create/views/Create"
import Manage from "./manage/views/Manage"

const attendanceCRUD = {
    views:{
        Create:Create,
        Manage:Manage
    },
    logic:{},
    api:{}
}

export default attendanceCRUD
