import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AttendanceCreationResult, AttendanceCreationStatus, Customer, CustomerTestvariant} from "../../../types";
import {AppDispatch, AppThunk} from "../../../store";
import {allocTestvariant, deallocTestvariant, getTestvariants} from "../api/testvariantAPI";

const initialState:CustomerTestvariant[] = []


const testvariantSlice = createSlice({
    name: "testvariants",
    initialState,
    reducers: {
        addTestvariant(state, action:PayloadAction<CustomerTestvariant>){
            let idx: number | undefined = state.findIndex(c => c.testvariant == action.payload.testvariant)
            let newState = idx < 0 ? [...state, action.payload] : [...state]
            return newState
        },
        removeTestvariant(state, action: PayloadAction<CustomerTestvariant>){
            let idx: number | undefined = state.findIndex(c => c.testvariant == action.payload.testvariant)
            let newState = idx > -1 ? [...state.slice(0, idx), ...state.slice(idx+1)] : [...state]
            return newState
        },
        changedCustomer(state, action: PayloadAction<any>){
            return initialState
        },
        addTestvariants(state, action:PayloadAction<CustomerTestvariant[]>){
            return action.payload
        }
    }
})

export const addTestvariant = (ct:CustomerTestvariant):AppThunk => async (dispatch: AppDispatch) => {
    let c: CustomerTestvariant = await allocTestvariant(ct)
    dispatch(testvariantSlice.actions.addTestvariant(c))
}

export const removeTestvariant = (ct:CustomerTestvariant):AppThunk => async (dispatch: AppDispatch) => {
    let c: CustomerTestvariant = await deallocTestvariant(ct)
    dispatch(testvariantSlice.actions.removeTestvariant(c))
}

export const allTestvariants = (customer: Customer):AppThunk => async (dispatch: AppDispatch ) => {
    let c:CustomerTestvariant[] = await getTestvariants(customer)
    dispatch(testvariantSlice.actions.addTestvariants(c))
}

export const changedCustomer = ():AppThunk => async (dispatch: AppDispatch) => {
    dispatch(testvariantSlice.actions.changedCustomer(null))
}

export default testvariantSlice.reducer