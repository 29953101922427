import {create} from "zustand";
import {devtools} from "zustand/middleware";

interface DeleteResponseState {
  isOpen: boolean
  isSuccess: boolean
  message: string
  open: (message: string, success: boolean) => void
  close: () => void
}

const useDeleteResponse = create<DeleteResponseState>()(devtools((set,get) => ({
  isOpen: false,
  isSuccess: false,
  message: "",
  open:(message:string, success:boolean) => {
    set({isOpen: true, isSuccess: success, message:message})
  },
  close:() => {
    set({isOpen: false})
  }
})))

export {useDeleteResponse}