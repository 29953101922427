/**
 * Created by stephanpudras on 26.10.18.
 */
import React from 'react';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';

class ATooltip extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
         return (
             typeof this.props.children !== "undefined" ?
                 <Tooltip overlay={this.props.text !== null && typeof this.props.text !== "undefined" ? <span>{this.props.text}</span> : <span>""</span>} placement="bottom">
                        {this.props.children}
                    </Tooltip>
                    :
                    <Tooltip overlay={this.props.text !== null && typeof this.props.text !== "undefined" ? this.props.text : ""} arrowContent={<div className="rc-tooltip-arrow-inner"> </div>} mouseEnterDelay={1} placement="bottom">
                        {this.props.children}
                    </Tooltip>
         )


    }
}

export default ATooltip;