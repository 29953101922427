import React from 'react';


class CustomerList extends React.Component {
  constructor(props){
    super(props);
  }
  render(){
    return(
      <div>
        <table>
          <thead>
            <tr>
              <th>
                ID
              </th>
              <th>
                Name
              </th>
            </tr>
          </thead>
          <tbody>
            {
              this.props.data.map((item, index) => {
                if(item.ref != null)
                return <tr key={"customer_list_" + index}>
                  <td>
                    {item.ref.id}
                  </td>
                  <td>
                    {item.ref.name}
                  </td>
                </tr>
                else
                  return null;
              })
            }
          </tbody>
        </table>
      </div>
    )
  }
}

export default CustomerList;
