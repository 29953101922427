import RequestEntity from "../../../api/RequestEntity";
import URLConstants from "../../../api/URLConstants";
import InterviewManagementConst from "../const/InterviewManagementConst";
import AppConst from "../../../AppConst";

const BASE_URL = URLConstants.getURL();
const InterviewManagementActions = {
    selectCustomer: (customerId) => {
        return {type: InterviewManagementConst.SELECT_CUSTOMER_ADMIN_INTERVIEW, payload:{customerId: customerId}};
    },
    fetchInterviewManagementDTO: () => {
        return (dispatch, getState) => {
            let year = getState().admin.interviewManagement.selectedYear.year.value;
            let month = getState().admin.interviewManagement.selectedMonth.month.value;
            let customerId = getState().admin.interviewManagement.selectedCustomer.customerId.value;
            dispatch({type: AppConst.AJAX_CALL, entity: new RequestEntity(BASE_URL + "/portal/admin/interview/" + customerId + "?year=" + year + "&month=" + month , "GET", InterviewManagementConst.FETCH_INTERVIEW_DTO, null)});
        };

    },
    selectYear:(year) => {
        return {type: InterviewManagementConst.SELECT_YEAR_INTERVIEW_MANAGEMENT, payload:{year: year}};
    },
    selectMonth:(month) => {
        return {type: InterviewManagementConst.SELECT_MONTH_INTERVIEW_MANAGEMENT, payload:{month:month}}
    }
};

export default InterviewManagementActions;