import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    AttendanceCreationResult,
    AttendanceCreationStatus,
    Customer,
    Customer_Flat,
    CustomerTestvariant
} from "../../../../types";
import {AppDispatch, AppThunk} from "../../../../store";
import {loadAvailableTestvariants} from "../api/attendanceApi";

const initialState:CustomerTestvariant[] = []

const availableTestvariantsSlice = createSlice({
    name: "availableTestvariants",
    initialState,
    reducers: {
        addTestvariant(state, action:PayloadAction<CustomerTestvariant>){
            return [...state, action.payload]
        },
        addAllTestvariants(state, action:PayloadAction<CustomerTestvariant[]>){
            return action.payload
        }
    }
})

export const allAvailableTestvariants = (customer: Customer_Flat):AppThunk => async (dispatch: AppDispatch) => {
    let avail: CustomerTestvariant[] = await loadAvailableTestvariants(customer)
    dispatch(availableTestvariantsSlice.actions.addAllTestvariants(avail))
}

export default availableTestvariantsSlice.reducer