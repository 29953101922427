import {create} from "zustand";
import {devtools} from "zustand/middleware";

interface EditResponseState {
  isOpen: boolean
  isSuccess: boolean
  message: string
  open: (message: string, success:boolean) => void
  close: () => void
}

const useEditResponse = create<EditResponseState>()(devtools((set,get) => ({
  isOpen: false,
  isSuccess: false,
  message: "",
  open: (message: string, success:boolean) => {
    set({message: message, isOpen:true, isSuccess:success})
  },
  close:() => {
    set({isOpen: false})
  }
})))

export {useEditResponse}