class URLConstants {
    constructor(){
        URLConstants.getURL = URLConstants.getURL.bind(this);
        URLConstants.getJson = URLConstants.getJson.bind(this);
    }

    static getURL(){
        let env = window.location.host;
        if(env.indexOf("localhost") > -1){
            return "http://localhost:8090"
        }else if(env.indexOf("portal-front-eval1") > -1){
            return "https://portal-back-eval1.develability.net"
        }else if(env.indexOf("portal-front-eval2") > -1){
            return "https://portal-back-eval2.develability.net"
        }else{
            return "https://abciportalgw.develability.net"
        }
    }

    static getJson(){
        let env = window.location.host;
        if(env.indexOf("localhost") > -1){
            return "http://localhost:8082"
        }else if(env.indexOf("portal-front-eval1") > -1){
            return "https://portal-front-eval1.develability.net"
        }else if(env.indexOf("portal-front-eval2") > -1){
            return "https://portal-front-eval2.develability.net"
        }else{
            return "https://abciportal.develability.net"
        }
    }

    static getKeycloakConf(){
        let env = window.location.host;
        if(env.indexOf("localhost") > -1){
            return "http://localhost:8082/keycloak.dev.json"
        }else if(env.indexOf("portal-front-eval1") > -1){
            return "https://portal-front-eval1.develability.net/keycloak.test.json";
        }else if(env.indexOf("portal-front-eval2") > -1){
            return "https://portal-front-eval2.develability.net/keycloak.test.json";
        }else{
            return "https://abciportal.develability.net/keycloak.prod.json";
        }
    }

    static getRedirectURI(){
        let env = window.location.host;
        if(env.indexOf("localhost") > -1){
            return "http://localhost:3000";
        }else if(env.indexOf("portal-front-eval1") > -1){
            return "https://portal-front-eval1.develability.net/home";
        }else if(env.indexOf("portal-front-eval2") > -1){
            return "https://portal-front-eval2.develability.net/home";
        }else{
            return "https://abciportal.develability.net/home";
        }
    }
}

export default URLConstants