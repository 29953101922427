import {create} from "zustand";
import {devtools} from "zustand/middleware";
import {RProfile, RProfileUser} from "../../types";
import axios from "axios";
import URLConstants from "../../api/URLConstants";

interface  ProfileAdminUserState {
  list: RProfileUser[]
  active: number | undefined
  modalEdit: boolean
  modalDelete: boolean
  onlyOwn: boolean
  initialFetch: boolean
  fetchLoading: boolean
  updateResponse: { open: boolean, success:boolean, name: string}
  deleteResponse: { open: boolean, success:boolean, name: string}
  fetch: () => void
  update: (profile:RProfileUser | undefined) => void
  delete: (id: number | undefined) => void
  post: (id:number, profile:RProfileUser) => void
  openEdit:() => void
  openDelete: () => void
  toggleActive: (id:number) => void
  toggleOwn: () => void
  closeUpdateResponse: () => void
  closeDeleteResponse: () => void
}

const useProfileAdminUser = create<ProfileAdminUserState>()(devtools((set,get) => ({
  list:[],
  active: undefined,
  modalEdit: false,
  modalDelete: false,
  onlyOwn: false,
  initialFetch: false,
  fetchLoading: false,
  updateResponse: {open:false, success: false, name:""},
  deleteResponse: {open:false, success: false, name:""},
  fetch:() => {
    if(!get().fetchLoading && get().list.length === 0){
      set({fetchLoading: true})
      axios.get<RProfileUser[]>(URLConstants.getURL() + `/portal/profile/user`)
        .then(r => set({list:[...r.data], fetchLoading:false, initialFetch:true}))
    }
  },
  update:(profile:RProfileUser | undefined) => {
    set({updateResponse: {open:false, success: false, name:""}})
    if(profile !== undefined) {
      let idx = get().list.findIndex(it => it.id === profile.id)
      console.log(profile)
      axios.put<RProfileUser>(URLConstants.getURL() + `/portal/profile/group/${profile.id}`, profile)
        .then(r => set({list: [...get().list.slice(0, idx), r.data, ...get().list.slice(idx + 1)], updateResponse: {open:true, success: !!r.data, name: r.data.profile.name}}))
    }
  },
  delete:(id:number | undefined) => {
    set({deleteResponse: {open:false, success: false, name:""}})
    if(id !== undefined) {
      let idx = get().list.findIndex(it => it.id === id)
      axios.delete<boolean>(URLConstants.getURL() + `/portal/profile/group/${id}`)
        .then(r => set({list: [...get().list.slice(0, idx), ...get().list.slice(idx + 1)], deleteResponse: {open: true, success: r.data, name: get().list[idx].profile.name}}))
    }
  },
  post:(id:number, profile: RProfileUser) => {
    let idx = get().list.findIndex(it => it.id === id)
    axios.put<RProfileUser>(URLConstants.getURL() + `/portal/profile/group/${id}`, profile)
      .then(r => set({list:[...get().list.slice(0,idx),r.data,...get().list.slice(idx+1)]}))
  },
  openEdit:() => {
    set({modalEdit: !get().modalEdit})
  },
  toggleActive:(id:number) => {
    set({active:id})
  },
  toggleOwn: () => {
    set({onlyOwn: !get().onlyOwn})
  },
  openDelete: () => {
    set({modalDelete: !get().modalDelete})
  },
  closeUpdateResponse: () => {
    set({updateResponse:{...get().deleteResponse, open:false}})
  },
  closeDeleteResponse: () => {
    set({deleteResponse:{...get().deleteResponse, open:false}})
  }
})))

export {useProfileAdminUser}