import React, {Component} from 'react';
import {connect} from 'react-redux';
import AdminActions from './AdminActions';

class Bootstrap extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <p>Beim Abgleich der Portaldaten werden alle Kunden und Positionen/Projekte (Jobs) aus PadProfiler
                    eingelesen und
                    dafür in der Datenbank des Kundenportals Verweise gespeichert.
                    Aus dem Keycloak User-Management werden alle Benutzer, Rollen und Gruppen ausgelesen:
                </p>
                <ol>
                    <li>1. Wenn eine Gruppe so heißt, wie in Kunde, werden alle Benutzer dieser Gruppe diesem Kunden
                        zugeordnet.
                    </li>
                    <li>2. Für Benutzer mit Rolle 'ROLE_RECRUITER' wird in der Portaldatenbank ein Recruiter angelegt.
                        Die Jobs des Kunden werden so gespeichert, als wären sie von diesem Recruiter angelegt worden,
                        damit
                        er darauf Zugriff hat.<br/><br/><p>
                            Wenn beim Benutzer des Recruiters als Attribute 'position' und ggf. auch 'mandate' angegeben
                            wurde,
                            erhält dieser Recruiter nicht Zugriff auf alle Jobs des Kunden, sondern nur auf die Position
                            und Projekte,
                            deren Name mit Position=position bzw. Projekt=mandate übereinstimmen.</p>
                    </li>
                    <li>3. Für Benutzer mit Rolle 'ROLE_ASSISTANT' werden Assistenzeverweise auf alle Recruiter
                        des Kunden angelegt.
                    </li>
                    <li>4. Für Benutzer mit Rolle 'ROLE_HIRING_MANAGER' wird ein HiringManager des Kunden angelegt.
                    </li>
                    <li>5. Alle AbciProfile werden für alle Kunden sichtbar gemacht, falls es noch keine AbciProfile für einen
                        Kunden gibt.
                    </li>
                </ol>
                <p>
                Wenn der Abgleich mehrmals ausgeführt wird, werden bestehende Daten in der Portaldatenbank nicht gelöscht,
                    sondern bei gefundenen Unterschieden zu PadProfiler bzw. Keycloak nur korrigiert und fehlende Daten
                    ergänzt.
                </p>
                <button className="btn btn-outline-warning" onClick={(e) => this.props.bootstrap()}>Abgleich starten
                </button>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    bootstrap: () => dispatch(AdminActions.bootstrap())
});

export default connect(mapStateToProps, mapDispatchToProps)(Bootstrap);
