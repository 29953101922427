import {combineReducers} from "@reduxjs/toolkit";
import app from "./AppReducer";
import home from './HomeReducer';
import admin from './AdminReducer';
import {locale} from './LocaleReducer'
import attendances from './AttendanceReducer';
import results from "./ResultsReducer";
import {result} from "../module/results/reducer/ResultReducer"
import global from "../bootstrap/global"
import attendance from "../features/attendance/reducerIndex"
import administration from "../features/admin/adminIndex"

const rootReducer = combineReducers({app, home, admin, locale, attendances, results, result, global, attendance, administration}); //combine reducers (multiple, can be nested combines, too)
export type RootState = ReturnType<typeof rootReducer>
export default rootReducer;
