import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  List,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import {GroupUser} from "../../../types";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {useProfileShare} from "../state/ProfileShareModalState";
import {useProfileAdmin} from "../state/ProfileAdminState";
import {useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";

export const ProfileShareModal = ():JSX.Element => {
  const [isOpen, profile, shares, close, setActiveUser, toggleCanShare, toggleCanEdit, toggleCanDelete, save] = useProfileShare((state) =>
    [state.isOpen, state.profile, state.share, state.close, state.setActiveUser, state.toggleCanShare, state.toggleCanEdit, state.toggleCanDelete, state.save])
  const [users, userId] = useProfileAdmin((state) => [state.groupUsers, state.userId])
  const {t, i18n} = useTranslation("common")

  const handleUser = (user: GroupUser) => {
    setActiveUser(user)
    toggleCanShare(user.userId)
  };

  return (
    <>
      <Dialog open={isOpen}>
        <DialogTitle>
          {`${t("dialogTitles.shareProfile")}: ${profile ? profile.name : ""}`}
        </DialogTitle>
        <DialogContent>
          <Box>
            <Typography variant={"body2"}>{t("dialogText.shareProfile")}</Typography>
          </Box>
          <Box sx={{display:"flex", flexDirection:"row"}}>
            <Table >
              <TableHead>
                <TableRow>
                  <TableCell>{t("profileTable.groupUsers")}</TableCell>
                  <TableCell>{t("profileTable.shareProfile")}</TableCell>
                  <TableCell>{t("profileTable.userCanEdit")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  users.filter(it => it.userId !== userId).map((u,idx) =>
                  <TableRow key={`table-share-${idx}`}>
                    <TableCell>
                      <Box>
                        <List>
                          <li><b>{u.userName}</b></li>
                          <li><a href={`mailto:${u.email}`}>{u.email}</a></li>
                          <li>{u.firstName}&nbsp;{u.lastName}</li>
                        </List>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <FormControlLabel control={
                        <Checkbox
                          checked={shares.find(it => it.userId === u.userId) !== undefined}
                          onClick={() => handleUser(u)}
                        />
                      } label={""}/>
                    </TableCell>
                    <TableCell>
                      <FormControlLabel control={
                        <Checkbox
                          checked={shares.find(it => it.userId === u.userId) ? shares.find(it => it.userId === u.userId).canEdit : false}
                          disabled={shares.find(it => it.userId === u.userId) === undefined}
                          onClick={() => toggleCanEdit(u.userId)}
                        />
                      } label={""}/>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant={"contained"} color={"primary"} onClick={() => save()}>Übernehmen</Button>
          <Button variant={"contained"} color={"secondary"} onClick={() => close()}>Abbrechen</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}