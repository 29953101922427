import React, { Component } from 'react';
import { connect } from 'react-redux';
import AppActions from "../../AppActions";
import Keycloak from "keycloak-js";
import CustomerList from "../../components/CustomerList";
import Sidebar from "../../components/Sidebar";


 class Customer extends Component {

  render() {
    return (
      <div>
        <p className="App-intro">
            Viel Erfolg!
        </p>
          {
            this.props.keycloak.hasOwnProperty("keycloak") ?
            <div>
              {
                this.props.keycloak.keycloak.hasRealmRole("ROLE_OWNER_ADMIN") ?
                  <div>
                    <button onClick={(e) => this.props.customer()}>Kundenliste</button>
                    {
                      this.props.cust.length > 0 ?
                      <div>
                        <h1>Kundenliste</h1>
                        <CustomerList data={this.props.cust}/>
                      </div>
                      :
                      null
                    }
                  </div>

                  :
                  null
              }
            </div>
            :
            null
          }


        <br/>
        <a href="http://localhost:8090/portal/user/list">
            Users
        </a><br/>
        <a id="logout"
           href="http://localhost:8080/auth/realms/abci/protocol/openid-connect/logout?redirect_uri=http://localhost:8081"
           className="btn btn-dark">
            <span className="glyphicon glyphicon-log-out"></span>
            Logout
        </a>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  cust: state.app.cust,
  loadingButton: state.app.loadingButton,
  keycloack: state.props.keycloak
});

const mapDispatchToProps = (dispatch) => ({
  customer: () => dispatch(AppActions.customer())
})

export default connect(mapStateToProps, mapDispatchToProps)(Customer);
