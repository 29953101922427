import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import ReactTable from "react-table-6/react-table";
import {format, getTime, parseISO} from "date-fns";
import {de} from "date-fns/locale";
import {withTranslation} from "react-i18next";



 class SummaryShort extends Component {
   constructor(props){
     super(props);
     this.buildTableArray = this.buildTableArray.bind(this);
   }

   buildTableArray(){
         let finArr = [];
       this.props.tableData.map(item => {
             finArr.push(item.ref)
         });
         return finArr;
     };

     tableHeaders = () => {
         return [
             {
                 Header: this.props.t("tHeadLabels.logincode"),
                 accessor: "identification"
             },
             {
                 Header: this.props.t("tHeadLabels.position"),
                 accessor: "position"
             },
             {
                 Header: this.props.t("tHeadLabels.started"),
                 accessor: "started",
                 sortMethod: (a, b) => {
                     let oA = getTime(parseISO(a));
                     let oB = getTime(parseISO(b));
                     return oA<oB ? -1 : oA>oB ? 1 : 0;
                 }
             },
             {
                 Header: this.props.t("tHeadLabels.block"),
                 accessor: "block"
             }
         ];
     };

  render() {
    return (
      <div className="card form-group">
          <div className="card-header"><strong>{this.props.header} <span className="float-right"><Link to={this.props.link} className="">  </Link></span></strong></div>
        <div className="card-body" style={{padding: "0"}}>
            <ReactTable
                data={this.props.tdata}
                columns={this.tableHeaders()}
                defaultPageSize={5}
                keyField='identification'
                className="-striped -highlight"
                previousText={"Zurück"}
                nextText={'Vorwärts'}
                loadingText={'Lade...'}
                noDataText={this.props.t("tableControls.nodata")}
                pageText={'Seite'}
                ofText={'von'}
                rowsText={'Zeilen'}
                minRows={3}
                showPagination={false}
                defaultSorted={[
                    {
                        id: "started",
                        desc: true
                    }
                ]}
            />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({

});

const wSummaryShortTrans = withTranslation(["common"])(SummaryShort);
export default connect(mapStateToProps, mapDispatchToProps)(wSummaryShortTrans);
