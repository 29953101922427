/**
 * Created by stephanpudras on 12.10.18.
 */

import React from 'react';
import {connect} from 'react-redux';
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {
    format,
    getTime,
    subDays,
    getISODay,
    parse,
    isSameDay,
    isSameMonth,
    isSameWeek,
    isSameYear,
    parseISO
} from "date-fns";
import {de} from "date-fns/locale";
import {enGB as en} from "date-fns/locale";
import {withTranslation} from "react-i18next";

const mapStateToProps = (state) => {
    return {
        data: state.home.finished.data,
        started: state.home.started.data
    }
};

const mapDispatchToProps = (dispatch) => {
    return {}
};

class StatsWeek extends React.Component {
    constructor(props) {
        super(props);
        this.getPastDays = this.getPastDays.bind(this);
    }

    getPastDays = () =>{
        let time = Date.now();
        let tmpDate = "";
        let arr = [];
        let nDate = new Date()
        arr.push(nDate.toISOString());
        tmpDate = Object.assign(nDate);
        while(arr.length < 7){
            tmpDate = subDays(tmpDate, 1);
            arr.push(tmpDate.toISOString());
        }

        let dateArr = [];
        arr.reverse().map((date, index) => {
            let day = {};
            let iso = getISODay(parseISO(date));
            day["name"] = this.getDayName(iso) + (index === 6 ? " (" + this.props.t("labels.today") +")" : "");
            day["nameShort"] = this.getDayNameShort(iso);
            switch(this.props.i18n.language){
                case "de":
                    day["date"] = format(parseISO(date),"dd.MM.yyyy", {locale:de});
                    break;
                case "en":
                    day["date"] = format(parseISO(date),"MM/dd/yyyy", {locale:en});
                    break;
                default:
                    day["date"] = format(parseISO(date),"MM/dd/yyyy", {locale:en});
                    break;
            }
            day["dateRaw"] = date;
            day[this.props.t("diagrams.legend.finished")] = 0;
            day[this.props.t("diagrams.legend.started")] = 0;
            dateArr.push(day);
        });

        this.props.data.map(item => {
            dateArr.map(dateItem => {
                if(isSameDay(parseISO(item.ref.finishedAt), parseISO(dateItem.dateRaw)) &&
                   isSameYear(parseISO(item.ref.finishedAt), parseISO(dateItem.dateRaw)) &&
                   isSameMonth(parseISO(item.ref.finishedAt), parseISO(dateItem.dateRaw)) &&
                   isSameWeek(parseISO(item.ref.finishedAt), parseISO(dateItem.dateRaw)))
                    dateItem[this.props.t("diagrams.legend.finished")] = dateItem[this.props.t("diagrams.legend.finished")] + 1
            })
        });
        this.props.started.map(item => {
            dateArr.map(dateItem => {
                if(isSameDay(parseISO(item.ref.started), parseISO(dateItem.dateRaw)) &&
                    isSameYear(parseISO(item.ref.started), parseISO(dateItem.dateRaw)) &&
                    isSameMonth(parseISO(item.ref.started), parseISO(dateItem.dateRaw)) &&
                    isSameWeek(parseISO(item.ref.started), parseISO(dateItem.dateRaw)))
                    dateItem[this.props.t("diagrams.legend.started")] = dateItem[this.props.t("diagrams.legend.started")] + 1
            });
        });
        return dateArr;
    };

    getDayName = (number) => {
        switch (number) {
            case 1:
                return this.props.t("labels.days.long.mon");
            case 2:
                return this.props.t("labels.days.long.tue");
            case 3:
                return this.props.t("labels.days.long.wed");
            case 4:
                return this.props.t("labels.days.long.thu");
            case 5:
                return this.props.t("labels.days.long.fri");
            case 6:
                return this.props.t("labels.days.long.sat");
            case 7:
                return this.props.t("labels.days.long.sun");
            default:
                return null;
        }
    };
    getDayNameShort = (number) => {
        switch (number) {
            case 1:
                return this.props.t("labels.days.short.mon");
            case 2:
                return this.props.t("labels.days.short.tue");
            case 3:
                return this.props.t("labels.days.short.wed");
            case 4:
                return this.props.t("labels.days.short.thu");
            case 5:
                return this.props.t("labels.days.short.fri");
            case 6:
                return this.props.t("labels.days.short.sat");
            case 7:
                return this.props.t("labels.days.short.sun");
            default:
                return null;
        }
    };

    render() {
        this.getPastDays();
        return (
            <div className="card form-group">
                <div className="card-header">
                    <strong>{this.props.t("homeCardHeader.stats")}</strong>
                </div>
                <div className="card-body" style={{padding:"0"}}>
                    <ResponsiveContainer width={"100%"} aspect={2}>
                    <BarChart data={this.getPastDays()}
                              margin={{top: 5, right: 5, left: 0, bottom: 5}}>
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey="nameShort"/>
                        <YAxis type="number" ticks={[0,5,10,15,20,25,30,35,40,45,50]}/>
                        <Tooltip content={<CustomTooltip t={this.props.t}/>}/>
                        <Legend/>
                        <Bar dataKey={this.props.t("diagrams.legend.finished")} barSize={20} fill="#0079c2" />
                        <Bar dataKey={this.props.t("diagrams.legend.started")} barSize={20} fill="#fcb040" />
                    </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
        )
    }
}

class CustomTooltip extends React.Component{
    constructor(props, context) {
        super(props, context);
        this.getIntroOfPage = this.getIntroOfPage.bind(this);
    }

    getIntroOfPage(label) {
        switch(label){
            case "Gspeed":
                return {header:"Informationsverarbeitung(Gs)" ,exp: "Abstrakt-logische Problemlösung\nspeed", info:""};
            case "Gpower":
                return {header:"Informationsverarbeitung(Gp)" ,exp: "Abstrakt-logische Problemlösung\npower", info:""};
            case "C":
                return {header:"Informationsverarbeitung(C)" ,exp: "verbale und numerische Aufgaben", info:""};
            case "K":
                return {header:"Informationsverarbeitung(K)" ,exp: "Wissen", info:""};
            case "M":
                return {header:"Motivation(M)" ,exp: "(Abschlusspräferenz - Handlungspräferenz)", info:"Orientierung der Grundlegenden Motivation"};
            case "N":
                return {header:"Normorientierung(N)" ,exp: "(Veränderungsbereitschaft - Regelidentifikation)", info:"Orientierung am grundlegenden persönlichen\nNorm -und Wertesystem"};
            case "S":
                return {header:"Sensitivität(S)" ,exp: "(Sachorientierung - Personenorientierung)",info:"Grundlegende Interaktionsorientierung"};
            case "V":
                return {header:"Volition(V)" ,exp: "(Kompromissorientierung - Durchsetzungsorientierung)", info: "Orientierung an grundlegenden Zielvorstellungen"};
        }
    }

    render(){
        const { active } = this.props;

        if (active) {
            const { payload, label } = this.props;
            return (
                <div className="card">
                    <div className="card-header">
                        {payload[0].payload.name}
                    </div>
                    <div className="card-body">
                        <h6 className="card-title">{payload[0].payload.date}</h6>
                        <p className="card-text">{`${this.props.t("diagrams.legend.finished")}: ${payload[0].value}`}</p>
                        <p className="card-text">{`${this.props.t("diagrams.legend.started")}: ${payload[1].value}`}</p>
                    </div>
                </div>
            );
        }
        return null;
    }
}

const wStatsWeekTrans = withTranslation(["common"])(StatsWeek);
export default connect(mapStateToProps, mapDispatchToProps)(wStatsWeekTrans)