import {Profile, ProfileTableData, RProfileUser} from "../types";

const ProfileTableDataNormalizer = {
  normalize: (profiles: Profile[], sharedProfiles: RProfileUser[]):ProfileTableData[] => {
    let list:ProfileTableData[] = []
    profiles.map(p => {
      if(p.entity){
        list.push({id:(p.entity.extId as number), name: p.entity.name, abci:true, dateCreated: p.entity.createdAt, dateUpdated: "", hasShares:false, description:"", username:"", userId: undefined})
      }
    })
    sharedProfiles.map(sp => {
      list.push({id:sp.profile.id, name: sp.profile.name, profile: sp.profile, abci:false, dateCreated:sp.profile.dateCreated, dateUpdated:sp.profile.dateUpdated, shares:sp.shares, hasShares:true, description:sp.profile.description, username:sp.userName, userId:sp.userId})
    })
    return list
  }
}

export {ProfileTableDataNormalizer}