export const DeepClone = {
  clone: (obj:any) => {
    if(obj !== null && obj !== undefined) {
      let objStringified = JSON.stringify(obj)
      return JSON.parse(objStringified)
    }else {
      return null;
    }
  }
}

