import React, { Component } from 'react';
import { connect } from 'react-redux';
import "react-table-6/react-table.css";
import ResultsActions from "./actions/ResultsActions";
import ReportForm from "./ReportForm";
import ReportState from "../../const/ReportState";
import 'rc-tooltip/assets/bootstrap_white.css';
import BaseActions from "./actions/BaseActions";
import ListForm from "./view/ListForm";
import Flip from "../../components/style/Flip";
import ListTable from "../../components/table/ListTable";
import ResultTable from "../../components/table/ResultTable";
import FadeInDown from "../../components/style/FadeInDown";
import {withTranslation} from "react-i18next";




 class Finished extends Component {
  constructor(props){
    super(props);
    this.getFilteredProfiles = this.getFilteredProfiles.bind(this);

    this.selectPosition = this.selectPosition.bind(this);
    this.state = {byRef: false, tableEntries:[], files:[]};
    if(props !== null && props !== undefined && props.location !== undefined && props.location.state !== undefined && props.location.state !== null){
        if(props.location !== undefined && props !== "undefined" && props !== null && props.location.state.byRef !== null && props.location.state.byRef !== undefined && props.location.state !== undefined){
            if(props && props.hasOwnProperty("location")){
                if(props.location.hasOwnProperty("state") && typeof props.location.state !== "undefined"){
                    if(props.location.state.byRef && props.location.state.hasOwnProperty("tableEntries")){
                        this.state = {byRef: props.location.state.byRef, tableEntries: props.location.state.tableEntries};
                    }
                }
            }
        }
    }
  }

  getFilteredProfiles(){
    let oProfiles = [];
    this.props.profiles.profiles.map(profile => {
      oProfiles.push({value:profile.ref.id, label: profile.ref.name})
    });
    return oProfiles;
  }

  selectPosition(){
      this.setState({byRef: false})
  }


  componentDidMount(){
        //let test = this.props.log.l(1)
  }



     render() {

    return (
        <div>
            <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                        <Flip>
                            {
                                this.props.switch_form.form === "result" ?
                                    <ReportForm forwardRef="result-report-form" key="result-report-form" reportState={ReportState.FINISHED} fn={this.selectPosition}/>
                                    :
                                    <ListForm i18next={this.props.i18next} key="result-list-form"/>
                            }
                        </Flip>
                    </div>
            </div>
          <div className="row form-group">
              <div className="col-12">
                  <button className="btn btn-outline-primary"
                          onClick={(e) => this.props.switchForm(this.props.switch_form.form === "result" ? "list" : "result")}>
                      {
                          this.props.switch_form.form === "result" ?
                              <span>{this.props.t("labels.to_extended_list_generation")}&nbsp;<span className="icon-arrow-bold-right"> </span></span>
                              :
                              <span><span className="icon-arrow-bold-left"> </span>&nbsp;{this.props.t("labels.back_to_results")}</span>
                      }

                  </button>
              </div>
          </div>
          <div className="row form-group">
              <div className="col-12">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 alert alert-info">
                      {this.props.t("alerts.results.finished.hint") + ":"}
                  </div>
              </div>
          </div>
              <FadeInDown>
                  {
                      this.props.switch_form.form === "result" ?
                          <ResultTable key={"result_table"}/>
                          :
                          <ListTable key={"list_table"}/>
                  }
              </FadeInDown>
      </div>
    )
  }
}




const mapStateToProps = (state) => ({
  finished: state.results.finished,
 // positions: positions(state.results.positions.positions),
  positionsRaw: state.results.positions.positions,
  selectedPosition: state.results.positions.selectedFinished,
  tableSelection: state.results.tableSelection,
  language: state.results.language,
  profiles: state.results.profiles,
  reportType: state.results.reportType,
  interviewType: state.results.interviewType,
  downloadReport: state.results.downloadReport,
    finishedExpanded: state.results.finishedExpanded,
    percentRanges: state.results.percentRanges,
    customer: state.app.portal.userInfo.customer.id,
    i18next: state.locale.i18next,
    portal: state.app.portal,
    switch_form: state.results.switch_form,
    //List
    filter: state.result.list.filter,

    listDownloads: state.result.list.downloadList

});

const mapDispatchToProps = (dispatch) => ({
  fetchFinished: (jobId, customerId) => dispatch(ResultsActions.fetchFinished(jobId, customerId)),
  fetchPositions: () => dispatch(ResultsActions.fetchPositions()),
  selectPositionFinished:(e) => dispatch(ResultsActions.selectPositionFinished(e)),
  startReportsDownload: (interval, format) => dispatch(ResultsActions.startReportsDownload(interval, format)),
  requestReports: (format, cb) => dispatch(ResultsActions.requestReports(format, cb)),
  pollReports: (token) => dispatch(ResultsActions.pollReports(token)),
  selectAllFinished: (a, b) => dispatch(ResultsActions.selectAllFinished(a, b)),
  selectRowFinished: (key) => dispatch(ResultsActions.selectRowFinished(key)),
  chooseLanguage: (lang) => dispatch(ResultsActions.chooseLanguage(lang)),
  fetchProfiles: () => dispatch(ResultsActions.fetchProfiles()),
  chooseProfile: (id) => dispatch(ResultsActions.chooseProfile(id)),
  startRankingDownload: (interval, format) => dispatch(ResultsActions.startRankingDownload(interval, format)),
  pollRanking: (token) => dispatch(ResultsActions.pollRanking(token)),
  requestRanking: (format) => dispatch(ResultsActions.requestRanking(format)),
  setInterviewType: (interviewType) => dispatch(ResultsActions.setInterviewType(interviewType)),
  setReportType: (reportType) => dispatch(ResultsActions.setReportType(reportType)),
    //List


  //Reports as Zip


    setFinishedExpanded: (newExpanded, index, event) => dispatch(ResultsActions.setFinishedExpanded(newExpanded, index, event)),
    fetchRange: (identification, finishedAt) => dispatch(ResultsActions.fetchRange(identification, finishedAt)),

    switchForm: (form) => dispatch(BaseActions.switchForm(form))
});

const finishedTrans = withTranslation(["common"])(Finished);
export default connect(mapStateToProps, mapDispatchToProps)(finishedTrans);

