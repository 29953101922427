import AppConst from "../AppConst";
import {combineReducers} from "redux";
import ResultsConst from '../module/results/const/ResultsConst';
import ReportType from '../const/ReportType';
import InterviewType from '../const/InterviewType';
import ReportState from "../const/ReportState";
import RequestEntity from "../api/RequestEntity";
import URLConstants from "../api/URLConstants";
import {Timespan} from "../const/Timespan";

const downloadReportsDefault = {idle: true, pending:false, ready: false, token:null, format:null, interval:null, files:[]};
const downloadRankingsDefault = {idle: true, pending:false, ready: false, token:null, format:null, interval:null, file:null, fileName:null};

const downloadReportState = {idle: true, pending:false, ready: false, token: null, exportFormat: null, interval: null, files:[], zip:false};

const missingProperties = {open:false, missingProperties:[]}

const URL = URLConstants.getURL();

const resultPropertiesState = {
    inwork:{
        positionId:null,
        reportType: null,
        interviewType: null,
        format:  {value:"PDF", label: "PDF"},
        locale: {value:"DE", label: "Deutsch"},
        profileId: null
    },
    started:{
        positionId:null,
        reportType: null,
        interviewType: null,
        format:  {value:"PDF", label: "PDF"},
        locale: {value:"DE", label: "Deutsch"},
        profileId: null
    },
    finished:{
        positionId:null,
        reportType: null,
        interviewType: null,
        format: {value:"PDF", label: "PDF"},
        locale: {value:"DE", label: "Deutsch"},
        profileId: null
    },
};

const inwork = (state=[], action=null) => {
  switch(action.type){
    case ResultsConst.FETCH_INWORK:
      return Object.assign([], action.payload.active);
    default:
      return state;
  }
};

const started = (state=[], action=null) => {
  switch(action.type){
    case ResultsConst.FETCH_STARTED:
      return Object.assign([], action.payload.unfinished);
    default:
      return state;
  }
};

const finished = (state=[], action=null) => {
  switch(action.type){
    case ResultsConst.FETCH_FINISHED:
      return Object.assign([], action.payload.finished);
    default:
      return state;
  }
};

const positions = (state={positions:[], selectedFinished:null, selectedInwork:null, selectedStarted:null}, action=null) => {
  switch(action.type){
      case ResultsConst.FETCH_POSITIONS:
      return Object.assign({}, state, {positions: action.payload, selectedFinished:action.payload.length > 0 ? action.payload[0] : null});
    case ResultsConst.SELECT_POSITION_FINISHED:
      return Object.assign({}, state, {selectedFinished: action.payload != null ? action.payload.value : null});
    case ResultsConst.SELECT_POSITION_INWORK:
      return Object.assign({}, state, {selectedInwork: action.payload != null ? action.payload.value : null});
    case ResultsConst.SELECT_POSITION_STARTED:
      return Object.assign({}, state, {selectedStarted: action.payload != null ? action.payload : null});
    default:
      return state;
  }
};

//const reportsDownloadPDF = (state=downloadReportsDefault, action=null) => {
//    switch(action.type){
//        case ResultsConst.START_REPORTS_DOWNLOAD:
//            if(action.payload.format.toUpperCase() === "PDF")
//                return Object.assign({}, state, {idle:false, pending:true, ready:false, interval: action.payload.interval, token:null, format: action.payload.format, files:[]});
//            else
//                return state;
//        case ResultsConst.REQUEST_REPORTS:
//            console.log("REQUEST_REPORTS");
//            if(action.payload.format.toUpperCase() === "PDF")
//                return Object.assign({}, state, {token: action.payload.token});
//            else
//                return state;
//        case ResultsConst.FETCH_REPORTS:
//            if(action.payload.format.toUpperCase() === "PDF"){
//                if(action.payload.ready === true || action.payload.ready === "true"){
//                    clearInterval(state.interval);
//                    return Object.assign({}, state, {files: action.payload.files, ready: true, pending:false, filename:action.payload.filename})
//                }
//                if(action.payload.queued === true || action.payload.queued === "true"){
//                    return Object.assign({},state, {token: action.payload.token})
//                }
//                return state;
//            }
//            return state;
//        case ResultsConst.FINISHED_ROW_SELECTED:
//          return Object.assign({}, state, downloadReportsDefault);
//        case ResultsConst.FINISHED_ROW_SELECTED:
//          return Object.assign({}, state, downloadReportsDefault);
//        case ResultsConst.CHOOSE_LANGUAGE:
//          return Object.assign({}, state, downloadRankingsDefault);
//        default:
//            return state;
//    }
//};
//
//const reportsDownloadDOCX = (state=downloadReportsDefault, action=null) => {
//    switch(action.type){
//        case ResultsConst.START_REPORTS_DOWNLOAD:
//            if(action.payload.format.toUpperCase() === "DOCX")
//                return Object.assign({}, state, {idle:false, pending:true, ready:false, interval: action.payload.interval, token:null, format: action.payload.format, files:[]});
//            else
//                return state;
//        case ResultsConst.REQUEST_REPORTS:
//          if(action.payload.format.toUpperCase() === "DOCX")
//              return Object.assign({}, state, {token: action.payload.token});
//          else
//              return state;
//        case ResultsConst.FETCH_REPORTS:
//            if(action.payload.format.toUpperCase() === "DOCX") {
//                if (action.payload.ready === true || action.payload.ready === "true") {
//                    clearInterval(state.interval);
//                    return Object.assign({}, state, {
//                        files: action.payload.files,
//                        ready: true,
//                        pending: false,
//                        filename: action.payload.filename
//                    })
//                }
//                if (action.payload.queued === true || action.payload.queued === "true") {
//                    return Object.assign({}, state, {token: action.payload.token})
//                }
//                return state;
//            }
//            return state;
//        default:
//            return state;
//    }
//};

const tableSelection = (state={selection:[], selectAll:false}, action=null) => {
    switch(action.type){
        case ResultsConst.FINISHED_ROW_SELECTED:
            let newState = [];
            let keyIndex = state.selection.findIndex(item => item === action.payload.key);
            newState = keyIndex >= 0 ? [...state.selection.slice(0,keyIndex), ...state.selection.slice(keyIndex + 1)] : [...state.selection, action.payload.key];
            return Object.assign({}, state, {selection: newState});
        case ResultsConst.FINISHED_ALL_ROWS_SELECTED:
            return Object.assign({}, state, {selection: action.payload.toggleAll === false ? [] : action.payload.keys, selectAll: action.payload.toggleAll});
        case ResultsConst.FETCH_FINISHED:
            return Object.assign({}, state, {selection: [] });
        default:
            return state;
    }
};

const language = (state={lang:"de"}, action=null) => {
    switch(action.type){
      case ResultsConst.CHOOSE_LANGUAGE:
        return Object.assign({},state, {lang: action.payload.lang});
      default:
        return state;
    }
};

const profiles = (state={profiles:[], selected: null}, action=null) => {
    switch(action.type){
      case ResultsConst.FETCH_PROFILES:
        return Object.assign({}, state, {profiles:action.payload});
      case ResultsConst.CHOOSE_PROFILE:
        return Object.assign({}, state, {selected:action.payload});
      default:
        return state;
    }
};

//const rankingDownloadPDF = (state = {idle: true, pending:false, ready: false, token:null, format:null, interval:null, file:null}, action=null) => {
//    switch(action.type){
//        case ResultsConst.START_RANKING_DOWNLOAD:
//            if(action.payload.format === "PDF")
//                return Object.assign({}, state, {idle:false, pending:true, ready:false, interval: action.payload.interval,token:null, format: action.payload.format, file:null});
//            else
//                return state;
//        case ResultsConst.REQUEST_RANKING:
//            if(action.payload.format === "PDF")
//              return Object.assign({},state, {token: action.payload.token});
//            else
//              return state;
//        case ResultsConst.FETCH_RANKING:
//            if(action.payload.format === "PDF") {
//                if (action.payload.ready === true) {
//                    clearInterval(state.interval);
//                    return Object.assign({}, state, {
//                        file: action.payload.file,
//                        ready: true,
//                        pending: false,
//                        filename: action.payload.filename
//                    })
//                }
//                if (action.payload.queued === true) {
//                    return Object.assign({}, state, {token: action.payload.token})
//                }
//                return state;
//            }
//            return state;
//        default:
//            return state;
//    }
//};
//
//const rankingDownloadXLSX = (state = {idle: true, pending:false, ready: false, token:null, format:null, interval:null, file:null, filename: null}, action=null) => {
//    switch(action.type){
//        case ResultsConst.START_RANKING_DOWNLOAD:
//            if(action.payload.format === "EXCEL")
//                return Object.assign({}, state, {idle:false, pending:true, ready:false, interval: action.payload.interval,token:null, format: action.payload.format, file:null, filename:null});
//            else
//                return state;
//        case ResultsConst.REQUEST_RANKING:
//          if(action.payload.format === "XLSX")
//            return Object.assign({},state, {token: action.payload.token});
//          else
//            return state;
//        case ResultsConst.FETCH_RANKING:
//            if(action.payload.format === "EXCEL") {
//                if (action.payload.ready === true || action.payload.ready === "true") {
//                    clearInterval(state.interval);
//                    return Object.assign({}, state, {
//                        file: action.payload.file,
//                        ready: true,
//                        pending: false,
//                        filename: action.payload.filename
//                    })
//                }
//                if (action.payload.queued === true || action.payload.queued === "true") {
//                    return Object.assign({}, state, {token: action.payload.token})
//                }
//                return state;
//            }
//            return state;
//        default:
//            return state;
//    }
//};

const reportType = ( state = {reportType: null}, action=null) => {
    switch(action.type){
        case ResultsConst.SET_REPORT_TYPE:
            return Object.assign({}, state, {reportType:action.payload.reportType});
        default:
            return state;
    }
};

const interviewType = ( state = {interviewType: null}, action=null) => {
    switch(action.type){
        case ResultsConst.SET_INTERVIEW_TYPE:
            return Object.assign({}, state, {interviewType:action.payload.interviewType});
        default:
            return state;
    }
};

const downloadReport = ( state = downloadReportState, action=null) => {
    switch(action.type) {
        case ResultsConst.START_REPORT_DOWNLOAD:
            return Object.assign({}, state, {idle: false, pending:true, ready: false, interval: action.payload.interval, exportFormat: action.payload.exportFormat, zip:false});
        case ResultsConst.START_REPORT_DOWNLOAD_ZIP:
            return Object.assign({}, state, {idle: false, pending:true, ready: false, interval: action.payload.interval, exportFormat: action.payload.exportFormat, zip:true});
        case ResultsConst.REQUEST_REPORTS:
            return Object.assign({}, state, {token: action.payload.token});
        case ResultsConst.REQUEST_REPORTS_ZIP:
            return Object.assign({}, state, {token: action.payload.token});
        case ResultsConst.FETCH_REPORTS:
            if (action.payload.ready === true || action.payload.ready === "true") {
                clearInterval(state.interval);
                return Object.assign({}, state, {
                    files: action.payload.exportFiles,
                    ready: true,
                    pending: false,
                })
            }
            if (action.payload.queued === true || action.payload.queued === "true") {
                return Object.assign({}, state, {token: action.payload.token})
            }
            return state;
        case ResultsConst.FETCH_REPORTS_ZIP:
            if (action.payload.ready === true || action.payload.ready === "true") {
                clearInterval(state.interval);
                return Object.assign({}, state, {
                    files: action.payload.exportFiles,
                    ready: true,
                    pending: false,
                })
            }
            if (action.payload.queued === true || action.payload.queued === "true") {
                return Object.assign({}, state, {token: action.payload.token})
            }
            return state;
        case ResultsConst.SET_INTERVIEW_TYPE:
            return Object.assign({}, downloadReportState);
        case ResultsConst.SET_REPORT_TYPE:
            return Object.assign({}, downloadReportState);
        case ResultsConst.CHOOSE_LANGUAGE:
            return Object.assign({}, downloadReportState);
        case ResultsConst.CHOOSE_PROFILE:
            return Object.assign({}, downloadReportState);
        case ResultsConst.SELECT_POSITION_FINISHED:
            return Object.assign({}, downloadReportState);
        case ResultsConst.SET_POSITION:
            return Object.assign({}, downloadReportState);
        case ResultsConst.FINISHED_ROW_SELECTED:
            return Object.assign({}, downloadReportState);
        case ResultsConst.FINISHED_ALL_ROWS_SELECTED:
            return Object.assign({}, downloadReportState);
        default:
            return state;
    }
};

const resultProperties = (state = resultPropertiesState, action=null) => {
    switch (action.type) {
        case ResultsConst.CHOOSE_LANGUAGE:
            switch (action.payload.reportState) {
                case ReportState.INWORK:
                    return Object.assign({}, state, {
                        inwork: {
                            ...state.inwork,
                            locale: action.payload.locale
                        }
                    });
                case ReportState.STARTED:
                    return Object.assign({}, state, {
                        started: {
                            ...state.started,
                            locale: action.payload.locale
                        }
                    });
                case ReportState.FINISHED:
                    return Object.assign({}, state, {
                        finished: {
                            ...state.finished,
                            locale: action.payload.locale
                        }
                    });
            }
            break;
        case ResultsConst.CHOOSE_PROFILE:
            switch (action.payload.reportState) {
                case ReportState.INWORK:
                    return Object.assign({}, state, {
                        inwork: {
                            ...state.inwork,
                            profileId: action.payload.profileId
                        }
                    });
                case ReportState.STARTED:
                    return Object.assign({}, state, {
                        started: {
                            ...state.started,
                            profileId: action.payload.profileId
                        }
                    });
                case ReportState.FINISHED:
                    return Object.assign({}, state, {
                        finished: {
                            ...state.finished,
                            profileId: action.payload.profileId
                        }
                    });
            }
            break;
        case ResultsConst.SET_REPORT_TYPE:
            switch (action.payload.reportState) {
                case ReportState.INWORK:
                    return Object.assign({}, state, {
                        inwork: {
                            ...state.inwork,
                            reportType: action.payload.reportType
                        }
                    });
                case ReportState.STARTED:
                    return Object.assign({}, state, {
                        started: {
                            ...state.started,
                            reportType: action.payload.reportType
                        }
                    });
                case ReportState.FINISHED:
                    return Object.assign({}, state, {
                        finished: {
                            ...state.finished,
                            reportType: action.payload.reportType
                        }
                    });
            }
            break;
        case ResultsConst.SET_INTERVIEW_TYPE:
            switch (action.payload.reportState) {
                case ReportState.INWORK:
                    return Object.assign({}, state, {
                        inwork: {
                            ...state.inwork,
                            interviewType: action.payload.interviewType
                        }
                    });
                case ReportState.STARTED:
                    return Object.assign({}, state, {
                        started: {
                            ...state.started,
                            interviewType: action.payload.interviewType
                        }
                    });
                case ReportState.FINISHED:
                    return Object.assign({}, state, {
                        finished: {
                            ...state.finished,
                            interviewType: action.payload.interviewType
                        }
                    });
            }
            break;
        case ResultsConst.SET_POSITION:
            switch (action.payload.reportState) {
                case ReportState.INWORK:
                    return Object.assign({}, state, {
                        inwork: {
                            ...state.inwork,
                            positionId: action.payload.positionId
                        }
                    });
                case ReportState.STARTED:
                    return Object.assign({}, state, {
                        started: {
                            ...state.started,
                            positionId: action.payload.positionId
                        }
                    });
                case ReportState.FINISHED:
                    return Object.assign({}, state, {
                        finished: {
                            ...state.finished,
                            positionId: action.payload.positionId
                        }
                    });
            }
            break;
        case ResultsConst.SET_FILE_FORMAT:
            switch (action.payload.reportState) {
                case ReportState.INWORK:
                    return Object.assign({}, state, {
                        inwork: {
                            ...state.inwork,
                            format: action.payload.format
                        }
                    });
                case ReportState.STARTED:
                    return Object.assign({}, state, {
                        started: {
                            ...state.started,
                            format: action.payload.format
                        }
                    });
                case ReportState.FINISHED:
                    return Object.assign({}, state, {
                        finished: {
                            ...state.finished,
                            format: action.payload.format
                        }
                    });
            }
            break;
        case ResultsConst.FETCH_POSITIONS:
            //let customerId = null;
            let sorted = action.payload.sort((a,b) => (a-b));
            if(typeof action.meta !== "undefined") {
                if (action.meta.customerId && sorted[0] !== undefined && sorted[0].entity) {
                    sorted = action.payload.sort((a, b) => (a - b));
                    action.asyncDispatch({
                        type: AppConst.AJAX_CALL,
                        entity: new RequestEntity(URL + "/portal/attendance/" + action.meta.customerId + "/list?jobId=" + sorted[0].entity.id + "&finished=true&feedback=true", "GET", ResultsConst.FETCH_FINISHED)
                    });
                }
            }
            return Object.assign({}, state, {
                finished: {
                    ...state.finished,
                    positionsId: sorted[0] !== undefined && sorted[0].entity ? sorted[0].entity.id: undefined,
                    positionId:{value: sorted[0] !== undefined && sorted[0].entity ? sorted[0] !== undefined && sorted[0].entity.id: undefined, label: sorted[0] !== undefined && sorted[0].entity ? sorted[0].entity.name: ""}
                }
            });
        default:
            return state;
    }
};

const percentRanges = (state=[], action=null) => {
    switch(action.type){
        case ResultsConst.GET_RANGES:
            return Object.assign([], state, [...state, action.payload]);
        default:
            return state;
    }
};

const finishedExpanded = (state={}, action=null) => {
    switch(action.type){
        case ResultsConst.SET_FINISHED_EXPANDED:
            return Object.assign({}, state, action.payload.newExpanded);
        default:
            return state;
    }
};

const mappingFile = (state={file:null}, action=null) => {
    switch (action.type) {
        case ResultsConst.DROP_FILE:
           return Object.assign({}, state, {file:action.payload.file});
        default:
            return state;
    }
};

const listFilter = (state={timespan: Timespan.ONE_WEEK, profile:null, position: "", file:null, sort: null}, action=null) => {
    switch(action.type) {
        case ResultsConst.SET_FILTER_TIMESPAN:
            return Object.assign({}, state, {
                timespan: action.payload
            });
        case ResultsConst.SET_LIST_FILTER_PROFILE:
            return Object.assign({},state, {
                profile: action.payload
            });
        case ResultsConst.SET_LIST_FILTER_POSITION:
            return Object.assign({},state, {
                position: action.payload
            });
        case ResultsConst.SET_LIST_FILTER_FILE:
            return Object.assign({}, state, {
                file: action.payload
            });
        case ResultsConst.SET_LIST_FILTER_SORT:
            return Object.assign({}, state, {
                sort: action.payload
            })
        default:
            return state;
    }
}
const switch_form = (state={form: "result"}, action=null) => {
    switch(action.type){
        case "SWITCH_FORM":
            return Object.assign({}, state, {
                form: action.payload
            });
        default:
            return state;
    }
}

const dialog = (state={...missingProperties},action=null) => {
    switch(action.type){
        case ResultsConst.SET_DIALOG_OPEN:
            return Object.assign({}, state, {open:action.payload})
        case ResultsConst.SET_DIALOG_CLOSE:
            return Object.assign({}, state, {open:action.payload})
        case ResultsConst.SET_DIALOG_MISSING_PROPS:
            return Object.assign({}, state, {missingProperties:action.payload})
        default:
            return state;
    }
}
const results = combineReducers({inwork, started, finished, positions, tableSelection, language, profiles, reportType, interviewType, downloadReport, resultProperties, percentRanges, finishedExpanded, mappingFile, listFilter, switch_form, dialog});
export default results;
