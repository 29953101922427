interface Props {

}

export function LegalContentDe(props: Props){

    return(
        <div style={{marginTop:"2em"}} className="offset-xl-2 offset-lg-2 offset-md-1 col-xl-8 col-lg-8 col-md-10 col-sm-11 col-xs-12 privacy">
            <h3>Datenschutzhinweise </h3>

            <p>Mit diesen Datenschutzhinweisen informieren wir, die Harald Ackerschott GmbH, Adenauerallee 168, 53113 Bonn, ob und wenn ja, welche personenbezogenen Daten bei der  Nutzung unseres psychometrischen Onlineverfahrens erhoben und wie diese verarbeitet und genutzt werden. </p>
            <strong>Inhaltsübersicht: </strong>
            <ol type="1">
                <li> <a href="#data_responsibility">Wer ist für meine Daten verantwortlich?</a></li>
                <li> <a href="#data_anonymity">Ist meine Teilnahme anonym?</a></li>
                <li> <a href="#data_raisedData">Welche Daten werden erhoben?</a></li>
                <li> <a href="#data_usage">Wofür werden die Daten verwendet?</a></li>
                <li> <a href="#data_deletion">Wann werden meine Daten gelöscht?</a></li>
                <li> <a href="#data_location">Wo werden meine Daten gespeichert?</a></li>
                <li> <a href="#data_tracking">Werde ich getrackt?</a></li>
                <li> <a href="#data_cookies">Welche Cookies kommen zum Einsatz?</a></li>
                <li> <a href="#data_questions">An wen kann ich mich bei Fragen und Anliegen wenden?</a></li>
            </ol>
            <h4 id="data_responsibility">1. Wer ist für meine Daten verantwortlich?</h4>
            <p>Für die im Rahmen des Verfahrens erhobenen Daten ist unser jeweiliger Auftraggeber verantwortliche Stelle im Sinne des Datenschutzrechts, d.h. das Unternehmen, das Ihnen die Zugangsdaten übermittelt hat und für den wir das Verfahren durchführen.
                Wir, die Harald Ackerschott GmbH, sind technischer Dienstleister und Ansprechpartner für die Durchführung des Verfahrens und handeln gegenüber unserem Auftraggeber strikt weisungs- und zweckgebunden.</p>

            <h4 id="data_anonymity">2. Ist meine Teilnahme anonym?</h4>
            <p>Während Sie das Verfahren online bearbeiten erheben wir keine unmittelbare Angaben über Sie, mit denen wir  Sie identifizieren können, also insbesondere weder Ihren Name noch Ihre Anschrift oder E-Mail Adresse.
                Sie erhalten vom unserem Auftraggeber einen individuellen Zugangscode, mit dem Sie am Verfahren teilnehmen können.
                Wir wissen nicht, welcher Zugangscode zu welchem Teilnehmer gehört. Nur unser Auftraggeber, der Sie eingeladen hat, verfügt über diese Information und kann so die Ergebnisse des Verfahrens Ihnen persönlich zuordnen. Im Rahmen von Auftragsdatenverarbeitungsvereinbarungen (ADV) können wir im Auftrag des Auftraggebers die persönliche Zuordnung der Codes zu den Namen in der Auswertung übernehmen. In diesen Fällen teilt uns der Auftraggeber die Zuordnung mit.</p>

            <h4 id="data_raisedData">3. Welche Daten werden erhoben?</h4>
            <p>Wir erfassen Ihre Antworten auf die Fragen und Aufgaben im Zeitverlauf vom Einloggen bis zum Abschluss des Verfahrens inklusive Beginn, Ihren Eingaben zur Steuerung, dem Ende und Ihre gewählte Sprachversion.</p>

            <h4 id="data_usage">4. Wofür werden die Daten verwendet?</h4>
            <p>Wir verwenden die Daten für eine Auswertung und geben die Auswertungsergebnisse an unseren Auftraggeber weiter. Die Auswertungen beinhalten die Analyse Ihrer Entscheidungen und der von Ihnen ausgewählten Antwortalternativen nach unterschiedlichen Dimensionen und den Vergleich Ihrer Ergebnisse mit einer relevanten Norm – und Vergleichsstichproben.
                Darüber hinaus speichern wir alle Antworten in anonymer Form, d.h. ohne Zuordnung des Namens, der IP-Adresse oder des Zugangscodes. Wir nutzen diese Daten zur Evaluation und Weiterentwicklung unserer Verfahren. Sofern Erkenntnisse hieraus öffentlich gemacht werden, zum Beispiel in wissenschaftlichen Publikationen, werden diese Daten ausschließlich in aggregierter Form verwendet.
                Unsere Auftraggeber nutzen die Daten für Personalentscheidungen und - maßnahmen, etwa für Entscheidungen zur Einstellung, Beförderung, Platzierung oder Entwicklung von Mitarbeiterinnen und Mitarbeitern.</p>

            <h4 id="data_deletion">5. Wann werden meine Daten gelöscht?</h4>
            <p>Wir löschen die Daten, die auf einen bestimmten Teilnehmer zurückgeführt werden können,  in der Regel sechs Monate nach Beendigung des psychometrischen Verfahrens, soweit wir vom Auftraggeber nicht anderweitig angewiesen wurden. Für Informationen zur Datenspeicherung beim Auftraggeber selbst, kontaktieren Sie bitte diesen direkt.
                In anonymer Form können wir Daten, insbesondere die Antworten auf die Aufgaben auch länger speichern.</p>

            <h4 id="data_location">6. Wo werden meine Daten gespeichert?</h4>
            <p>Ihre Daten werden von uns ausschließlich auf Rechnern in Deutschland gespeichert und verarbeitet die nach <strong>DIN ISO 27001</strong> zertifiziert wurden.</p>

            <h4 id="data_tracking">7. Werde ich getrackt?</h4>
            <p>Wir erheben und speichern Informationen in Log Files, die Ihr Browser automatisch an uns übermittelt. Dies sind:</p>
            <ul>
                <li>Browsertyp/ Browserversion</li>
                <li>verwendetes Betriebssystem</li>
                <li>Referrer URL</li>
                <li>Hostname des zugreifenden Rechners</li>
                <li>Uhrzeit der Serveranfrage</li>
            </ul>
            <p>Diese Daten sind nicht bestimmten Personen zuordenbar. Wir verknüpfen diese Daten auch nicht mit Ihrem Zugangscode oder Ihren Antworten.
                Wir speichern in unseren Logfiles die IP-Adresse Ihres Rechners , Die IP-Adresse ist eine Nummer, die ihr Internet-Anbieter Ihrem Rechner vorrübergehend oder auch dauerhaft zuweist. Die Log-File-Daten werden von uns ausschließlich zur Sicherstellung der Funktionsfähigkeit unserer Dienste verwendet (z.B. Fehleranalyse, Gewährleistung der Systemsicherheit und Schutz vor Missbrauch). Die Logfiles werden nach 30 Tagen automatisch gelöscht.</p>

            <h4 id="data_cookies">8. Welche Cookies kommen zum Einsatz?</h4>
            <p>Wenn Sie ein Verfahren beginnen, setzt unser Server einen "Cookie" mit einer Kennzahl auf Ihrem Rechner. Ein Cookie ist eine kleine Textdatei, die auf Ihrem Rechner gespeichert wird. Der Inhalt dieser Datei wird bei jedem Aufruf einer Webseite an unseren Server übertragen. Wir nutzen den Cookie ausschließlich, um Sie als Teilnehmer über den Verlauf des Verfahrens wiederzuerkennen (sog. Session-Cookie). Sie können in Ihren Browser-Einstellungen die Speicherung von Cookies ablehnen. Eine Teilnahme am Verfahren ist dann nicht möglich.</p>

            <h4 id="data_questions">9. An wen kann ich mich bei Fragen und Anliegen wenden?</h4>
            <p>Wenn Sie wissen möchten, welche Daten wir über Sie gespeichert haben, kontaktieren Sie uns gerne: <a href="mailto:Datenschutz@ackerschott.eu">Datenschutz@ackerschott.eu</a></p>
            <p>Stand dieser Datenschutzhinweise: [05-04-2017]</p>
        </div>
    )
}