import React, { Component } from 'react';
import { connect } from 'react-redux';
import SummaryShort from "../widgets/SummaryShort";
import HomeActions from './HomeActions';
import WelcomeWidget from "./WelcomeWidget";
import Finished from "../widgets/Finished";
import {format, parseISO} from "date-fns";
import StatsWeek from "../widgets/StatsWeek";
import {de} from "date-fns/locale"
import {enGB as en} from "date-fns/locale"
import {withTranslation} from "react-i18next";

const tableMockHeader = [
  {label: "Login Code"},
  {label: "Position"}
];
const tableMockData = [
  {id: "123456789", position: "position1"},
  {id: "123456789", position: "position2"},
  {id: "123456789", position: "position3"},
  {id: "123456789", position: "position4"},
  {id: "123456789", position: "position5"},
];

class Home extends Component {
     constructor(props) {
         super(props);
     }

     filteredFinished = (finished, positions, locale) => {
         let clonedFinished = Object.assign([], finished);
         let filteredFinished = [];
         let merged = [];
         if(finished.length > 0 && positions.length > 0){
             filteredFinished = clonedFinished.sort((a,b) => a.entity.finishedAt > b.entity.finishedAt);
             let oFinished = [];
             //filteredFinished.splice(Math.max(filteredFinished.length -5), filteredFinished.length).map(item => {
             //    oFinished.push(Object.assign({}, item.ref));
             //});
             oFinished = clonedFinished;
             oFinished.map(oItem => {
                 let posName = positions.find(pItem => pItem.ref.id === oItem.positionId).ref.name;
                 merged.push({identification:oItem.identification, position: posName, finishedAt: getLocalizedDateShort(oItem.finishedAt, locale)});
             })
         }
         return merged;
     };

     filteredInwork = (inwork, positions, locale) => {
         let clonedInwork = Object.assign([], inwork);
         let filteredInwork = [];
         filteredInwork = clonedInwork.sort((a,b) => a.entity.started > b.entity.started);
         let oInwork = [];
         filteredInwork.splice(Math.max(filteredInwork.length -5), filteredInwork.length).map(item => {
             oInwork.push(Object.assign({}, item.ref));
         });
         let merged = [];
         oInwork.map(oItem => {
             let posName = positions.find(pItem => pItem.ref.id === oItem.positionId).ref.name;
             merged.push({identification:oItem.identification, position: posName, started: getLocalizedDateShort(oItem.started, locale)});
         });
         return merged;
     };

     filteredStarted = (started, positions,locale) => {
         let clonedStarted = Object.assign([], started);
         let filteredStarted = [];
         filteredStarted = clonedStarted.sort((a,b) => {
             let aDate = new Date(a);
             let bDate = new Date(b);
             return aDate>bDate ? -1 : aDate<bDate ? 1 : 0;
         });
         //let oStarted = [];
         //filteredStarted.splice(Math.max(filteredStarted.length -5), filteredStarted.length).map(item => {
         //  oStarted.push(Object.assign({}, item.ref));
         //})

         let merged = [];
         if(filteredStarted.length > 0 && filteredStarted[0].hasOwnProperty("ref")){
             filteredStarted.map(oItem => {
                 let time = parseISO(oItem.ref.started.substr(0, oItem.ref.started.length-1));
                 let posName = positions.find(pItem => pItem.ref.id === oItem.ref.positionId).ref.name;
                 merged.push({identification:oItem.ref.identification, position: posName, block: oItem.ref.block, started: getLocalizedDateShort(oItem.ref.started, locale)});
             });
         }
         return merged;
     };

     componentDidMount(){
    if(!this.props.keycloak.keycloak.hasRealmRole("ROLE_OWNER_ADMIN")){
      this.props.fetchAll();
      this.props.fetchPositions();      
    }
  }
  
  render() {
    return (
      <div className="row">
                <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12">
                    {
                        this.props.userInfo.hasOwnProperty("entity") ?
                            <WelcomeWidget userName={this.props.userInfo.entity.userName} mostRecent={this.props.userInfo.userSessions.mostRecentSessionStarted}/>
                            :
                            null
                    }
                    <StatsWeek/>
                </div>
                <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12">
                    {this.props.keycloak.keycloak.hasRealmRole("ROLE_OWNER_ADMIN") ? <div></div>
                        :
                        <div>
                            <SummaryShort i18next={this.props.i18n} key={"summary_s_1"} link="/results/inwork" tdata={this.filteredInwork(this.props.inwork, this.props.positions, this.props.i18n.language)} header={this.props.t("homeCardHeader.inwork")} theader={tableMockHeader}/>
                            <SummaryShort i18next={this.props.i18n} key={"summary_s_2"} link="/results/started" tdata={this.filteredStarted(this.props.started, this.props.positions, this.props.i18n.language)} header={this.props.t("homeCardHeader.started")} theader={tableMockHeader}/>
                            <Finished link="/results/finished"/>
                        </div>
                    }
                </div>
      </div>
    )
  }
}

const getLocalizedDateShort = (date, locale) => {
     if(date != null && date !== "null" && date !== "undefined"){
         switch(locale){
             case "de":
                 return format(parseISO(date), "dd.MM.yyyy HH:mm:ss", {locale: de});
             case "en":
                 return format(parseISO(date), "MM/dd/yyyy hh:mm:ss", {locale: en});
             default:
                 return format(parseISO(date), "MM/dd/yyyy hh:mm:ss", {locale: en});
         }
     }else{
         return  locale === "de" ? "noch keine Aufgabe bearbeitet" : "not started a task yet";
     }
};

const mapStateToProps = (state) => ({
  finished: state.home.finished.data,
  inwork: state.home.inwork.data,
  started: state.home.started.data,
  keycloak: state.app.keycloak,
  userInfo: state.app.portal.userInfo,
  i18next: state.locale.i18next,
    positions: state.home.positions
});

const mapDispatchToProps = (dispatch) => ({
  fetchAll: () => dispatch(HomeActions.fetchAll()),
  fetchPositions: () => dispatch(HomeActions.fetchPositions())
});

const homeTrans = withTranslation(["common"])(Home);
export default connect(mapStateToProps, mapDispatchToProps)(homeTrans);
