import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch, AppThunk} from "../../../../store";
import {
    Attendance,
    AttendanceCreation,
    AttendanceCreationProperties,
    AttendanceCreationResult
} from "../../../../types";
import {newAttendances} from "../api/attendanceApi";
import {changeCreationStatus, creationFinishedStatus} from "./creationStatusSlice";

const initialState:Attendance[] = []

const attendanceCreationSlice = createSlice({
    name: "creationProperties",
    initialState,
    reducers: {
        addAttendances(state, action:PayloadAction<Attendance[]>){
            return action.payload
        }
    }
})

export const createAttendances = (createAttendance: AttendanceCreation):AppThunk => async (dispatch: AppDispatch) => {
    let attendances: Promise<AttendanceCreationResult> = newAttendances(createAttendance)
    attendances.then( att => {
            dispatch(attendanceCreationSlice.actions.addAttendances(att.payload))
            dispatch(creationFinishedStatus(att))
    })


}

export default attendanceCreationSlice.reducer;