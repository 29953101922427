import * as React from "react";
import ListFormSchema from "../../schema/ListFormSchema";
import {FormContext} from "../../module/results/view/List";
import ListFormContext from "../../context/ListFormContext";
import FadeInDown from "../style/FadeInDown";
import Select from "react-select";

class ExtSelect extends React.Component {
    static contextType = ListFormContext
    constructor(props) {
        super(props);
        this.validate = this.validate.bind(this);
        this.state = {valueValid: true, validate: false, value:""}
    }

    validate(e){
        let valueValid = false;
        console.log(this.context)
        let item = this.context.schema.components.find(item => item.name.toString() === this.props.name.toString());
        if(item){
            valueValid = item.validation(e);
        }
        if(this.props.customValue){
            this.setState({valueValid: this.props.customValue, value:e});
        }else{
            this.setState({valueValid: valueValid, value:e});
        }
        this.props.onChange(e);
    }

    componentDidMount() {
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props !== prevProps){
            this.setState({
                validate: this.state.valueValid && this.props.validation.elements.includes(this.props.name) > 0 && this.props.validation.validate
            })
        }
    }

    render(){
        return(
            <div className="form-group">
                <label className="form-check-label" htmlFor={this.props.name}>{this.props.label}</label>
                <Select name={this.props.name}
                        styles={{
                            // Fixes the overlapping problem of the component
                            menu: provided => ({ ...provided, zIndex: 9999 })
                        }}
                           value={this.props.value}
                           onChange={(e) => this.validate(e)}
                           options={this.props.options}
                           isClearable={this.props.isClearable}
                           placeholder={this.props.placeholder}
                />
                <div>
                    {
                        this.state.validate ?
                            <div className="animate__animated animate__bounceIn" style={{color:"red", fontSize:"0.8rem"}}>
                                {
                                    this.props.lang.toUpperCase() === "DE" ?
                                        this.context.schema.components.find(item => item.name===this.props.name).message.de
                                        :
                                        this.context.schema.components.find(item => item.name===this.props.name).message.en
                                }
                            </div>
                            :
                            null
                    }
                </div>
            </div>
        )
    }
}

export default ExtSelect;