import {combineReducers} from "redux";
import InterviewManagementConst from "../const/InterviewManagementConst";

const selectedCustomer = (state={customer:null}, action=null) => {
    switch(action.type){
        case InterviewManagementConst.SELECT_CUSTOMER_ADMIN_INTERVIEW:
            return Object.assign({}, state, {customerId: action.payload.customerId});
        default:
            return state;
    }
};

const interviewManagementDTO = (state=[], action=null) => {
    switch(action.type){
        case InterviewManagementConst.FETCH_INTERVIEW_DTO:
            return Object.assign([], action.payload.interviewManagementDTOs);
        default:
            return state;
    }
};

const selectedYear = (state={year: null}, action=null) => {
    switch(action.type){
        case InterviewManagementConst.SELECT_YEAR_INTERVIEW_MANAGEMENT:
            return Object.assign({}, state, {year: action.payload.year});
        default:
            return state;
    }
};

const selectedMonth = (state={month: null}, action=null) => {
    switch(action.type){
        case InterviewManagementConst.SELECT_MONTH_INTERVIEW_MANAGEMENT:
            return Object.assign({}, state, {month: action.payload.month});
        default:
            return state;
    }
};

const interviewManagement = combineReducers({selectedCustomer, interviewManagementDTO, selectedMonth, selectedYear});
export  {interviewManagement};