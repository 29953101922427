import {
  AbciDocumentProfileShort,
  AbciProfileDocument,
  useAbciProfileDocument
} from "../../../state/AbciProfileDocument";
import React from "react";
import { Box } from "@mui/material";
import URLConstants from "../../../../../api/URLConstants";
import Typography from "@mui/material/Typography";
import {RProfile} from "../../../../../types";

interface AbciProfileDocumentViewProps {
    document: AbciProfileDocument
    profile: AbciDocumentProfileShort
}

export const AbciProfileDocumentView = (props:AbciProfileDocumentViewProps) => {
  return (
    <Box>
      <table className="abci-profile-document-table">
        <thead>
        <tr>
          <th className="abci-profile-document-table-header">
            <SiteHeader/>
          </th>
        </tr>
        </thead>
      <tbody>
      <tr>
        <td>
          <Page_1 document={props.document} profile={props.profile}/>
          <Page_2 document={props.document} profile={props.profile}/>
        </td>
      </tr>
      </tbody>
        <tfoot className="abci-profile-document-table-footer">
        <tr>
          <td className="abci-profile-document-table-footer">
            <Footer document={props.document} profile={props.profile}/>
          </td>
        </tr>
        </tfoot>
      </table>
    </Box>
  )
}

const SiteHeader = () => {
  return (
    <Box display={"flex"} flexDirection={"row"} sx={{height:"26px !important", width:"100% !important"}}>
      <Box flexGrow={1} borderBottom={"2px solid #0079c2"}>

      </Box>
      <Box color={"#0079c2"} sx={{marginLeft: "5px", marginRight: "5px", fontSize:"24px", padding: 0, bottom:"0", display:"table-cell", verticalAlign:"bottom", fontFamily: "Afacad", fontWeight: "400"}}>
        ackerschott and associates
      </Box>
      <Box width={"14%"} borderBottom={"2px solid #0079c2"}>

      </Box>
    </Box>
  )
}

const SiteFooter = (props:AbciProfileDocumentViewProps):JSX.Element => {
  return (
    <Box>

    </Box>
  )
}

const Header = (props:AbciProfileDocumentViewProps):JSX.Element => {
  return (
    <Box display={"flex"} flexDirection={"column"} sx={{marginTop:"0.3rem", marginBottom:"1rem", gap:"1rem"}}>
      <Typography variant={"h5"} style={{paddingLeft:"20px"}}><strong>{props.document.text.documentHeader.mainHeader}</strong></Typography>
      <Typography variant={"h6"} sx={{backgroundColor:"#EEEEEE"}} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} style={{paddingLeft:"20px"}}>{`${props.document.text.documentHeader.subHeader} - ${props?.profile?.name}`}<span style={{paddingRight:"20px"}}><img src={`/img/abcilogo.svg`} height={30}/></span></Typography>
    </Box>
  )
}

const Page_1_Content = (props:AbciProfileDocumentViewProps):JSX.Element => {
  return(
    <Box display={"flex"} flexDirection={"row"} width={"100%"} sx={{marginBottom:"1rem"}}>
      <Box width={"100%"} sx={{paddingLeft: "20px", paddingRight:"20px"}}>
        {
          props.document.text.dimensionDescription.map((dim, idx) =>
          {

            if(dim.dimension === "GS"){
              return <Box className="dimension-description-iv-first" display={"flex"} lineHeight={1} flexDirection={"column"} justifyItems={"flex-center"} alignItems={"end"}>
                <Typography variant={"body2"}><strong>{dim.header}</strong></Typography>
                <Typography variant={"body2"} textAlign={"right"} sx={{whiteSpace:"pre-wrap"}}>{dim.paragraph}</Typography>
              </Box>
            }

            if(dim.dimension === "GP" || dim.dimension === "C" || dim.dimension === "K"){
              return <Box className="dimension-description-iv-all" display={"flex"} lineHeight={1} flexDirection={"column"} justifyItems={"flex-center"} alignItems={"end"}>
                <Typography variant={"body2"}><strong>{dim.header}</strong></Typography>
                <Typography variant={"body2"} textAlign={"right"} sx={{whiteSpace:"pre-wrap"}}>{dim.paragraph}</Typography>
              </Box>
            }
            if(dim.dimension === "M"){
              return <Box className="dimension-description-vp-first" display={"flex"} lineHeight={1} flexDirection={"column"} justifyItems={"flex-center"} alignItems={"end"}>
                <Typography variant={"body2"}><strong>{dim.header}</strong></Typography>
                <Typography variant={"body2"} textAlign={"right"} sx={{whiteSpace:"pre-wrap"}}>{dim.paragraph}</Typography>
              </Box>
            }

            if(dim.dimension === "N" || dim.dimension === "S" || dim.dimension === "V" || dim.dimension === "DTM" || dim.dimension ==="DTN" || dim.dimension === "DTP"){
              return <Box className="dimension-description-vp-all" display={"flex"} lineHeight={1} flexDirection={"column"} justifyItems={"flex-center"} alignItems={"end"}>
                <Typography variant={"body2"}><strong>{dim.header}</strong></Typography>
                <Typography variant={"body2"} textAlign={"right"} sx={{whiteSpace:"pre-wrap"}}>{dim.paragraph}</Typography>
              </Box>
            }
          })
        }
      </Box>
      <Box>
        <img src={props.document.imageBase64} id={"graphImage"}/>
      </Box>
    </Box>
  )
}

const Legend = (props:AbciProfileDocumentViewProps) => {
  return (
    <Box display={"flex"} flexDirection={"row"} sx={{backgroundColor:"#EFEFEF", borderRadius:"5px", padding:"5px", marginTop: "0.5rem"}}>
      <Box sx={{paddingRight:"30px"}}>
        <Box display={"flex"} flexDirection={"row"}>
          <div style={{width:"16px !important", minWidth:"16px", height:"16px", marginTop:"4px", borderRadius:"5px", backgroundColor:"#139547"}}></div>&nbsp;&nbsp;<Typography variant={"body2"}>{props.document.text.legendDescription.green}</Typography>
        </Box>
        <Box display={"flex"} flexDirection={"row"} sx={{marginTop:"0.5rem"}}>
          <div style={{width:"16px", height:"16px", marginTop:"4px", borderRadius:"5px", backgroundColor:"#FFC200FF"}}></div>&nbsp;&nbsp;<Typography variant={"body2"}>{props.document.text.legendDescription.yellow}</Typography>
        </Box>
        <Box display={"flex"} flexDirection={"row"} sx={{marginTop:"0.5rem"}}>
          <div style={{width:"16px", height:"16px", marginTop:"4px", borderRadius:"5px", backgroundColor:"#EF134BFF"}}></div>&nbsp;&nbsp;
          <Typography variant={"body2"}>{props.document.text.legendDescription.red}</Typography>
        </Box>
        <Box display={"flex"} flexDirection={"row"} sx={{marginTop:"0.5rem"}}>
          <img src={`/img/ex_red.svg`} height={16} style={{marginTop:"4px"}}/>&nbsp;&nbsp;<Typography variant={"body2"}>{props.document.text.legendDescription.exclamation}</Typography>
        </Box>
        <Box display={"flex"} flexDirection={"row"} sx={{marginTop:"0.5rem"}}>
          <img src={`/img/cut.svg`} height={16} style={{marginTop:"4px"}}/>&nbsp;&nbsp;<Typography variant={"body2"}>{props.document.text.legendDescription.cut}</Typography>
        </Box>
      </Box>
    </Box>
  )
}

const Footer = (props:AbciProfileDocumentViewProps) => {
  return (
    <Box width={"100%"} sx={{borderTop: "2px solid #0079c2", color:"#0079c2", fontSize:"10px"}} display={"flex"} flexDirection={"column"} alignItems={"center"}>
      <Box>
        {`${props.document.text.address.company} • ${props.document.text.address.street} • ${props.document.text.address.zip} ${props.document.text.address.city}, ${props.document.text.address.country}`}
      </Box>
      <Box>
        {`${props.document.text.address.telephone} • `}<a href={`mailto:${props.document.text.address.email}`}>{props.document.text.address.email}</a>
      </Box>
      <Box>
        <a href={"https://www.abci.de"}>{props.document.text.address.homepage}</a>
      </Box>
    </Box>
  )
}

const Page_1 = (props:AbciProfileDocumentViewProps) => {
  const [variant] = useAbciProfileDocument((state) => [state.variant])
  return (
    <Box className="abci-profile-document-table-page" display={"flex"} flexDirection={"column"}>
      <Header document={props.document} profile={props.profile}/>
      <Page_1_Content document={props.document} profile={props.profile}/>
      {
        variant !== undefined && variant !== "DEFAULT_DT" ?
          <Legend document={props.document} profile={props.profile}/>
          :
          null
      }
    </Box>
  )
}

const Page_2 = (props:AbciProfileDocumentViewProps) => {
  const [variant] = useAbciProfileDocument((state) => [state.variant])
  return (
    <Box className="abci-profile-document-table-page" display={"flex"} flexDirection={"column"}>
      {
        variant !== undefined && variant === "DEFAULT_DT" ?
          <Legend document={props.document} profile={props.profile}/>
          :
          null
      }
      <br/>
      <Box>
        <Typography variant={"h6"}><strong>{props.document.text.mainSection.entry}</strong></Typography>
        <br/>
      </Box>
      {
        props.document.text.mainSection.descriptions.map(disc =>
          <Box>
            <Typography variant={"body1"}><strong>{disc.header}</strong></Typography>
            <Typography variant={"body1"} sx={{whiteSpace:"pre-wrap"}}>{disc.paragraph}</Typography>
            <br/>
          </Box>
        )
      }
    </Box>
  )
}