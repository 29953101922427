/**
 * Created by stephanpudras on 10.12.18.
 */

import React from 'react';
import {connect} from 'react-redux';
import AdminActions from "../AdminActions";
import ReportType from "../../../const/ReportType";
import PositionManagementActions from "../actions/PositionManagementActions";
import Select from "react-select";
import ReactTable from "react-table-6/react-table";

const positions = (pAll, pPortaluser) => {
    let filteredPositions = [];
    pAll.map((item) => {
        filteredPositions.push({view: {id: null, name: null}, addable:{id: item.entity.id, name: item.entity.name}, removeable: {id:null, name:null}});
    });

    filteredPositions.map((item) => {
        pPortaluser.map((pItem) => {
            if(item.addable.id === pItem.jobId){
                item.addable = {id:null, name:null};
                item.removeable = {id: pItem.jobId, name:pItem.jobName};
                item.view = {id: pItem.jobId, name: pItem.jobName};
            }
        })
    });

    return filteredPositions;
};

const mapStateToProps = (state) => {
    return {
        customer: state.admin.positionManagement.customer.customer,
        portaluser: state.admin.positionManagement.portaluser.portaluser,
        positions: positions(state.admin.positionManagement.positions.positions, state.admin.positionManagement.positionsByPortaluser.positions),
        selectedCustomer: state.admin.selectedCustomer.selectedCustomer
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCustomer: () => dispatch(PositionManagementActions.fetchCustomer()),
        selectCustomer: (customer) => dispatch(PositionManagementActions.selectCustomer(customer)),
        selectPortaluser: (portaluser) => dispatch(PositionManagementActions.selectPortaluser(portaluser)),
        addPosition: (jobId) => dispatch(PositionManagementActions.addPosition(jobId)),
        removePosition: (jobId) => dispatch(PositionManagementActions.removePosition(jobId)),
    }
};

class PositionManagement extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    componentDidMount(){
        this.props.fetchCustomer();
    }

    tableHeaders = () => {
        return [
            {
                Header: "Id",
                accessor: "view.id",
            },
            {
                Header: "Name",
                accessor: "view.name"
            },
            {
                Header: "",
                accessor: "delete",
                Cell: row => {
                    if(row.original.removeable !== null && row.original.removeable.id !== null)
                        return <button className="btn btn-danger btn-sm" onClick={(e) => this.props.removePosition(row.original.removeable.id)}>{"- [ " + row.original.removeable.name + " ]"}</button>
                    else
                        return null;
                }
            },
            {
                Header: "",
                accessor: "add",
                Cell: row => {
                    if(row.original.addable !== null && row.original.addable.id !== null)
                        return <button className="btn btn-info btn-sm" onClick={(e) => this.props.addPosition(row.original.addable.id)}>{"+ [ " + row.original.addable.name + " ]"}</button>
                    else
                        return null;
                }
            }
        ]
    };

    render() {
        return (

            <div>
                <div className="row">
                    <div className="col-12 form-group">
                        <label>Kunde:</label>

                        <Select
                            placeholder={"Kunde"}
                            value={this.props.selectedCustomer}
                            onChange={(e) => {e !== null && typeof e !== "undefined" ? this.props.selectCustomer(e) : this.props.selectCustomer(null)}}
                            getOptionLabel={(opt) => opt.entity.name}
                            getOptionValue={(opt) => opt.entity.id}
                            options={this.props.customer}
                            searchable={true}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 form-group">
                        <label>Portaluser:</label>
                        <Select
                            placeholder={"Kunde"}
                            value={this.props.selectedPortaluser}
                            onChange={(e) => {e !== null && typeof e !== "undefined" ? this.props.selectPortaluser(e) : this.props.selectPortaluser(null)}}
                            getOptionLabel={(opt) => opt.userName}
                            getOptionValue={(opt) => opt.id}
                            options={this.props.portaluser}
                            searchable={true}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <ReactTable
                            columns={this.tableHeaders()}
                            data={this.props.positions}
                            defaultPageSize={10}
                            keyField='identification'
                            className="-striped -highlight"
                            previousText={"Zurück"}
                            nextText={'Vorwärts'}
                            loadingText={'Lade...'}
                            noDataText={"Keine Daten gefunden..."}
                            pageText={'Seite'}
                            ofText={'von'}
                            rowsText={'Zeilen'}
                            filterable
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PositionManagement)