import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AttendanceCreationResult, AttendanceCreationStatus, Customer} from "../../../types";
import {AppDispatch, AppThunk} from "../../../store";

const initialState:Customer = {
    entity:undefined,
    ref:undefined
}

const selectedCustomerSlice = createSlice({
    name: "selectedCustomer",
    initialState,
    reducers: {
        changeStatus(state, action:PayloadAction<Customer>){
            return action.payload
        }
    }
})

export const changeCustomer = (customer: Customer):AppThunk => async(dispatch: AppDispatch) => {
    dispatch(selectedCustomerSlice.actions.changeStatus(customer))
}

export default selectedCustomerSlice.reducer