import {Timespan} from "../../../const/Timespan";
import {combineReducers} from "redux";
import ListConst from "../const/ListConst";
import ResultsConst from "../const/ResultsConst";

const downloadListState = {idle: true, pending:false, ready: false, token: null, exportFormat: null, interval: null, files:[], zip:false};

const filter = (state={timespan: null, profile:null, position: "", file:null, sort: null, locale: null, testvariant: null, format:{value:"PDF", label: "PDF"}}, action=null) => {
    switch(action.type) {
        case ListConst.SET_RESULTS_LIST_FILTER_TIMESPAN:
            return Object.assign({}, state, {
                timespan: action.payload
            });
        case ListConst.SET_RESULTS_LIST_FILTER_PROFILE:
            return Object.assign({},state, {
                profile: action.payload
            });
        case ListConst.SET_RESULTS_LIST_FILTER_POSITION:
            return Object.assign({},state, {
                position: action.payload
            });
        case ListConst.SET_RESULTS_LIST_FILTER_FILE:
            return Object.assign({}, state, {
                file: action.payload
            });
        case ListConst.SET_RESULTS_LIST_FILTER_SORT:
            return Object.assign({}, state, {
                sort: action.payload
            })
        case ListConst.SET_RESULTS_LIST_FILTER_LOCALE:
            return Object.assign({}, state, {
                locale: action.payload
            })
        case ListConst.SET_RESULTS_LIST_FILTER_TESTVARIANT:
            return Object.assign({}, state, {
                testvariant: action.payload
            })
        case ListConst.SET_RESULTS_LIST_FILTER_FORMAT:
            return Object.assign({}, state, {
                format: action.payload
            })
        default:
            return state;
    }
};

const attendances = (state=[], action=null) => {
    switch(action.type){
        case ListConst.FETCH_RESULTS_LIST_ATTENDANCES:
            return Object.assign([], action.payload.finished);
        case ListConst.SET_RESULTS_LIST_FILTER_TIMESPAN_RESET:
            return []
        default:
            return state;
    }
};

const selected = (state={selection:[], selectAll:false}, action=null) => {
    switch(action.type){
        case ListConst.SET_RESULTS_LIST_ROW_SELECTED:
            let newState = [];
            let keyIndex = state.selection.findIndex(item => item === action.payload.key);
            newState = keyIndex >= 0 ? [...state.selection.slice(0,keyIndex), ...state.selection.slice(keyIndex + 1)] : [...state.selection, action.payload.key];
            return Object.assign({}, state, {selection: newState});
        case ListConst.SET_RESULTS_LIST_ALL_ROWS_SELECTED:
            return Object.assign({}, state, {selection: action.payload.toggleAll === false ? [] : action.payload.keys, selectAll: action.payload.toggleAll});
        case ListConst.FETCH_RESULTS_LIST_ATTENDANCES:
            return Object.assign({}, state, {selection: [], selectAll: false});
        case ListConst.SET_RESULTS_LIST_FILTER_TESTVARIANT:
            return Object.assign({}, state, {selection: [], selectAll: false});
        case ListConst.SET_RESULTS_LIST_FILTER_TIMESPAN_RESET:
            return Object.assign({}, state, {selection:[], selectAll: false})
        default:
            return state;
    }
};

const tests = (state=[], action=null) => {
    switch(action.type){
        case ListConst.FETCH_RESULTS_LIST_ATTENDANCES:
            let tests = [];
            action.payload.finished.map(item => {
                if(tests.indexOf(item.ref.testname) === -1){
                    tests.push(item.ref.testname)
                }
            })
            return Object.assign([], tests);
        default:
            return state;
    }
}

const downloadList = (state=downloadListState, action = null) => {
    switch (action.type) {
        case ListConst.REQUEST_LIST:
            return Object.assign({}, state, {idle: false, pending:true, ready: false, interval: action.payload.interval, exportFormat: "PDF", zip:false});
        case ListConst.REQUEST_LIST_ZIP:
            return Object.assign({}, state, {idle: false, pending:true, ready: false, interval: action.payload.interval, exportFormat: "PDF", zip:true});
        case ListConst.POST_RESULTS_LIST_REQUEST:
            return Object.assign({}, state, {token: action.payload.token});
        case ListConst.POST_RESULTS_LIST_REQUEST_ZIP:
            return Object.assign({}, state, {token: action.payload.token});
        case ListConst.FETCH_LIST:
            if (action.payload.ready === true || action.payload.ready === "true") {
                clearInterval(state.interval);
                return Object.assign({}, state, {
                    files: action.payload.exportFiles,
                    ready: true,
                    pending: false,
                })
            }
            if (action.payload.queued === true || action.payload.queued === "true") {
                return Object.assign({}, state, {token: action.payload.token})
            }
            return state;
        case ListConst.FETCH_LIST_ZIP:
            if (action.payload.ready === true || action.payload.ready === "true") {
                clearInterval(state.interval);
                return Object.assign({}, state, {
                    files: action.payload.exportFile,
                    ready: true,
                    pending: false,
                })
            }
            if (action.payload.queued === true || action.payload.queued === "true") {
                return Object.assign({}, state, {token: action.payload.token})
            }
            return state;
        case ListConst.SET_RESULTS_LIST_FILTER_TIMESPAN_RESET:
            return downloadListState;
        case ListConst.SET_RESULTS_LIST_FILTER_TIMESPAN:
            return downloadListState;
        case ListConst.SET_RESULTS_LIST_FILTER_TESTVARIANT:
            return downloadListState;
        case ListConst.SET_RESULTS_LIST_ROW_SELECTED:
            return downloadListState;
        case ListConst.SET_RESULTS_LIST_ALL_ROWS_SELECTED:
            return downloadListState;
        case ListConst.SET_RESULTS_LIST_FILTER_LOCALE:
            return downloadListState;
        case ListConst.SET_RESULTS_LIST_FILTER_SORT:
            return downloadListState;
        case ListConst.SET_RESULTS_LIST_FILTER_FILE:
            return downloadListState;
        case ListConst.SET_RESULTS_LIST_FILTER_POSITION:
            return downloadListState;
        case ListConst.SET_RESULTS_LIST_FILTER_PROFILE:
            return downloadListState;
        default:
            return state;
    }
}

const listExpanded = (state={}, action=null) => {
    switch(action.type){
        case ListConst.SET_RESULTS_LIST_EXPANDED:
            return Object.assign({}, state, action.payload.newExpanded);
        default:
            return state;
    }
};

const listFormValidation = (state={validate:false, elements:[]}, action=null) => {
    switch(action.type){
        case ListConst.SET_RESULTS_LIST_VALIDATE:
            return Object.assign({}, state, {
                validate: action.payload.validate,
                elements: action.payload.elements
            })
        default:
            return state;
    }
}

const list = combineReducers({filter, attendances, selected, tests, downloadList, listExpanded,listFormValidation});
export {list};