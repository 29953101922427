/**
 * Created by stephanpudras on 11.10.18.
 */

import React from 'react';
import {connect} from 'react-redux';
import {format, parseISO} from "date-fns";
import {de} from "date-fns/locale"
import {enGB as en} from  "date-fns/locale"
import {withTranslation} from "react-i18next";

const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = (dispatch) => {
    return {}
};

class WelcomeWidget extends React.Component {
    getLocale = () => {
        switch(this.props.i18n.language){
            case "de":
                if(this.props.mostRecent && this.props.mostRecent !== undefined) {
                    return format(parseISO(this.props.mostRecent), "dd.MM.yyyy HH:mm", {locale: de});
                }else{
                    return "bisher noch nicht eingeloggt"
                }
            case "en":
                if(this.props.mostRecent && this.props.mostRecent !== undefined){
                    return format(parseISO(this.props.mostRecent), "MM/dd/yyyy hh:mm", {locale: en});
                }else{
                    return "not logged in until now"
                }

        }
    };

    render() {
        return (
            <div className="form-group">
                <div className="card bg-info text-white">
                    <div className="card-header">
                        {this.props.t("common:home.welcome.header") + ","}<strong>&nbsp;{this.props.userName}</strong>!
                    </div>
                    <div className="card-body">
                        <p>{this.props.t("common:home.welcome.innerText")}</p>
                        <p><strong>{this.props.t("common:home.welcome.lastlogin") + ":"}</strong>&nbsp;{this.getLocale()}</p>
                    </div>
                </div>
            </div>
        )
    }
}
const wWelcomeWidgetTrans = withTranslation(["common"])(WelcomeWidget);
export default connect(mapStateToProps, mapDispatchToProps)(wWelcomeWidgetTrans)