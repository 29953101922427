import React from 'react';
import {Link, NavLink, Route, withRouter} from "react-router-dom";
import 'rc-tooltip/assets/bootstrap_white.css';
import ATooltip from "./ATooltip";
import URLConstants from "../api/URLConstants";
import Select from "react-select";
import {withTranslation} from "react-i18next";


const customStyles = {
    option: (provided, state) => ({
        ...provided, color: "black"
    }),
    control: (provided, state) => (
        { ...provided, color: '#ffffff', backgroundColor: '#0079c2', borderRadius: "0", borderColor: state.isFocused ? '#0079c2' : '#0079c2',boxShadow: "0",
            '&:hover': {
                borderColor: '#0079c2'
            }
        }
    ),
    container: (provided, state) => (
        { ...provided, color: '#ffffff', backgroundColor: '#0079c2', borderRadius: "0", borderColor: state.isFocused ? '#0079c2' : '#0079c2',boxShadow: "0",
            '&:hover': {
                borderColor: '#0079c2'
            }
        }
    ),
    valueContainer: (provided, state) => (
        { ...provided, color: '#ffffff', backgroundColor: '#0079c2', borderRadius: "0", borderColor: state.isFocused ? '#0079c2' : '#0079c2',boxShadow: "0",
            '&:hover': {
                borderColor: '#0079c2'
            }
        }
    ),
    input: (provided, state) => (
        { ...provided, color: '#ffffff', backgroundColor: '#0079c2', borderRadius: "0", borderColor: state.isFocused ? '#0079c2' : '#0079c2',boxShadow: "0",
            '&:hover': {
                borderColor: '#0079c2'
            }
        }
    ),
    singleValue: (provided, state) => (
        { ...provided, color: '#ffffff', backgroundColor: '#0079c2', borderRadius: "0", borderColor: state.isFocused ? '#0079c2' : '#0079c2',boxShadow: "0",
            '&:hover': {
                borderColor: '#0079c2'
            }
        }
    ),
    placeholder: (provided, state) => (
        { ...provided, color: '#ffffff', backgroundColor: '#0079c2', borderRadius: "0", borderColor: state.isFocused ? '#0079c2' : '#0079c2',boxShadow: "0",
            '&:hover': {
                borderColor: '#0079c2'
            }
        }
    ),
    dropdownIndicator: (provided, state) => (
        { ...provided, color: '#ffffff', backgroundColor: '#0079c2', borderRadius: "0", borderColor: state.isFocused ? '#0079c2' : '#0079c2',boxShadow: "0",
            '&:hover': {
                borderColor: '#0079c2'
            }
        }
    ),
};

class Sidebar extends React.Component {
  constructor(props){
    super(props);
  }


    changeLanguage = (lng) => {
        this.props.i18n.changeLanguage(lng);
        this.props.loadLocale(this.props.i18n);
    };

    getLocaleComponent = () => {
        switch(this.props.i18n.language){
            case "de":
                return {value:"de", label:<div><i className="flag-icon flag-icon-de"/> {this.props.t("locale.long.de")}</div>};
            case "en":
                return {value:"en", label:<div><i className="flag-icon flag-icon-gb"/> {this.props.t("locale.long.en")}</div>};
            default:
                return {value:"en", label:<div><i className="flag-icon flag-icon-gb"/> {this.props.t("locale.long.en")}</div>};
        }
    };
  //href="http://localhost:8080/auth/realms/abci/protocol/openid-connect/logout?redirect_uri=http://localhost:8081"
  render(){
    return(
      <div className="d-flex flex-column">
        <div className="abcilogo">
          <img src="/img/abcilogo.svg" height={34}/>
        </div>

                  <div className="user_sb d-flex flex-grow-1">
                          <div className="user_sb_btn flex-fill d-flex justify-content-center">
                              <NavLink to="/home" className="icon-home flex-fill"
                                       title={this.props.t("tooltip.sidebar.home")}> </NavLink>
                          </div>
                      <div className="user_sb_btn flex-fill d-flex justify-content-center" title={this.props.t("tooltip.sidebar.userdata")}>
                          <a onClick={() => this.props.kinstance.accountManagement()}
                             className="icon-user flex-fill"></a>
                      </div>
                      <div className="user_sb_btn flex-fill d-flex justify-content-center" title={this.props.t("tooltip.sidebar.logout")}>
                          <a onClick={() => this.props.keycloak.keycloak.logout({redirectUri: URLConstants.getRedirectURI()})}
                             className="icon-log-out flex-fill"> </a>
                      </div>
                  </div>
          <div className="user_locale_sb_btn">
              <span>
                  <Select className=""
                          placeholder={<div><span className="icon-globe"> </span> Sprache</div>}
                          options={[{value: "de", label:<div><i className="flag-icon flag-icon-de"/> {this.props.t("locale.long.de")}</div>}, {value:"en", label: <div><i className="flag-icon flag-icon-gb"/> {this.props.t("locale.long.en")}</div>}]}
                          styles={customStyles}
                          onChange={(e) => this.changeLanguage(e.value)}
                          value={this.getLocaleComponent()}
                  />
              </span>
          </div>
                  <div className="user_sb_info">
              {
                  this.props.keycloak != null && this.props.keycloak.hasOwnProperty("userProfile") && this.props.keycloak.userProfile !== undefined?
                  <div>{this.props.t("labels.user") + ": "} {this.props.keycloak.userProfile.username}</div>
                  :
                  null
              }
              {
                  this.props.portal.userInfo && typeof this.props.portal.userInfo.customer !== "undefined" && this.props.portal.userInfo.customer !== null ? <div>{this.props.t("labels.customer") + ": "}{this.props.portal.userInfo.customer.name}</div> : null
              }

              </div>

        <div>
          <ul style={{paddingLeft:"0"}} className="sidebar">
            {
              this.props.sidebar.map((mainTopic, index) => {
                if(this.props.portal.userInfo != null){
                  //if (mainTopic.role != null) console.log("hasRealmRole " + mainTopic.role + ":" + this.props.keycloak.keycloak.hasRealmRole(mainTopic.role));
                  //if (mainTopic.validate != null) console.log("validate of " + this.props.portal.userInfo.recruiterId  + " is: " + mainTopic.validate(this.props.portal.userInfo));
                  if ((mainTopic.role == null || this.props.keycloak.keycloak.hasRealmRole(mainTopic.role)) &&
                      (mainTopic.validate == null || mainTopic.validate(this.props.portal.userInfo)))
                    return <SidebarElement t={this.props.t} key={"sb_topic_" + index} item={mainTopic} routing={this.props} sidebarState={this.props.sidebarState} handler={this.props.handler} topicIndex={index}/>
                  else
                    return null
                }
                else
                    return null
              })
            }
          </ul>
          <div>
              <a href={"mailto:team@ackerschott.eu?subject=" + this.props.t("sidebar.bBoxSubject")} title={this.props.t("sidebar.bBoxTooltip")}><img src="/img/betabox_7.svg" width="100%"/></a>
          </div>
        </div>
      </div>
    )
  }
}

class SidebarElement extends React.Component {
    constructor(props) {
        super(props);
        this.checkIfOpen = this.checkIfOpen.bind(this);
    }

    checkIfOpen(){
        let urlString = this.props.routing.location.pathname;
        if(urlString.startsWith("/"))
            urlString = urlString.substr(1,urlString.length);
        let pathTokens = urlString.split("/");
        return pathTokens
    }

    render(){
        let tokens = this.checkIfOpen();
    return(

      <li className="sidebar-topic">
          <span className="sidebar-topic-item" onClick={(e) => this.props.handler(this.props.item.topic, this.props.item.link)}><NavLink to={this.props.item.link} activeClassName="sidebar-subitem-active">{this.props.t(this.props.item.topic)}</NavLink></span>
        {
          this.props.sidebarState.active != null || "/" + tokens[0] === this.props.item.link ?
          <ul className={this.props.sidebarState.active === this.props.item.topic || "/" + tokens[0] === this.props.item.link ? "sidebar-item open" : "sidebar-item"}>
            {
              this.props.item.subitems ? this.props.item.subitems.map((subitem, idx) => {
                return <li key={"sb_sub_" + this.props.topicIndex + "_" + idx}><span className="sidebar-subitem"><NavLink to={this.props.item.link + subitem.link}  activeClassName="sidebar-subitem-active">{this.props.t(subitem.topic)}</NavLink></span></li>
              })
                  :
                  null
            }
        </ul>
        :
        null
        }
      </li>
    )
  }
}
const sidebarTrans = withTranslation(["common"])(Sidebar);
export default sidebarTrans;
