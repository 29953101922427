/**
 * Created by stephanpudras on 05.10.18.
 */

import React from 'react';
import {connect} from 'react-redux';
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import ResultsActions from "./actions/ResultsActions";
import {withNamespaces, withTranslation} from "react-i18next";

const mapStateToProps = (state, props) => {
    return {
        ranges: props.percentRanges
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchRange: (identification) => dispatch(ResultsActions.fetchRange(identification))
    }
};

const data = [
    {name: 'Gspeed', pv: 80},
    {name: 'Gpower', iv: 67},
    {name: 'C', iv: 67},
    {name: 'K', iv: 67},
    {name: 'M', iv: 67},
    {name: 'N', iv: 67},
    {name: 'S', iv: 67},
    {name: 'V', iv: 67},
];

class ResultChartOverview extends React.Component {
    constructor(props) {
        super(props);
        this.range = this.range.bind(this);
    }

    range(){
        let obj = {};
        if(this.props.ranges.length > 0){
            let range = null;
            range = this.props.ranges.find(r => r.loginCode === this.props.identification);
            if(range !== null && typeof range !== "undefined" && range.hasOwnProperty("range_gs")) {
                let gs = {};
                let gp = {};
                let c = {};
                let k = {};
                let m = {};
                let n = {};
                let s = {};
                let v = {};
                gs["name"] = this.props.t("common:dimensions.med.gs");
                gp["name"] = this.props.t("common:dimensions.med.gp");
                c["name"] = this.props.t("common:dimensions.med.c");
                k["name"] = this.props.t("common:dimensions.med.k");
                m["name"] = this.props.t("common:dimensions.med.m");
                n["name"] = this.props.t("common:dimensions.med.n");
                s["name"] = this.props.t("common:dimensions.med.s");
                v["name"] = this.props.t("common:dimensions.med.v");

                gs[this.props.t("common:labels.percentrange")] = range.range_gs;
                gp[this.props.t("common:labels.percentrange")] = range.range_gp;
                c[this.props.t("common:labels.percentrange")] = range.range_c;
                k[this.props.t("common:labels.percentrange")] = range.range_k;
                m[this.props.t("common:labels.percentrange")] = range.range_m;
                n[this.props.t("common:labels.percentrange")] = range.range_n;
                s[this.props.t("common:labels.percentrange")] = range.range_s;
                v[this.props.t("common:labels.percentrange")] = range.range_v;
                obj["iv"] = [gs, gp, c, k];
                obj["vp"] = [m,n,s,v];
            }else{
                obj = null
            }
        }else{
            obj = null
        }
        return obj;
    };

    render() {
        return (
                <div className="card card-table">
                    <div className="card-header">
                        {this.props.t("common:labels.informationfor")}<strong>&nbsp;{this.props.identification}</strong>
                    </div>
                    <div className="card-body" style={{padding:"0"}}>
                        {
                            this.range() !== null ?
                                <div style={{ padding: "20px"}} className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                            <h5 style={{textAlign:"center"}}>{this.props.t("common:labels.iv")}</h5>
                                            <ResponsiveContainer width="100%" aspect={2}>
                                                <BarChart margin={{ top: 0, right: 20, bottom: 0, left: 20 }} layout="vertical" data={this.range().iv}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis tickCount={5} type="number" ticks={[0,25,50,75,100]} />
                                                    <YAxis type="category" dataKey={"name"}/>
                                                    <Tooltip content={<CustomTooltip t={this.props.t}/>}/>
                                                    <Bar layout="vertical" barSize={30} label={{ fill: 'white', fontSize: 16 }} dataKey={this.props.t("common:labels.percentrange")} fill="#0079c2" />
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                            <h5 style={{textAlign:"center"}}>{this.props.t("common:labels.vp")}</h5>
                                            <ResponsiveContainer width="100%" aspect={2}>
                                                <BarChart margin={{ top: 0, right: 20, bottom: 0, left: 20 }} layout="vertical" data={this.range().vp}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis tickCount={5} type="number" ticks={[0,25,50,75,100]} />
                                                    <YAxis type="category" dataKey={"name"}/>
                                                    <Tooltip content={<CustomTooltip t={this.props.t}/>}/>
                                                    <Bar layout="vertical" barSize={30} label={{ fill: 'white', fontSize: 16 }} dataKey={this.props.t("common:labels.percentrange")} fill="#33b1ff" />
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div>{this.props.t("common:labels.loading") + "..."}</div>
                        }
                 </div>
            </div>
        )
    }
}

class CustomTooltip extends React.Component{
    constructor(props, context) {
        super(props, context);
        this.getIntroOfPage = this.getIntroOfPage.bind(this);
    }

    getIntroOfPage(label) {
        switch(label){
            case "Gspeed":
                return this.props.t("charts:charts.hints.gp");
            case "Gpower":
                return this.props.t("charts:charts.hints.gs");
            case "C":
                return this.props.t("charts:charts.hints.c");
            case "K":
                return this.props.t("charts:charts.hints.k");
            case "M":
                return this.props.t("charts:charts.hints.m");
            case "N":
                return this.props.t("charts:charts.hints.n");
            case "S":
                return this.props.t("charts:charts.hints.s");
            case "V":
                return this.props.t("charts:charts.hints.v");
        }
    }

    render(){
        const { active } = this.props;

        if (active) {
            const { payload, label } = this.props;
            let info = this.getIntroOfPage(label);
            return (
                <div style={{width:"100% !important"}} className="card card-table">
                    <div className="card-header">
                        {info.header}
                    </div>
                    <div className="card-body">
                        <h6 className="card-title">{info.exp}</h6>
                        <div className="card-text" style={{fontSize:"0.8rem"}}>{info.info}</div>
                        <p className="card-text">{`${this.props.t("common:labels.percentrange")} : ${payload[0].value}`}</p>
                    </div>
                </div>
            );
        }
        return null;
    }
}

const resultChartsOberviewTrans = withTranslation(["common", "charts"])(ResultChartOverview);
export default connect(mapStateToProps, mapDispatchToProps)(resultChartsOberviewTrans)