import React, {Component} from 'react';
import {connect} from 'react-redux';
import ResultChartOverview from "../../module/results/ResultChartOverview";
import {withNamespaces, withTranslation} from "react-i18next";
import {format, getTime, parseISO} from "date-fns";
import Tooltip from "rc-tooltip";
import FeedbackTooltip from "./FeedbackTooltip";
import {de} from "date-fns/locale";
import {enGB as en} from "date-fns/locale";
import checkboxHOC from "react-table-6/lib/hoc/selectTable";
import ReactTable from "react-table-6/react-table";
import ResultsActions from "../../module/results/actions/ResultsActions";
import BaseActions from "../../module/results/actions/BaseActions";

const getLocalizedDateShort = (date, locale) => {
    switch(locale){
        case "de":
            return format(parseISO(date), "dd.MM.yyyy HH:mm:ss", {locale: de});
        case "en":
            return format(parseISO(date), "MM/dd/yyyy hh:mm:ss", {locale: en});
        default:
            return format(parseISO(date), "MM/dd/yyyy hh:mm:ss", {locale: en});
    }
};

const CheckboxTable = checkboxHOC(ReactTable);

const positions = (positionState) => {
    let posArr = [];
    positionState.map(position => {
        posArr.push({value: position.entity.id, label: position.entity.name})
    });
    return posArr
};

const mapStateToProps = (state) => ({
    finished: state.results.finished,
    positions: positions(state.results.positions.positions),
    positionsRaw: state.results.positions.positions,
    selectedPosition: state.results.positions.selectedFinished,
    tableSelection: state.results.tableSelection,
    language: state.results.language,
    profiles: state.results.profiles,
    reportType: state.results.reportType,
    interviewType: state.results.interviewType,
    downloadReport: state.results.downloadReport,
    finishedExpanded: state.results.finishedExpanded,
    percentRanges: state.results.percentRanges,
    customer: state.app.portal.userInfo.customer.id,
    i18next: state.locale.i18next,
    portal: state.app.portal,
    switch_form: state.results.switch_form,

});

const mapDispatchToProps = (dispatch) => ({
    fetchFinished: (jobId, customerId) => dispatch(ResultsActions.fetchFinished(jobId, customerId)),
    fetchPositions: () => dispatch(ResultsActions.fetchPositions()),
    selectPositionFinished:(e) => dispatch(ResultsActions.selectPositionFinished(e)),
    startReportsDownload: (interval, format) => dispatch(ResultsActions.startReportsDownload(interval, format)),
    requestReports: (format, cb) => dispatch(ResultsActions.requestReports(format, cb)),
    pollReports: (token) => dispatch(ResultsActions.pollReports(token)),
    selectAllFinished: (a, b) => dispatch(ResultsActions.selectAllFinished(a, b)),
    selectRowFinished: (key) => dispatch(ResultsActions.selectRowFinished(key)),
    chooseLanguage: (lang) => dispatch(ResultsActions.chooseLanguage(lang)),
    fetchProfiles: () => dispatch(ResultsActions.fetchProfiles()),
    chooseProfile: (id) => dispatch(ResultsActions.chooseProfile(id)),
    startRankingDownload: (interval, format) => dispatch(ResultsActions.startRankingDownload(interval, format)),
    pollRanking: (token) => dispatch(ResultsActions.pollRanking(token)),
    requestRanking: (format) => dispatch(ResultsActions.requestRanking(format)),
    setInterviewType: (interviewType) => dispatch(ResultsActions.setInterviewType(interviewType)),
    setReportType: (reportType) => dispatch(ResultsActions.setReportType(reportType)),
    //List


    //Reports as Zip


    setFinishedExpanded: (newExpanded, index, event) => dispatch(ResultsActions.setFinishedExpanded(newExpanded, index, event)),
    fetchRange: (identification, finishedAt) => dispatch(ResultsActions.fetchRange(identification, finishedAt)),

    switchForm: (form) => dispatch(BaseActions.switchForm(form))
});

/**
 * created by stephanpudras at 19.05.20
 */
class ResultTable extends Component {
    constructor(props) {
        super(props);
        this.getFilteredProfiles = this.getFilteredProfiles.bind(this);
        this.selectPosition = this.selectPosition.bind(this);
        this.deriveFinished = this.deriveFinished.bind(this);
        this.state = {byRef: false, tableEntries:[], files:[]};
    }

    finished = (finishedState, positionState) => {
        let finArr = [];
        finishedState.map(finishedItem => {
            finArr.push({identification:finishedItem.ref.identification, finishedAt: finishedItem.ref.finishedAt, locale: finishedItem.ref.locale, testname:finishedItem.ref.testname, position: positionState.find(item => item.entity.extId === finishedItem.ref.positionId).entity.name,
                feedback: finishedItem.stats != null && finishedItem.stats.identification ? finishedItem.stats : null})
        });
        return finArr;
    };

    getFilteredProfiles(){
        let oProfiles = [];
        this.props.profiles.profiles.map(profile => {
            oProfiles.push({value:profile.ref.id, label: profile.ref.name})
        });
        return oProfiles;
    }

    selectPosition(){
        this.setState({byRef: false})
    }

    deriveFinished(){
        let arr = [];
        this.state.tableEntries.map(tEntry => {
            arr.push({identification: tEntry.identification, finishedAt: getLocalizedDateShort(tEntry.finishedAt, this.props.i18n.language), locale: tEntry.locale, testname: tEntry.testname, position: this.props.positionsRaw.find(item => item.entity.extId === tEntry.positionId).entity.name})
        });
        return arr;
    }

    componentDidMount() {

    }

    toggleSelection = (key, shift, row) => {
        let k = key.split("-")
            this.props.selectRowFinished(k[1]);
    };

    toggleAll = () =>{
        const selectAll = !this.props.tableSelection.selectAll;
        const selection = [];
        if (selectAll) {
            // we need to get at the internals of ReactTable
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            // the 'sortedData' property contains the currently accessible records based on the filter and sort
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            // we just push all the IDs onto the selection array
            currentRecords.forEach(item => {
                selection.push(item._original.identification);
            });
        }
        this.props.selectAllFinished(selection, selectAll)

    };

    isSelected = key => {
        /*
          Instead of passing our external selection state we provide an 'isSelected'
          callback and detect the selection state ourselves. This allows any implementation
          for selection (either an array, object keys, or even a Javascript Set object).
        */
        return this.props.tableSelection.selection.includes(key);
    };

    reportHeaders = () => {
        return [
            {
                Header: this.props.t("tHeadLabels.logincode"),
                accessor: "identification"
            },
            {
                Header: this.props.t("tHeadLabels.finished"),
                accessor: "finishedAt",
                sortMethod: (a, b) => {
                    let oA = getTime(parseISO(a));
                    let oB = getTime(parseISO(b));
                    if(oA < oB)
                        return 1;
                    else
                        return -1;
                },
                Cell: row => {
                    return getLocalizedDateShort(row.original.finishedAt, this.props.i18n.language)
                }


            },
            {
                Header: this.props.t("tHeadLabels.locale"),
                accessor: "locale"
            },
            {
                Header: this.props.t("tHeadLabels.test"),
                accessor: "testname"
            },
            {
                Header: this.props.t("tHeadLabels.position"),
                accessor: "position",
            },
            {
                Header: this.props.t("tHeadLabels.feedback"),
                accessor: "feedback",
                Cell: row => {
                    if(row.original.feedback !== null)
                        return <Tooltip placement={"left"} overlay={<FeedbackTooltip locale={this.props.i18next.language} stats={row.original.feedback}/>} trigger={"hover"}><span style={{fontSize: "1.1rem", display: "block", cursor:"pointer"}} className="text-success icon-check"> </span></Tooltip>;
                    else
                        return <span style={{fontSize:"1.1rem"}} className="text-danger icon-cross"> </span>;

                }
            }
        ];
    };

    render() {
        let data = []
        data = this.state.byRef ? this.deriveFinished() : this.finished(this.props.finished, this.props.positionsRaw);
        const columns = this.reportHeaders();
        const { toggleSelection, toggleAll, isSelected } = this;
        const selectAll =this.props.tableSelection.selectAll;
        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox",
            getTrProps: (s, r) => {
                // someone asked for an example of a background color change
                // here it is...
                const selected = r ? r !=="undefined" && r !== null ? r.hasOwnProperty("original") ? this.isSelected(r.original.identification) : false : false : false;
                return {
                    style: {
                        backgroundColor: selected ? "#a6d6fa" : "inherit",
                        color: selected ? "white" : "inherit"
                        // color: selected ? 'white' : 'inherit',
                    }
                };
            }
        };
        return (
            <div className="col-12">
                <CheckboxTable
                    ref={r => (this.checkboxTable = r)}
                    data={data}
                    columns={this.reportHeaders()}
                    defaultPageSize={10}
                    keyField='identification'
                    className="-striped -highlight"
                    previousText={this.props.t("tableControls.backward")}
                    nextText={this.props.t("tableControls.forward")}
                    loadingText={this.props.t("tableControls.loading") + '...'}
                    noDataText={this.props.t("tableControls.nodata") + "..."}
                    pageText={this.props.t("tableControls.page")}
                    ofText={this.props.t("tableControls.of")}
                    rowsText={this.props.t("tableControls.rows")}
                    defaultSorted={[
                        {
                            id: "finishedAt",
                            desc: false
                        }
                    ]}
                    filterable
                    expanded={this.props.finishedExpanded}
                    onExpandedChange = {(newExpanded, index, event) => this.props.setFinishedExpanded(newExpanded, index, event)}
                    SubComponent = { row => {
                        this.props.fetchRange(row.row.identification, row.original.finishedAt);
                        return <ResultChartOverview percentRanges={this.props.percentRanges} identification={row.row.identification}/>
                    }
                    }
                    {...checkboxProps}
                />
            </div>
        )
    }
}
let resultTableTrans = withTranslation(["common"])(ResultTable);
export default connect(mapStateToProps, mapDispatchToProps)(resultTableTrans);