import React, {useEffect} from "react"
import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    NativeSelect,
    Select,
    Switch
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../reducer/rootReducer";
import {Customer, CustomerTestvariant} from "../../../types";
import {changeCustomer} from "../slices/selectedCustomerSlice";
import {addTestvariant, allTestvariants, changedCustomer, removeTestvariant} from "../slices/testvariantsSlice";
import {getTestvariants} from "../api/testvariantAPI";

export default function TestvariantAllocation():JSX.Element {

    const dispatch = useDispatch()

    const customer = useSelector(
        (state: RootState) => state.admin.customer
    )

    const selectedCustomer = useSelector(
        (state:RootState) => state.administration.selectedCustomer
    )

    const testvariants = useSelector(
        (state:RootState) => state.administration.testvariant
    )

    const handleSelect = (e:any) => {
        customer?.data?.map((cust:Customer) => {
            if(cust?.entity?.id == e.target.value){
                let cu: Customer = {...cust}
                dispatch(changeCustomer(cu))
                dispatch(allTestvariants(cu))
            }
        })
    }

    const handleChange = (e: any) => {
        let c: CustomerTestvariant = {
            id: "",
            testvariant: e.target.name,
            customer: selectedCustomer?.entity
        }
        let t:CustomerTestvariant | undefined = testvariants.find(tv => tv.testvariant == e.target.name)
        if(t !== undefined){
            dispatch(removeTestvariant(t))
        }else{
            dispatch(addTestvariant(c))
        }
    }

    const available = ["DEFAULT", "AZUBI", "COGNITIVE", "DEFAULT_DT", "RESEARCH"]

    return (
        <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <FormControl>
                    <InputLabel htmlFor="testvariant">Testvariante</InputLabel>
                    <NativeSelect
                        value={selectedCustomer?.entity?.id}
                        onChange={(e) => handleSelect(e)}
                        inputProps={{
                            name: 'testvariant',
                            id: 'testvariant',
                        }}
                    >
                        {
                            customer.data.map((cust:Customer,idx:number) => {
                                return <option value={cust?.entity?.id} key={"sel_cu_"+idx}>{cust?.entity?.name}</option>
                            })
                        }
                    </NativeSelect>
                    <FormHelperText>Die Variante des Assessments</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                {
                    available.map((t:string, idx) => {
                        return <FormControlLabel
                            control={
                                <Switch
                                    checked={testvariants.find(tv => tv.testvariant.toUpperCase() == t) !== undefined}
                                    onChange={handleChange}
                                    name={t}
                                    color="primary"
                                />
                            }
                            label={t}
                        />
                    })
                }

            </Grid>
        </Grid>
    )
}