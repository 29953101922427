import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    AttendanceCreationResult,
    AttendanceCreationStatus,
    Customer,
    Customer_Flat,
    CustomerTestvariant, Position
} from "../../../../types";
import {AppDispatch, AppThunk} from "../../../../store";
import {loadAvailablePositions} from "../api/attendanceApi";

const initialState:Position[] = []

const availablePositionsSlice = createSlice({
    name: "availablePositions",
    initialState,
    reducers: {
        addPosition(state, action:PayloadAction<Position>){
            return [...state, action.payload]
        },
        addAllPositions(state, action:PayloadAction<Position[]>){
            console.log(action.payload)
            return action.payload
        }
    }
})

export const allAvailablePositions = (customer: Customer_Flat):AppThunk => async (dispatch: AppDispatch) => {
    let avail: Position[] = await loadAvailablePositions(customer)
    console.log(avail)
    dispatch(availablePositionsSlice.actions.addAllPositions(avail))
}

export default availablePositionsSlice.reducer