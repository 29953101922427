import React, {Component} from 'react';
import {connect} from 'react-redux';
import {format, getTime, parseISO} from "date-fns";
import {de} from "date-fns/locale";
import {enGB as en} from "date-fns/locale";
import checkboxHOC from "react-table-6/lib/hoc/selectTable";
import ReactTable from "react-table-6/react-table";
import Tooltip from "rc-tooltip";
import FeedbackTooltip from "./FeedbackTooltip";
import ResultChartOverview from "../../module/results/ResultChartOverview";
import {withNamespaces, withTranslation} from "react-i18next";
import ListActions from "../../module/results/actions/ListActions";
import ResultsActions from "../../module/results/actions/ResultsActions";
import FadeInDown from "../style/FadeInDown";

const attendances = (attendances, testvariant) => {
    let attendancesList = [];
    if(attendances.length > 0){
        if(testvariant != null){
            attendances.map(item => {
                if(item.ref.testname === testvariant.value){
                    attendancesList.push(
                        {
                            identification: item.ref.identification,
                            finishedAt: item.ref.finishedAt,
                            locale: item.ref.locale,
                            testname: item.ref.testname,
                            position: item.ref.position,
                            feedback: item.stats != null && item.stats !== "undefined" ? item.stats : null
                        }
                    )
                }
            })
        }else{
            attendances.map(item => {
                attendancesList.push(
                    {
                        identification: item.ref.identification,
                        finishedAt: item.ref.finishedAt,
                        locale: item.ref.locale,
                        testname: item.ref.testname,
                        position: item.ref.position,
                        feedback: item.stats != null && item.stats !== "undefined" ? item.stats : null
                    }
                )

            })

        }
    }
    return attendancesList
}

const mapStateToProps = (state) => ({
    selected: state.result.list.selected,
    attendances: attendances(state.result.list.attendances, state.result.list.filter.testvariant),
    percentRanges: state.results.percentRanges,
    expanded: state.result.list.listExpanded
});

const mapDispatchToProps = (dispatch) => ({
    setListTableSelect: (key) => dispatch(ListActions.setListTableSelect(key)),
    setListTableSelectAll: (a,b) => dispatch(ListActions.setListTableSelectAll(a,b)),
    fetchRange: (identification, finishedAt) => dispatch(ResultsActions.fetchRange(identification, finishedAt)),
    setListExpanded: (newExpanded, index, event) => dispatch(ListActions.setListExpanded(newExpanded, index, event)),

});

const getLocalizedDateShort = (date, locale) => {
    switch(locale){
        case "de":
            return format(parseISO(date), "dd.MM.yyyy HH:mm:ss", {locale: de});
        case "en":
            return format(parseISO(date), "MM/dd/yyyy hh:mm:ss", {locale: en});
        default:
            return format(parseISO(date), "MM/dd/yyyy hh:mm:ss", {locale: en});
    }
};

const CheckboxTable = checkboxHOC(ReactTable);


/**
 * created by stephanpudras at 19.05.20
 */
class ListTable extends Component {
    constructor(props) {
        super(props);
        this.state = {byRef: false, tableEntries:[], files:[]};
    }

    componentDidMount() {

    }

    listHeaders = () => {
        return [
            {
                Header: this.props.t("tHeadLabels.logincode"),
                accessor: "identification"
            },
            {
                Header: this.props.t("tHeadLabels.finished"),
                accessor: "finishedAt",
                sortMethod: (a, b) => {
                    let oA = getTime(parseISO(a));
                    let oB = getTime(parseISO(b));
                    if(oA < oB)
                        return 1;
                    else
                        return -1;
                },
                Cell: row => {
                    return getLocalizedDateShort(row.original.finishedAt, this.props.i18n.language)
                }
            },
            {
                Header: this.props.t("tHeadLabels.locale"),
                accessor: "locale"
            },
            {
                Header: this.props.t("tHeadLabels.test"),
                accessor: "testname"
            },
            {
                Header: this.props.t("tHeadLabels.feedback"),
                accessor: "feedback",
                Cell: row => {
                    if(row.original.feedback !== null)
                        return <Tooltip placement={"left"} overlay={<FeedbackTooltip locale={this.props.i18next.language} stats={row.original.feedback}/>} trigger={"hover"}><span style={{fontSize: "1.1rem", display: "block", cursor:"pointer"}} className="text-success icon-check"> </span></Tooltip>;
                    else
                        return <span style={{fontSize:"1.1rem"}} className="text-danger icon-cross"> </span>;

                }
            }
        ];
    };

    toggleSelection = (key, shift, row) => {
        let k = key.split("-")
        this.props.setListTableSelect(k[1]);
    };

    toggleAll = () =>{
        const selectAll = !this.props.selected.selectAll;
        const selection = [];
        if (selectAll) {
            // we need to get at the internals of ReactTable
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            // the 'sortedData' property contains the currently accessible records based on the filter and sort
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            // we just push all the IDs onto the selection array
            currentRecords.forEach(item => {
                selection.push(item._original.identification);
            });
        }
        this.props.setListTableSelectAll(selection, selectAll);
    };

    isSelected = key => {
        /*
          Instead of passing our external selection state we provide an 'isSelected'
          callback and detect the selection state ourselves. This allows any implementation
          for selection (either an array, object keys, or even a Javascript Set object).
        */
        return this.props.selected.selection.includes(key);
    };

    render() {
        let data = this.props.attendances;
        let columns = this.listHeaders();
        const { toggleSelection, toggleAll, isSelected } = this;
        const selectAll = this.props.selected.selectAll;
        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox",
            getTrProps: (s, r) => {
                // someone asked for an example of a background color change
                // here it is...
                const selected = r ? r !=="undefined" && r !== null ? r.hasOwnProperty("original") ? this.isSelected(r.original.identification) : false : false : false;
                return {
                    style: {
                        backgroundColor: selected ? "lightgreen" : "inherit"
                        // color: selected ? 'white' : 'inherit',
                    }
                };
            }
        };
        return (
            <div className="col-12">
                <CheckboxTable
                    ref={r => (this.checkboxTable = r)}
                    data={data}
                    columns={this.listHeaders()}
                    defaultPageSize={10}
                    keyField='identification'
                    className="-striped -highlight"
                    previousText={this.props.t("tableControls.backward")}
                    nextText={this.props.t("tableControls.forward")}
                    loadingText={this.props.t("tableControls.loading") + '...'}
                    noDataText={this.props.t("tableControls.nodata") + "..."}
                    pageText={this.props.t("tableControls.page")}
                    ofText={this.props.t("tableControls.of")}
                    rowsText={this.props.t("tableControls.rows")}
                    defaultSorted={[
                        {
                            id: "finishedAt",
                            desc: false
                        }
                    ]}
                    filterable
                    expanded={this.props.expanded}
                    onExpandedChange = {(newExpanded, index, event) => this.props.setListExpanded(newExpanded, index, event)}
                    SubComponent = { row => {
                        this.props.fetchRange(row.row.identification, row.original.finishedAt);
                        return <FadeInDown><ResultChartOverview percentRanges={this.props.percentRanges} identification={row.row.identification}/></FadeInDown>
                    }
                    }
                    {...checkboxProps}
                />
            </div>
        )
    }
}

const listTableTrans = withTranslation(["common"])(ListTable);
export default connect(mapStateToProps, mapDispatchToProps)(listTableTrans);