/**
 * Action type definitions, which will be used app wide. Should not hold specific
 * types to avoid duplication and/or messy files
 */
const AppConst = {
  AJAX_CALL: "AJAX_CALL",
  AJAX_ERROR: "AJAX_ERROR",
  LOGIN: "LOGIN",
  USERPROFILE: "USERPROFILE",
  LOGOUT: "LOGOUT",
  REFRESH_TOKEN: "REFRESH_TOKEN",
  GET_CUSTOMER: "GET_CUSTOMER",
  GET_PORTALUSERINFO: "GET_PORTALUSERINFO",
  SIDEBAR_OPEN_TOPIC: "SIDEBAR_OPEN_TOPIC",
  USER_PROFILE_FINISHED: "USER_PROFILE_FINISHED",
  LOAD_LOCALIZED_TOOLTIP: "LOAD_LOCALIZED_TOOLTIP",
  LOAD_LOCALE: "LOAD_LOCALE"
};

export default AppConst;
