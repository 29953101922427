import {combineReducers} from "redux";
import HomeConst from '../module/home/HomeConst';
import DateTimeConverter from "../logic/postprocessor/DateTimeConverter";

const finished = (state={data:[]}, action=null) => {
  switch(action.type){
    case HomeConst.FETCH_ALL:
      return Object.assign({}, state, {data: action.payload.finished});
    default:
      return state;
  }
};

const started = (state={data:[]}, action=null) => {
  switch(action.type){
    case HomeConst.FETCH_ALL:
        /** @namespace action.payload.unfinished */
        return Object.assign({}, state, {data: action.payload.unfinished});
    default:
      return state;
  }
};

const inwork = (state={data:[]}, action=null) => {
  switch(action.type){
    case HomeConst.FETCH_ALL:
      return Object.assign({}, state, {data: action.payload.active});
    default:
      return state;
  }
};

const positions = (state=[], action=null) => {
  switch(action.type){
    case HomeConst.FETCH_POSITIONS:
      return Object.assign([], action.payload);
    default:
      return state;
  }
};

const finishedExpanded = (state={}, action=null) => {
    switch(action.type){
        case HomeConst.SET_FINISHED_WIDGET_EXPANDED:
            return Object.assign({}, state, action.payload.newExpanded);
        default:
            return state;
    }
};

const percentRanges = (state=[], action=null) => {
    switch(action.type){
        case HomeConst.GET_WIDGET_RANGES:
            return Object.assign([], state, [...state, action.payload]);
        default:
            return state;
    }
};

const home = combineReducers({finished, inwork, started, positions, finishedExpanded, percentRanges});
export default home;
