import {create} from "zustand";
import {devtools} from "zustand/middleware";
import axios from "axios";
import URLConstants from "../../../api/URLConstants";
import {Profile, RProfile} from "../../../types";

export interface AbciProfileDocumentDimension {
  dimension:string
  header:string
  paragraph:string
}

export interface AbciProfileDocumentHeader {
  mainHeader: string
  subHeader: string
}

export interface AbciProfileDocumentLegend {
  cut:string
  exclamation: string
  green: string
  red: string
  yellow: string
}

export interface AbciProfileDocumentMainDescription {
  header: string
  paragraph: string
}

export interface AbciProfileDocumentMain {
  entry: string
  descriptions: AbciProfileDocumentMainDescription[]
}

export interface AbciProfileDocumentAddress {
  company: string
  street: string
  zip: string
  city: string
  country: string
  telephone: string
  email: string
  homepage: string
}

export interface AbciProfileDocumentRoot {
  lang: string
  dimensionDescription: AbciProfileDocumentDimension[]
  documentHeader: AbciProfileDocumentHeader
  legendDescription: AbciProfileDocumentLegend
  mainSection: AbciProfileDocumentMain
  address: AbciProfileDocumentAddress
}

export interface AbciProfileDocument{
  imageBase64: string
  text: AbciProfileDocumentRoot
}

export interface AbciDocumentProfileShort {
  id: number
  name: string
}

interface AbciProfileDocumentProps {
  document: AbciProfileDocument | undefined
  initial: boolean
  loading: boolean
  isOpen: boolean
  profile: AbciDocumentProfileShort
  profileId: number | undefined
  loadedProfileId: number | undefined
  variant?: string
  fetch: (profile:AbciDocumentProfileShort, variant?: string, lang?:string) => void
  open: () => void
}

const DEFAULT_DIM = ["GS", "GP", "C","K","M","N","S","V"]
const DEFAULT_DT_DIM = ["GS", "GP", "C","K","M","N","S","V","DTM","DTN","DTP"]
const AZUBI = ["GS", "GP", "C","M","N","S"]
const COGNITIVE = ["GS", "GP", "C","K"]

const useAbciProfileDocument = create<AbciProfileDocumentProps>()(devtools((set, get) => ({
  document: undefined,
  initial: false,
  loading:false,
  isOpen: false,
  profile: undefined,
  profileId: undefined,
  loadedProfileId: undefined,
  variant: undefined,
  fetch: (profile:AbciDocumentProfileShort, variant?: string, lang?:string) => {
    console.log("fetch")
      set({loading:true})
      console.log(get().profile)
      axios.get<AbciProfileDocument>(`${URLConstants.getURL()}/portal/profile/document/${profile.id}`,{params:{lang:lang,variant:variant}})
        .then(r => {
          let document:AbciProfileDocument = {...r.data}
          let dimensions:AbciProfileDocumentDimension[] = []

          switch(variant.toUpperCase()){
            case "DEFAULT":
              r.data.text.dimensionDescription.forEach(d => {
                if(DEFAULT_DIM.includes(d.dimension.toUpperCase())){
                  dimensions.push(d)
                }
              })
              break
            case "DEFAULT_DT":
              r.data.text.dimensionDescription.forEach(d => {
                if(DEFAULT_DT_DIM.includes(d.dimension.toUpperCase())){
                  dimensions.push(d)
                }
              })
              break
            case "AZUBI":
              r.data.text.dimensionDescription.forEach(d => {
                if(AZUBI.includes(d.dimension.toUpperCase())){
                  dimensions.push(d)
                }
              })
              break
            case "COGNITIVE":
              r.data.text.dimensionDescription.forEach(d => {
                if(COGNITIVE.includes(d.dimension.toUpperCase())){
                  dimensions.push(d)
                }
              })
              break
            default:
              r.data.text.dimensionDescription.forEach(d => {
                if(DEFAULT_DIM.includes(d.dimension.toUpperCase())){
                  dimensions.push(d)
                }
              })
          }
          document = {...r.data, text:{...r.data.text, dimensionDescription: dimensions}}
          console.log(document)
          set({document:document, loading:false, profile: profile, profileId: profile?.id, loadedProfileId:profile?.id, isOpen: true, variant:variant.toUpperCase()})
        })
  },
  open:() => {
    set({isOpen: !get().isOpen})
  }
})));

export {useAbciProfileDocument};