import ResultsConst from "../const/ResultsConst";
import AppConst from "../../../AppConst";
import RequestEntity from "../../../api/RequestEntity";
import ListConst from "../const/ListConst";
import URLConstants from "../../../api/URLConstants";
import Base64Handler from "../../../utils/Base64Handler";
import ReportType from "../../../const/ReportType";
import {positionManagement} from "../../admin/reducer/PositionManagementReducer";

const BASE_URL = URLConstants.getURL();

const checkMandatory = (elements) => {

}

const ListActions = {
    setListFilterTimespan: (timespan) => {
        return (dispatch, getState) => {
            let timespanValue = null
            if(timespan){
                timespanValue = timespan.value.toString()
            }
            dispatch({type: ListConst.SET_RESULTS_LIST_FILTER_TIMESPAN, payload: timespan});
            let user = getState().app.portal.userInfo.customer.id;
            if(timespanValue){
                dispatch(ListActions.setResultsListValidate(ListActions.validateForm(getState)));
                dispatch({type: AppConst.AJAX_CALL, entity: new RequestEntity(BASE_URL + "/portal/personal/attendance/"+ user +"/list/timespan/" + timespanValue,
                        "GET", ListConst.FETCH_RESULTS_LIST_ATTENDANCES, dispatch(ListActions.setResultsListValidate(ListActions.validateForm(getState))), null)})
            }else{
                dispatch({type: ListConst.SET_RESULTS_LIST_FILTER_TIMESPAN_RESET});

            }
        }
    },
    setListFilterProfile: (profile) => {
        return (dispatch, getState) => {
            dispatch({type: ListConst.SET_RESULTS_LIST_FILTER_PROFILE, payload: profile});
            dispatch(ListActions.setResultsListValidate(ListActions.validateForm(getState)));
        }
    },
    setListFilterPosition: (position) => {
        return (dispatch, getState) => {
            dispatch({type: ListConst.SET_RESULTS_LIST_FILTER_POSITION, payload: position});
            dispatch(ListActions.setResultsListValidate(ListActions.validateForm(getState)));
        }
    },
    setListFilterFile: (file) => {
        return (dispatch, getState) => {
            dispatch({type: ListConst.SET_RESULTS_LIST_FILTER_FILE, payload: file});
            dispatch(ListActions.setResultsListValidate(ListActions.validateForm(getState)));
        }

    },
    setListFilterSort: (sort) => {
        return (dispatch, getState) => {
            dispatch({type: ListConst.SET_RESULTS_LIST_FILTER_SORT, payload: sort});
            dispatch(ListActions.setResultsListValidate(ListActions.validateForm(getState)));
        }
    },
    setListFilterLocale: (locale) => {
        return (dispatch, getState) => {
            dispatch({type: ListConst.SET_RESULTS_LIST_FILTER_LOCALE, payload: locale});
            dispatch(ListActions.setResultsListValidate(ListActions.validateForm(getState)));
        }
    },
    setListFilterTestvariant: (testvariant) => {
        return (dispatch, getState) => {
            dispatch({type: ListConst.SET_RESULTS_LIST_FILTER_TESTVARIANT, payload: testvariant})
            dispatch(ListActions.setResultsListValidate(ListActions.validateForm(getState)));
        }
    },
    setListFilterFormat: (format) =>{
        return (dispatch,getState) =>{
            dispatch({type: ListConst.SET_RESULTS_LIST_FILTER_FORMAT, payload: format})
            dispatch(ListActions.setResultsListValidate(ListActions.validateForm(getState)))
        }
    },
    setListTableSelect: (key) => {
        return (dispatch, getState) => {
            dispatch({type: ListConst.SET_RESULTS_LIST_ROW_SELECTED, payload:{key:key}})
            dispatch(ListActions.setResultsListValidate(ListActions.validateForm(getState)));
        }
    },
    setListTableSelectAll: (selected, toggleAll) => {
        return (dispatch, getState) => {
            dispatch({type: ListConst.SET_RESULTS_LIST_ALL_ROWS_SELECTED, payload:{keys: selected, toggleAll: toggleAll}})
            dispatch(ListActions.setResultsListValidate(ListActions.validateForm(getState)));
        }
    },
    requestList: (cb) => {
        return (dispatch, getState) => {
            let requested = getState().result.list.selected.selection;
            let attendances = [];
            if(requested.length > 0){
                requested.map(req => {
                    let a = getState().result.list.attendances.find(att => att.ref.identification === req).ref
                    attendances.push(a);
                })
            }
            let locale = getState().result.list.filter.locale ? getState().result.list.filter.locale.value : null;
            let profile = getState().result.list.filter.profile !== null ? getState().result.list.filter.profile.value : null;
            let position = getState().result.list.filter.position;
            let sort = getState().result.list.filter.sort;
            let file = getState().result.list.filter.file;
            let customer = getState().app.portal.userInfo.customer.name;
            let customerId = getState().app.portal.userInfo.customer.extId;
            let timespan = getState().result.list.filter.timespan;
            let format = getState().result.list.filter.format.value
            let elements = ListActions.validateForm(getState)
            //if(timespan == null || timespan === "undefined" || timespan === "" && file == null){
            //    elements.push("attendance", "file")
            //}
            //
            //if(attendances.length < 1){
            //    elements.push("attendance")
            //}
            //
            //if(locale == null || locale === "undefined" || locale === ""){
            //    elements.push("locale")
            //}
            //
            //if(file == null && position == null || position === "" || position === "undefined"){
            //    elements.push("position", "file")
            //}
            //
            //if(profile == null || profile === "undefined" || profile === "") {
            //    elements.push("profile")
            //}
            //
            //if(sort == null || sort === "undefined" || sort === ""){
            //    elements.push("sort")
            //}
            console.log(format)
            console.log(getState().result)
            if(elements.length > 0){
                dispatch(ListActions.setResultsListValidate(elements));
            }else {
                dispatch({
                    type: AppConst.AJAX_CALL,
                    entity: new RequestEntity(BASE_URL + "/portal/list/request", "POST", ListConst.POST_RESULTS_LIST_REQUEST, cb, null),
                    payload: {
                        attendances: attendances,
                        locale: locale,
                        profile: profile,
                        exportFormat: format,
                        exportProperties: {
                            positionName: position,
                            customerName: customer,
                            customerId: customerId
                        },
                        sortMode: sort != null ? sort.value : null,
                        sourceFile: {
                            base64file: file != null ? file.file : null,
                            fileName: file != null ? file.fileName : null,
                            mimetype: file != null ? file.mimetype : null
                        }
                    }
                })
            }
        }
    },
    pollListDownload: () => {
        return (dispatch, getState) => {
            let token = getState().result.list.downloadList.token
            dispatch({type: AppConst.AJAX_CALL, entity: new RequestEntity(BASE_URL + "/portal/list/download/" + token, "GET", ListConst.FETCH_LIST, null)})
        }
    },
    startListDownload:(interval) => {
        return {type: ListConst.REQUEST_LIST, payload: {interval: interval}}
    },
    setListExpanded: (newExpanded, index, event) => {
        return {type:ListConst.SET_RESULTS_LIST_EXPANDED, payload:{newExpanded: newExpanded, index: index, event: event}};
    },
    setResultsListValidate: (elements) => {
        return {type:ListConst.SET_RESULTS_LIST_VALIDATE, payload:{validate: true, elements: elements}}
    },
    validateForm:(getState) => {
            let requested = getState().result.list.selected.selection;
            let attendances = [];
            if(requested.length > 0){
                requested.map(req => {
                    let a = getState().result.list.attendances.find(att => att.ref.identification === req).ref
                    attendances.push(a);
                })
            }
            let locale = getState().result.list.filter.locale ? getState().result.list.filter.locale.value : null;
            let profile = getState().result.list.filter.profile !== null ? getState().result.list.filter.profile.value : null;
            let position = getState().result.list.filter.position;
            let sort = getState().result.list.filter.sort;
            let file = getState().result.list.filter.file;
            let customer = getState().app.portal.userInfo.customer.name;
            let customerId = getState().app.portal.userInfo.customer.extId;
            let timespan = getState().result.list.filter.timespan;
            let elements = []

            if(requested.length < 1){
                if(file == null || file === "undefined" || file === ""){
                    elements.push("attendance", "file")
                }else{
                    //elements.push("attendance")
                }
            }

            if(file == null || file === "undefined" || file === ""){
                if(position == null || position === "undefined" || position === ""){
                    elements.push("file", "position")
                }
            }

            if(position == null || position === "undefined" || position === ""){
                if(file == null || file === "undefined" || file === ""){
                    elements.push("position", "file")
                }
            }

            //if(timespan == null || timespan === "undefined" || timespan === ""){
            //    if(file == null || file === "undefined" || file === "" && requested.length < 1){
            //        elements.push("attendance", "file")
            //    }
            //}
            //
            //if(timespan != null || timespan !== "undefined" || timespan !== ""){
            //    if(file == null || file === "undefined" || file === "" && requested.length < 1){
            //        elements.push("attendance", "file")
            //    }
            //}

            //if(file == null || file === "undefined" || file === ""){
            //    if(requested.length < 1){
            //        elements.push("file", "attendance")
            //    }else if(requested.length < 1 && position == null || position === "undefined" || position === "") {
            //        elements.push("file", "attendance", "position")
            //    }else if(requested.length > 0 && position == null || position === "undefined" || position === ""){
            //        elements.push("position")
            //    }else if()
            //    else{
            //        if(requested.length < 1)
            //            elements.push("file")
            //    }
            //}

            //if(position == null || position === "undefined" || position === ""){
            //    if(requested.length < 1){
            //        elements.push("position", "attendance")
            //    }else if(requested.length < 1 && file == null || file === "undefined" || file === "") {
            //        elements.push("file", "attendance", "position")
            //    }else if(requested.length > 0 && file == null || file === "undefined" || file === ""){
            //        elements.push("file", "position")
            //    }
            //    else{
            //        if(file == null || file === "undefined" || file === "")
            //        elements.push("file")
            //    }
            //}

            if(profile == null || profile === "undefined" || profile === ""){
                if(sort == null || sort === "undefined" || sort === ""){
                    elements.push("profile", "sort")
                }else{
                    if(sort != null || sort !== "undefined" || sort !== ""){
                        //elements.push("profile")
                    }
                }
            }

            if(sort == null || sort === "undefined" || sort === "") {
                if(profile == null || profile === "undefined" || profile === ""){
                    elements.push("profile", "sort")
                }else{
                    if(profile != null || profile !== "undefined" || profile !== ""){
                        //elements.push("sort")
                    }
                }
            }

            if(locale == null || locale === "undefined" || locale === ""){
                elements.push("locale")
            }
            return elements;
    }
}

export default ListActions;