import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch, AppThunk} from "../../store";
import  {loadPositions} from "../api/positionApi";
import {Position} from "../../types";

const initialState:Position[] = []

const positionSlice = createSlice({
    name: "positions",
    initialState,
    reducers: {
        addPositions(state, action:PayloadAction<Position[]>){
            return action.payload
        }
    }
})

export const fetchPositions = (token: string, customerId: number | string):AppThunk => async (dispatch: AppDispatch) => {
    let response = await loadPositions(token, customerId)
    dispatch(positionSlice.actions.addPositions(response))
}

export function test(){
}

export default positionSlice.reducer;