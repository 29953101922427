import axios from "axios";
import {Position} from "../../types";
import URLConstants from "../../api/URLConstants";
import * as config from "../../api/axiosConfig";

const url = URLConstants.getURL()


interface GetPositionResponse {
    result: Position[]
}

export async function loadPositions(token: string, customerId: number | string): Promise<Position[]> {
    const response = await axios.get<GetPositionResponse>(url + "/portal/job/" + customerId);
    return response.data.result;
}