/**
 * The Redux store. Holds the entire application state (UI state, model state etc.).
 * If set up properly, one would never have to interfere with it anymore (accept adding new middleware, for example).
 */

import {configureStore, Action} from '@reduxjs/toolkit'
import thunk, { ThunkAction } from 'redux-thunk'
import asyncDispatchMiddleware from "./api/asyncDispatchMiddleware"
import api from "./api/api"
import rootReducer from "./reducer/rootReducer"
import axiosMiddleware from "./api/axiosMiddleware";

type RootState = ReturnType<typeof rootReducer>
const store = configureStore({
    reducer: rootReducer,
    middleware: [thunk, api, axiosMiddleware, asyncDispatchMiddleware]

})

if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./reducer/rootReducer', () => {
    const newRootReducer = require('./reducer/rootReducer').default
    store.replaceReducer(newRootReducer)
 })
}

export type AppDispatch = typeof store.dispatch
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>

export default store

// import {createStore, applyMiddleware, combineReducers, compose} from 'redux';
// import thunk from 'redux-thunk'; //important! Simple async middleware, which allows "dispatch(fn)" or "dispatch(actionObject)", also helps with "getState"
// import index from "./reducer/index"; //indexReducer, which combines multiple other reducers (which also can be combinations...)
// import api from "./api/api";
// import asyncDispatchMiddleware from "./api/asyncDispatchMiddleware"; //api middleware (produce ajax calls by action type)
//
//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
//const store = createStore(
//     index,
//     composeEnhancers(
//         applyMiddleware(thunk, api, asyncDispatchMiddleware)
//     )
//);
//
//let unsubscribe = store.subscribe(() => {
//     console.log(store.getState()); // log any state changes in the console. Will be removed in production env.
//});
//
//export default store;

