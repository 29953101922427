import {combineReducers} from "redux";
import PositionManagementConst from "../const/PositionManagementConst";

const customer = (state={customer:[]}, action=null) => {
    switch (action.type) {
        case PositionManagementConst.ADMIN_POSITIONS_FETCH_CUSTOMER:
            return Object.assign({}, state, {customer: action.payload});
        default:
            return state;
    }
};

const portaluser = (state={portaluser:[]}, action=null) => {
    switch(action.type){
        case PositionManagementConst.ADMIN_POSITIONS_FETCH_PORTALUSER:
            return Object.assign({}, state, {portaluser: action.payload});
        default:
            return state;
    }
};

const positions = (state={positions:[]}, action=null) => {
    switch(action.type){
        case PositionManagementConst.ADMIN_POSITIONS_FETCH_POSITIONS:
            return Object.assign({}, state, {positions:action.payload});
        default:
            return state;
    }
};

const positionsByPortaluser = (state={positions:[]}, action=null) => {
    switch(action.type){
        case PositionManagementConst.ADMIN_POSITIONS_FETCH_POSITIONS_BY_PORTALUSER:
            return Object.assign({}, state, {positions:action.payload});
        case PositionManagementConst.ADMIN_POSITIONS_ADD_POSITION_FOR_PORTALUSER:
            return Object.assign({}, state, {positions:[...state.positions,action.payload]});
        case PositionManagementConst.ADMIN_POSITIONS_DELETE_POSITION_FOR_PORTALUSER:
            return Object.assign({}, state, {positions:state.positions.filter(el => el.jobId !== action.payload.entity.jobId)});
        default:
            return state;
    }
};

const selectedCustomer = (state={customer:null}, action=null) => {
    switch(action.type){
        case PositionManagementConst.ADMIN_POSITIONS_SELECT_CUSTOMER:
            return Object.assign({}, state, {customer:action.payload.customer});
        default:
            return state;
    }
};

const selectedPortaluser = (state={portaluser:null}, action=null) => {
    switch(action.type){
        case PositionManagementConst.ADMIN_POSITIONS_SELECT_PORTALUSER:
            return Object.assign({}, state, {portaluser:action.payload.portaluser});
        default:
            return state;
    }
};

const positionManagement = combineReducers({customer, portaluser, positions, positionsByPortaluser, selectedCustomer, selectedPortaluser});
export  {positionManagement};