import {useHistory} from "react-router";
import {Button} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";

interface Props {
    children?: React.ReactNode
}

export function StaticFileView(props: Props) {
    const history = useHistory()
    const {t} = useTranslation(["common"])

    const goBack = () => {
        history.go(-1)
    }
    return (
        <div className="flex flex-column justify-content-center align-content-center">
            <div className="flex flex-column justify-content-center align-content-center text-center">
                <Button color={"primary"} variant={"outlined"} onClick={(e) => goBack()}>
                    {t("imprint.buttonback")}
                </Button>
                <br/>
            </div>
            {
                props.children
            }
        </div>
    )
}