import AppConst from "../../AppConst";
import AdminConst from "./AdminConst";
import RequestEntity from "../../api/RequestEntity";
import URLConstants from "../../api/URLConstants";

const BASE_URL = URLConstants.getURL();

const AdminActions = {
  bootstrap: () => {
    return {type:AppConst.AJAX_CALL, entity: new RequestEntity(BASE_URL + "/portal/bootstrap/start", "GET", AdminConst.BOOTSTRAP_FINISH)}
  },
  fetchCustomer: () => {
    return {type: AppConst.AJAX_CALL, entity: new RequestEntity(BASE_URL + "/portal/customer/sync", "GET", AdminConst.FETCH_CUSTOMER)}
  },
  fetchRecruiterByCustomer: (customerId) => {
    return {type: AppConst.AJAX_CALL, entity: new RequestEntity(BASE_URL + "/portal/recruiter/" + customerId + "/list", "GET", AdminConst.FETCH_RECRUITER)}
  },
  fetchPositionsByRecruiter: (customerId, recruiterId) => {
    return {type: AppConst.AJAX_CALL, entity: new RequestEntity(BASE_URL + "/portal/job/" + customerId + "/list?recruiterId=" + recruiterId)}
  },
  fetchProfilesByCustomer: (customerId) => {
    return {type: AppConst.AJAX_CALL, entity: new RequestEntity(BASE_URL + "/portal/profile/sync", "GET", AdminConst.FETCH_PROFILES_CUSTOMER)}
  },
  fetchProfilesAssociated: (customerId) => {
    return {type: AppConst.AJAX_CALL, entity: new RequestEntity(BASE_URL + "/portal/profile/associated/" + customerId, "GET", AdminConst.FETCH_PROFILES_ASSOCIATED)}
  },
  selectCustomer: (customerId) => {
    return {type: AdminConst.SELECT_CUSTOMER, payload: {customerId: customerId}}
  },
  deleteProfile: (profileId) => {
    return (dispatch, getState) => {
      dispatch({type: AppConst.AJAX_CALL, entity: new RequestEntity(BASE_URL + "/portal/profile/associated/"+ getState().admin.selectedCustomer.customer.value + "/" + profileId, "DELETE", AdminConst.DELETE_PROFILE)});
    }
  },
  addProfile: (ref) => {
    return (dispatch, getState) => {
        dispatch({type: AppConst.AJAX_CALL, entity: new RequestEntity(BASE_URL + "/portal/profile/" + getState().admin.selectedCustomer.customer.value, "POST", AdminConst.ADD_PROFILE), payload:ref})
    }
  },
  addProfileToCustomer:(profileId, customerId) => {
    return (dispatch, getState) => {
      dispatch({type: AppConst.AJAX_CALL, entity: new RequestEntity(BASE_URL + "/portal/profile/associated/" + getState().admin.selectedCustomer.customer.value + "/" + profileId, "POST", AdminConst.ADD_PROFILE)})
    }
  }
};
export default AdminActions;
