import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import {useProfileDelete} from "../state/ProfileDeleteModalState";
import {useTranslation} from "react-i18next";

export const ProfileDeleteModal = () => {
  const [profile, isOpen, close, _delete] = useProfileDelete((state) => [state.profile, state.isOpen, state.close, state.delete])
  const {t,i18n} = useTranslation("common")
  return (
    <Dialog open={isOpen} onClose={(e) => close()}>
      <DialogTitle >
        {t("dialogTitles.deleteProfile") + ":"} <br/><strong style={{color: "red"}}>{profile ? profile.name : ""}</strong>
      </DialogTitle>
      <DialogContent>
        <Typography variant={"body1"}>{t("dialogText.deleteProfile")}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant={"contained"} color={"warning"} onClick={() => _delete()}>{t("button.delete")}</Button>
        <Button variant={"contained"} color={"secondary"} onClick={() => close()}>{t("button.discard")}</Button>
      </DialogActions>
    </Dialog>
  )
}