import {AttendanceCreation, AttendanceCreationResult, Customer, CustomerTestvariant} from "../../../types";
import axios from "axios";
import URLConstants from "../../../api/URLConstants";
const url = URLConstants.getURL()

interface GetTestvariantResponse {
    result: CustomerTestvariant
}

interface GetTestvariants {
    result: CustomerTestvariant[]
}


export async function allocTestvariant(customerTestvariant: CustomerTestvariant): Promise<CustomerTestvariant> {
    const response = await axios.post<GetTestvariantResponse>(url + "/portal/testvariant", customerTestvariant);
    return response.data.result;
}

export async function deallocTestvariant(customerTestvariant: CustomerTestvariant): Promise<CustomerTestvariant> {
    const response = await axios.delete<GetTestvariantResponse>(url + "/portal/testvariant/" + customerTestvariant.id);
    return response.data.result;
}

export async function getTestvariants(customer: Customer): Promise<CustomerTestvariant[]> {
    const response = await axios.get<GetTestvariants>(url + "/portal/testvariant/customer/" + customer?.entity?.id);
    return response.data.result;
}

