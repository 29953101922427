/**
 * Created by stephanpudras on 19.10.18.
 */

import React from 'react';
import {connect} from 'react-redux';
import Finished from "./Finished";
import Started from "./Started";
import Inwork from "./Inwork";
import {Link, Route, withRouter, Redirect} from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import List from "./view/List";
import BaseActions from "./actions/BaseActions";

const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchProfiles:() => dispatch(BaseActions.fetchProfiles())
    }
};

class Results extends React.Component {

    componentDidMount() {
        this.props.fetchProfiles();
    }

    render() {
        return (
            <div>
                <Route path="/results/finished" component={Finished}/>
                <Route path="/results/started" component={Started}/>
                <Route path="/results/inwork" component={Inwork}/>
                <Route path="/results/list" component={List}/>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Results)