const PositionManagementConst = {

    ADMIN_POSITIONS_FETCH_CUSTOMER: "ADMIN_POSITIONS_FETCH_CUSTOMER",
    ADMIN_POSITIONS_FETCH_PORTALUSER: "ADMIN_POSITIONS_FETCH_PORTALUSER",
    ADMIN_POSITIONS_FETCH_POSITIONS: "ADMIN_POSITIONS_FETCH_POSITIONS",
    ADMIN_POSITIONS_FETCH_POSITIONS_BY_PORTALUSER: "ADMIN_POSITIONS_FETCH_POSITIONS_BY_PORTALUSER",
    ADMIN_POSITIONS_ADD_POSITION_FOR_PORTALUSER: "ADMIN_POSITIONS_ADD_POSITION_FOR_PORTALUSER",
    ADMIN_POSITIONS_DELETE_POSITION_FOR_PORTALUSER: "ADMIN_POSITIONS_DELETE_POSITION_FOR_PORTALUSER",
    ADMIN_POSITIONS_SELECT_CUSTOMER: "ADMIN_POSITIONS_SELECT_CUSTOMER",
    ADMIN_POSITIONS_SELECT_PORTALUSER: "ADMIN_POSITIONS_SELECT_PORTALUSER",
    ADMIN_POSITIONS_UPDATE_AFTER_ADD_POSITION_FOR_PORTALUSER: "ADMIN_POSITIONS_UPDATE_AFTER_ADD_POSITION_FOR_PORTALUSER",
    ADMIN_POSITIONS_UPDATE_AFTER_DELETE_POSITION_FOR_PORTALUSER: "ADMIN_POSITIONS_UPDATE_AFTER_DELETE_POSITION_FOR_PORTALUSER",
};

export default PositionManagementConst;