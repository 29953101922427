import React, {Component} from 'react';
import {connect} from 'react-redux';

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

/**
 * created by stephanpudras at 18.05.20
 */
class Flip extends Component {
    constructor(props) {
        super(props);
        this.state = {className:"row form-group"}
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.children.key !== prevProps.children.key){
            this.setState({className: "row form-group animate__animated animate__flipInX"});
            setTimeout(() => {this.setState({className: "row form-group"})}, 1000);
        }
    }


    render() {
        return (
            <div className={this.state.className}>
                <div className="col-12">
                    {
                        this.props.children
                    }
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Flip);