import {createTheme} from "@mui/material/styles";
import {grey, yellow} from "@mui/material/colors";

const defaultTheme = createTheme({
    palette: {
        black: {
            darker: "#000000",
            main: "#000000",
            contrastText: '#fff',
        },
        secondary:{
            light: grey[100],
            main: grey[200],
            dark: grey[400],
        },
        neutral: {
            darker: "#e7e7e7",
            main: "#EEEEEE",
            contrastText: '#9d9d9d',
        }
    },
});

export {defaultTheme};