import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton
} from "@mui/material";
import {useTranslation} from "react-i18next";
import React from "react";
import {Close} from "@mui/icons-material";

interface DialogProps {
    open: boolean,
    close: Function
    missingProps: string[]
}


export function MissingPropertiesDialog({open, close, missingProps}:DialogProps) {
    const {i18n, t} = useTranslation()
    const shouldBeOpen = ():boolean => {
        return true;
    }
    const getTitle = () => {
        console.log(missingProps)
        if((missingProps.indexOf("SELECTION") > -1) && missingProps.length > 1){
            return t("dialog.title_both", {ns:"common"})
        }else if(missingProps.length > 0 && missingProps.indexOf("SELECTION") < 0){
            return t("dialog.title_props", {ns:"common"})
        }else{
            return t("dialog.title_selection", {ns:"common"})
        }
    }

    const getMissingPropText = () => {
        let text = " "
        missingProps.forEach((prop, index) => {
            let offset = 1
            if(missingProps.indexOf("SELECTION") > -1){
                offset = 2
            }
            if(prop !== "SELECTION"){
                let key = "dialog.missing_props." + prop
                if((index + offset) < missingProps.length){
                    text += t(key,{ns:"common"}) + ", "
                }else if ((index + offset + 1) === missingProps.length){
                    text += t("dialog.content_and",{ns:"common"}) + t(key,{ns:"common"})
                }
                else{
                    text += t(key,{ns:"common"}) + " "
                }

            }
        })
        return text;
    };
    const getText = () => {
        let singleProp = false
        if(missingProps.includes("SELECTION") && missingProps.length < 3){
            singleProp = true
        }
        if(!missingProps.includes("SELECTION") && missingProps.length < 2){
            singleProp = true
        }
        if((missingProps.includes("SELECTION")) && missingProps.length > 1){
            return <div>
                {singleProp ? t("dialog.content_prop", {ns:"common"}) : t("dialog.content_both", {ns:"common"})}
                <strong>{getMissingPropText()}</strong>
                {t("dialog.content_both_and", {ns:"common"})}
            </div>
        }else if(missingProps.length > 0 && missingProps.indexOf("SELECTION") < 0){
            return <div>
                {singleProp ? t("dialog.content_prop",{ns:"common"}) : t("dialog.content_props", {ns:"common"})}
                <strong>{getMissingPropText()}</strong>
                {t("dialog.content_props_end", {ns:"common"})}
            </div>
        }else{
            return <div>{t("dialog.content_selection", {ns:"common"})}</div>
        }
        return undefined;
    };

    return (
        <Dialog open={open} onClose={(e) => {
            close(false)}}>
            <DialogContent>
                <Box style={{display:"flex", flexDirection:"row"}}>
                    <h5>{t("dialog.hint", {ns:"common"})}</h5>
                    <Box style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", flexGrow:1}}>
                        <IconButton size="small" onClick={(e) => close(false)}>
                            <Close/>
                        </IconButton>
                    </Box>
                </Box>
                <br/>
                <Box>
                    {getText()}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button color={"primary"} onClick={(e) => close(false)}>{t("labels.close",{ns:"common"})}</Button>
            </DialogActions>
        </Dialog>
    )
}