/**
 * Created by stephanpudras on 15.10.18.
 *
 * The base for the administration module
 */

import React from 'react';
import {connect} from 'react-redux';
import {Route} from "react-router-dom";
import Bootstrap from "./Bootstrap";
import Management from "./Management";
import Interviews from "./Interviews";
import AdminActions from "./AdminActions";
import PositionManagement from "./view/PositionManagement";
import AdminNameMapping from "./view/AdminNameMapping";
import TestvariantAllocation from "../../features/admin/view/TestvariantAllocation";

const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCustomer: () => dispatch(AdminActions.fetchCustomer()),
    }
};

class Administration extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount(){
        this.props.fetchCustomer()
    }

    render() {
        return (
            <div>
                <Route path="/admin/bootstrap" component={Bootstrap}/>
                <Route path="/admin/management" component={Management}/>
                <Route path="/admin/interviews" component={Interviews}/>
                <Route path="/admin/positions" component={PositionManagement}/>
                <Route path="/admin/nameMapping" component={AdminNameMapping}/>
                <Route path="/admin/testvariants" component={TestvariantAllocation}/>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Administration)