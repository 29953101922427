const AdminConst = {
  BOOTSTRAP_FINISH: "BOOTSTRAP_FINISH",
  FETCH_CUSTOMER: "FETCH_CUSTOMER",
  FETCH_RECRUITER: "FETCH_RECRUITER",
  FETCH_PROFILES: "FETCH_PROFILES",
  FETCH_PROFILES_CUSTOMER: "FETCH_PROFILES_CUSTOMER",
  SELECT_CUSTOMER: "SELECT_CUSTOMER",
  DELETE_PROFILE: "DELETE_PROFILE",
  ADD_PROFILE: "ADD_PROFILE",
  FETCH_PROFILES_ASSOCIATED: "FETCH_PROFILES_ASSOCIATED",

  NM_FETCH_CUSTOMER: "NM_FETCH_CUSTOMER",
  ADD_NAME_MAPPING: "ADD_NAME_MAPPING",
  REMOVE_NAME_MAPPING: "REMOVE_NAME_MAPPING"
};

export default AdminConst;
