class RequestEntity {
    constructor(url,method,context, callback, meta){
        this.url = url;
        this.method = method;
        this.context = context;
        this.callback = callback;
        this.meta = meta;
        this.setUrl = this.setUrl.bind(this);
        this.getUrl = this.getUrl.bind(this);
        this.setMethod = this.setMethod.bind(this);
        this.getMethod = this.getMethod.bind(this);
        this.setContext = this.setContext.bind(this);
        this.getContext = this.getContext.bind(this);
        this.setCallback = this.setCallback.bind(this);
        this.getCallback = this.getCallback.bind(this);
        this.setMeta = this.setMeta.bind(this);
        this.getMeta = this.getMeta.bind(this);
    }

    setUrl(URLString){
        this.url = URLString;
    }

    getUrl(){
        return this.url;
    }

    setMethod(HttpMethod){
        this.method = HttpMethod;
    }

    getMethod(){
        return this.method;
    }

    setContext(context){
        this.context = context;
    }

    getContext(){
        return this.context;
    }

    setCallback(callback){
        this.callback = callback;
    }

    getCallback(){
        return this.callback;
    }

    setMeta(meta){
        this.meta = meta;
    }

    getMeta(){
        return this.meta;
    }
}

export default RequestEntity;
