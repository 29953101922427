import React, { Component } from 'react';
import { connect } from 'react-redux';
import AdminActions from './AdminActions';
import Select from "react-select";
import ReactTable from "react-table-6/react-table";
import 'react-table-6/react-table.css'



 class Management extends Component {
   constructor(props){
     super(props);
     this.fetchAll = this.fetchAll.bind(this);
   }

  componentDidMount(){

  }

  fetchAll(customerId){
    this.props.selectCustomer(customerId);
    this.props.fetchRecruiter(customerId.value);
    this.props.fetchProfiles(customerId.value);
    this.props.fetchProfilesAssociated(customerId.value);
  }

  tableHeaders = () => {
      return [
          {
              Header: "ID",
              accessor: "id"
          },
          {
              Header: "Name",
              accessor: "name"
          },
          {
              Header: "",
              accessor: "delete",
              Cell: row => {
                  if(row.original.associated) {
                      return <button className="btn btn-danger btn-sm"
                                     onClick={(e) => this.props.deleteProfile(row.original.id)}>LÖSCHEN</button>
                  }else{
                      return null;
                  }
              }
          },
          {
              Header: "",
              accessor: "add",
              Cell: row => {
                  if(!row.original.associated ){
                      return <button className="btn btn-info btn-sm" onClick={(e) => this.props.addProfileToCustomer(row.original.id, this.props.selectedCustomer.customer.value)}>{"+ " + row.original.name}</button>
                  }else{
                      return null;
                  }
              }              
          }
      ];
  };

  render() {
    return (
      <div>
        <div>
          <h4>Kunden</h4>
          <ul>
            <Select
              placeholder={"Kunde"}
              value={this.props.selectedCustomer.customer}
              onChange={(e) => this.fetchAll(e)}
              options={this.props.customer}
            />
          </ul>
        </div>
        <div>
          <h5>Recruiter</h5>
          <ul>
            {
              this.props.recruiter.data.map((rec,index) => {
                return <li key={"ad_rec_" + index}><a>{rec.user.userName}</a></li>
              })
            }
          </ul>
        </div>
        <div>
          <h5>Profiles</h5>
                       <ReactTable
                        columns={this.tableHeaders()}
                        data={this.props.profiles}
                        defaultPageSize={10}
                        keyField='identification'
                        className="-striped -highlight"
                        previousText={"Zurück"}
                        nextText={'Vorwärts'}
                        loadingText={'Lade...'}
                        noDataText={"Keine Daten gefunden..."}
                        pageText={'Seite'}
                        ofText={'von'}
                        rowsText={'Zeilen'}
                        filterable
                    />
        </div>
      </div>
    )
  }
}

const customerSelectMapping = (customer) => {
  let customers = [];
  customer.data.map((customer, index) => {
    customers.push({label:customer.entity.name, value: customer.entity.id})
  });
  return customers;
};

const profileMapping = (profiles, associatedProfiles) => {
    let tdata = [];
    profiles.data.map((profile) => {
        let entry = {};
        entry['id'] = profile.entity.id;
        entry['name'] = profile.entity.name;
        let tmp = null;
            tmp = associatedProfiles.data.find(aProfile => profile.entity.id === aProfile.profileId);
        entry['associated'] = tmp != null;
        tdata.push(entry)
    });
    return tdata;
};

const mapStateToProps = (state) => ({
  customer: customerSelectMapping(state.admin.customer),
  recruiter: state.admin.recruiter,
  profiles: profileMapping(state.admin.profiles, state.admin.associatedProfiles),
  selectedCustomer: state.admin.selectedCustomer
});

const mapDispatchToProps = (dispatch) => ({
  fetchCustomer: () => dispatch(AdminActions.fetchCustomer()),
  fetchRecruiter: (customerId) => dispatch(AdminActions.fetchRecruiterByCustomer(customerId)),
  fetchProfiles: (customerId) => dispatch(AdminActions.fetchProfilesByCustomer()),
  selectCustomer: (customerId) => dispatch(AdminActions.selectCustomer(customerId)),
  deleteProfile: (profileId) => dispatch(AdminActions.deleteProfile(profileId)),
  //addProfile: (profileId, customerId) => dispatch(AdminActions.addProfile(profileId, customerId)),
  fetchProfilesAssociated: (customerId) => dispatch(AdminActions.fetchProfilesAssociated(customerId)),
  addProfileToCustomer: (customerId) => dispatch(AdminActions.addProfileToCustomer(customerId))
});

export default connect(mapStateToProps, mapDispatchToProps)(Management);
