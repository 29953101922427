import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import store, {AppDispatch, AppThunk} from "../../../../store";
import {AttendanceCreationResult, AttendanceCreationStatus, Position} from "../../../../types";

const initialState:AttendanceCreationStatus = {
    pending: false,
    finished: false,
    canceled: false,
    success: false,
    message: "",
    open: false
}

const creationStatusSlice = createSlice({
    name: "creationStatus",
    initialState,
    reducers: {
        changeStatus(state, action:PayloadAction<AttendanceCreationStatus>){
            return action.payload
        },
        creationFinished(state, action:PayloadAction<AttendanceCreationResult>){
            return {
                ...state,
                finished: true,
                pending: false,
                success: action.payload.success,
                message: action.payload.message
            }
        }
    }
})

export const changeCreationStatus = (stat:AttendanceCreationStatus):AppThunk => async (dispatch: AppDispatch) => {
    dispatch(creationStatusSlice.actions.changeStatus(stat))
}

export const creationFinishedStatus = (result: AttendanceCreationResult):any => async (dispatch:AppDispatch) => {
    dispatch(creationStatusSlice.actions.creationFinished(result))
}

export default creationStatusSlice.reducer