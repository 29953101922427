import {withTranslation} from "react-i18next";

const ListFormSchema = {
    components:[
        {
            name: "position",
            validation: (value) => {
                return value == null || value === "undefined" || value === ""
            },
            message:{
                en: "Please enter a position description, or alternatively upload your own code list.",
                de: "Bitte tragen Sie eine Positionsbezeichnung ein, oder laden Sie alternativ eine eigene Codeliste hoch."
            }
        },
        {
            name: "profile",
            validation: (value) => {
                return value == null || value === "undefined" || value === ""
            },
            message:{
                en: "Please decide on a requirement profile or a sorting criterion.",
                de: "Bitte entscheiden Sie sich für ein Anforderungsprofil oder für ein Sortierungskriterium."
            }
        },
        {
            name: "sort",
            validation: (value) => {
                return value == null || value === "undefined" || value === ""
            },
            message:{
                en: "Please decide on a sorting criterion or a requirement profile.",
                de: "Bitte entscheiden Sie sich für ein Anforderungsprofil oder für ein Sortierungskriterium."
            }
        },
        {
            name: "locale",
            validation: (value) => {
                return value == null || value === "undefined" || value === ""
            },
            message:{
                en: "Please select a language.",
                de: "Bitte wählen Sie eine Sprache aus."
            }
        },
        {
            name: "attendance",
            validation: (value) => {
                return value == null || value === "undefined" || value === ""
            },
            message:{
                en: "If no code list is chosen, at least one attendance from the table below has to be selected.",
                de: "Wenn keine Code-Liste ausgewählt ist, bitte mindestens einen Testzugang aus der unten befindlichen Tabelle wählen."
            }
        } ,
        {
            name: "file",
            validation: (value) => {
                    if(value == null || value === "undefined" || value === "")
                        return true
                    if(value && value.length < 1)
                        return true
            },
            message:{
                en: "Select the codes to be analyzed from the overview below or alternatively upload your own code list here.",
                de: "Wählen Sie Codes aus der unten-stehenden Übersicht oder laden Sie hier eine eigene Code-Liste hoch."
            }
        } ,
        {
            name: "testvariant",
            validation: (value) => {
                return value == null || value === "undefined" || value === ""
            },
            message:{
                en: "If no mapping file is chosen, at least on attendance from the table below has to be selected",
                de: "Wenn keine Mapping-Datei ausgewählt ist, muss ein Testzugang aus der unten befindlichen Tabelle gewählt werden."
            }
        },
        {
            name: "format",
            validation: (value) => {
                return value == null || value === "undefined" || value === ""
            },
            message:{
                en: "If no file format is selected, downloads won't be possible",
                de: "Bitte wählen Sie ein Dateiformat für den Export aus."
            }
        }
    ]
}

const listFormSchemaTrans = withTranslation(["common"])(ListFormSchema);
export default ListFormSchema;