import {combineReducers} from "redux";
import creationProperties from "./create/slices/creationPropertiesSlice"
import creationStatus from "./create/slices/creationStatusSlice"
import selectedPosition from "./create/slices/selectedPositionSlice"
import attendances from "./create/slices/attendanceCreationSlice"
import attendancesAll from "./create/slices/attendanceAllSlice"
import availableTestvariants from "./create/slices/availableTestvariantsSlice"
import availablePositions from "./create/slices/creationPositionsSlice"

const attendance = combineReducers({
    creationProperties,
    creationStatus,
    selectedPosition,
    attendances,
    attendancesAll,
    availableTestvariants,
    availablePositions
})

export default attendance