import * as React from "react";
import ListFormSchema from "../schema/ListFormSchema";
import ListFormContext from "./ListFormContext";

export class ListFormProvider extends React.Component {
    constructor(props) {
        super(props);
    }

    render(){
        return(
            <ListFormContext.Provider value={this.props.formSchema}>
                {
                    this.props.children
                }
            </ListFormContext.Provider>
        )
    }
}