import AppConst from '../../AppConst';
import HomeConst from './HomeConst';
import RequestEntity from '../../api/RequestEntity';
import URLConstants from "../../api/URLConstants";
import ResultsConst from "../results/const/ResultsConst";
import {differenceInDays, parseISO, subDays} from "date-fns";


const BASE_URL = URLConstants.getURL();

const HomeActions = {
  fetchAll: () => {
    return (dispatch, getState) => {
      let customerId = getState().app.portal.userInfo.customer.id ?? "";
      let lastAccess = getState().app.portal.userInfo.userSessions.mostRecentSessionLastAccess;
        let date = new Date()
        let days = differenceInDays(parseISO(lastAccess), parseISO(date.toISOString()));
      let targetDate = lastAccess;
      if(days < 7 || lastAccess === undefined || lastAccess === null)
          targetDate = subDays(parseISO(date.toISOString()), 7).toISOString();
      dispatch(
        {
          type: AppConst.AJAX_CALL,
          entity: new RequestEntity(BASE_URL + "/portal/personal/attendance/" + customerId + "/list?finished=true&active=true&unfinished=true&finishedAt=" + encodeURIComponent(targetDate), "GET", HomeConst.FETCH_ALL, null, {locale: getState().locale.i18next.language})
        }
     );
    }
  },
  fetchPositions: () => {
    return (dispatch, getState) => {
      let customerId = null;
      if(getState().app.portal.userInfo != null){
        let customerId = getState().app.portal.userInfo.customer.id;
        dispatch({type: AppConst.AJAX_CALL, entity: new RequestEntity(BASE_URL + "/portal/personal/job/" + customerId + "/list", "GET", HomeConst.FETCH_POSITIONS)})
      }else{
        dispatch({type: HomeConst.FETCH_POSITIONS_ERROR})
      }
    }
  },
  setFinishedExpanded: (newExpanded, index, event) => {
      return {type:HomeConst.SET_FINISHED_WIDGET_EXPANDED, payload:{newExpanded: newExpanded, index: index, event: event}};
  },
    fetchRange: (identification) => {
        return (dispatch, getState) => {
            let state = getState().home.percentRanges;
            let attendance = getState().home.finished.data.find(att => att.entity.identification === identification);
            if(state.find((item) => item.loginCode === identification) == null || state.length === 0)
                dispatch({type:AppConst.AJAX_CALL, entity:new RequestEntity(BASE_URL + "/portal/range/" + identification + "?finishedAt=" + attendance.ref.finishedAt, "GET", HomeConst.GET_WIDGET_RANGES, null)})
        }
    },
};

export default HomeActions;
