import {create} from "zustand";
import {devtools} from "zustand/middleware";

interface ShareResponseState {
  isOpen: boolean
  isSuccess: boolean
  message: string
  open: (message:string, success: boolean) => void
  close: () => void
}

const useShareResponse = create<ShareResponseState>()(devtools((set,get) => ({
  isOpen: false,
  isSuccess: false,
  message:"",
  open: (message:string, success:boolean) => {
    set({isOpen:true, isSuccess:success, message:message})
  },
  close: () => {
    set({isOpen:false})
  }
})))

export {useShareResponse}