/**
 * Superagent middleware. Is placed between action creator and store within the redux context.
 *
 * In order to invoke it, the actiontype should be "AJAX_CALL" (this could be changed, though...).
 * Uses the standard React action object {type: "sometype", payload: "theDataToSend"} plus "RequestEntity", which holds
 * the URL, the HTTP method to use and the return-actiontype (e.g.: FETCH_CUSTOMER).
 * Finer grained reducer could implement start-stop variations (e.g. FETCH_CUSTOMER_START, FETCH_CUSTOMER_FINISHED) for UI changes (Loadingbar etc.)
 *
 * As always, actiontypes should be coming from constants to avoid typos.
 *
 * The code can easily be edited or changed as needed
 *
 *
 *createdBy@Stephan Pudras
 */

 import request from 'superagent';
 import AppConst from "../AppConst";

 let actionEl = null;
 let keycloak = null;
 let tStore = null;

 const tokenRenewal = (store) => {
     if(keycloak != null && keycloak.hasOwnProperty("keycloak")){
         keycloak.keycloak.updateToken(5).then(refresh => {
             if(refresh){
                 console.log("Refresh")
                 if(tStore != null) {
                     store.dispatch({type: AppConst.LOGIN, login: true, payload: keycloak.keycloak})
                 }
             }
         })
     }
     //let k = {...store.getState().keycloak}
     //store.getState().keycloak.updateToken(5).then(() => {console.info("Token refreshed")});
 };

 const api = store => next => action => {
     next(action);
     if(action.type === AppConst.AJAX_CALL){
         keycloak = store.getState().app.keycloak;
         actionEl = action;
         tStore = store
         const agent  = request
                          .agent()
                          //.use(tokenRenewal)
                          .set('Content-Type', 'application/json')
                          .set('Authorization', 'Bearer ' + store.getState().app.keycloak.keycloak.token)
                          ;
         switch (action.entity.getMethod()) {
             case "POST":
                 agent
                     .post(action.entity.getUrl())
                     .send(action.payload)
                     .end((error, res) => {
                         if(error) {
                             return next ({
                                 type: AppConst.AJAX_ERROR,
                                 payload:error
                             });
                         }
                         const data = JSON.parse(res.text);
                         if(action.entity.getCallback() != null && action.entity.getCallback() !== "undefined"){
                             let cb = action.entity.getCallback();
                             setTimeout(store.dispatch(cb), 1000);
                         }
                         next({
                             type:action.entity.getContext(),
                             payload:data,
                             meta: action.entity.meta
                         });

                     });
                 break;
             case "PUT":
                 agent
                     .put(action.entity.getUrl())
                     .send(action.payload)
                     .end((error, res) => {
                         if(error) {
                             return next ({
                                 type: AppConst.AJAX_ERROR,
                                 payload:error
                             });
                         }
                         const data = JSON.parse(res.text);
                         if(action.entity.getCallback() != null && action.entity.getCallback() !== "undefined"){
                             let cb = action.entity.getCallback();
                             setTimeout(store.dispatch(cb), 1000);
                         }
                         next({
                             type:action.entity.getContext(),
                             payload:data,
                             meta: action.entity.meta
                         });
                         if(action.hasOwnProperty("context") && action.hasOwnProperty("contextId"))
                             if(action.contextId !== null && action.context !== null) {
                                 let cb = action.entity.getCallback();
                                 store.dispatch(cb(action.context, action.contextId));
                             }
                     });
                 break;
             case "GET":
                 //store.dispatch({type:action.entity.getContext() + "_START"});  -- Simple way to have a specific actiontype, which would be called everytime before the actual RequestEntity
                 //is sent. This could, for example, show a loading animation
                 agent
                     .get(action.entity.getUrl())
                     /**
                      * header options, could also implement "Authorization: Bearer euihsfdiufhsdf78...", get it with e.g. store.getState().app.jwtoken, if that reducer exists
                      * Token would then be sent with every ajax call.
                      *
                      */
                     .send()
                     .end((error, res) => {
                         if(error) {
                             return next({
                                 type: AppConst.AJAX_ERROR,
                                 payload: error
                             });
                         }
                         const data = JSON.parse(res.text);
                         if(action.entity.getCallback() != null && action.entity.getCallback() !== "undefined"){
                             let cb = action.entity.getCallback();
                             setTimeout(store.dispatch(cb), 1000);
                         }
                         next({
                             type:action.entity.getContext(),
                             payload: data,
                             meta: action.entity.meta
                         });
                         //store.dispatch({type:action.entity.getContext() + "_FINISHED"}); -- same as above but at the end of the request. Could be used for removal of temporary UI items (loading animation etc.)
                     });
                 break;
             case "DELETE":
                 agent
                     .delete(action.entity.getUrl())
                     .send()
                     .end((error, res) => {
                         if(error) {
                             return next({
                                 type: AppConst.AJAX_ERROR,
                                 payload: error
                             });
                         }
                         const data = JSON.parse(res.text);
                         if(action.entity.getCallback() != null && action.entity.getCallback() !== "undefined"){
                             let cb = action.entity.getCallback();
                             setTimeout(store.dispatch(cb), 1000);
                         }
                         next({
                             type:action.entity.getContext(),
                             payload: data,
                             meta: action.entity.meta
                         });
                         if(action.hasOwnProperty("contextId") && action.hasOwnProperty("context"))
                             if(action.contextId !== null && action.contextId !== null){
                                 let cb = action.entity.getCallback();
                                 store.dispatch(cb(action.context, action.contextId));
                             }
                     });
                 break;
             default:
                 break;
         }
     }
 };

 export default api;
