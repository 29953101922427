import ResultsConst from "../module/results/const/ResultsConst";
import {combineReducers} from "redux";
import GlobalConst from "../const/GlobalConst";

const inwork = (state=[], action=null) => {
    switch(action.type){
        case ResultsConst.FETCH_INWORK:
            return Object.assign([], action.payload.active);
        default:
            return state;
    }
};

const started = (state=[], action=null) => {
    switch(action.type){
        case ResultsConst.FETCH_STARTED:
            return Object.assign([], action.payload.unfinished);
        default:
            return state;
    }
};

const finished = (state=[], action=null) => {
    switch(action.type){
        case ResultsConst.FETCH_FINISHED:
            return Object.assign([], action.payload.finished);
        default:
            return state;
    }
};

const attendances = combineReducers({inwork, started, finished});
export default attendances;