const Timespan = {
    ONE_DAY: "ONE_DAY",
    ONE_WEEK: "ONE_WEEK",
    TWO_WEEKS: "TWO_WEEKS",
    FOUR_WEEKS: "FOUR_WEEKS",
    TWO_MONTHS: "TWO_MONTHS",
    THREE_MONTHS: "THREE_MONTHS",
    FOUR_MONTHS: "FOUR_MONTHS",
    FIVE_MONTHS: "FIVE_MONTHS",
    SIX_MONTHS: "SIX_MONTHS",
    EIGHT_MONTHS: "EIGHT_MONTHS",
    TWELVE_MONTHS: "TWELVE_MONTHS",
};

export {Timespan};