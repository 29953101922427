/**
 * Created by stephanpudras on 2019-01-29.
 */

import React from 'react';
import {withTranslation} from "react-i18next";
import {format, parseISO} from "date-fns";
import {enGB as en } from "date-fns/locale";
import {de} from "date-fns/locale";

class FeedbackTooltip extends React.Component {
    constructor(props){
        super(props);
    }

    formatDate = (date, locale) => {
        switch(locale){
            case "de":
                return format(parseISO(date), "dd.MM.yyyy HH:mm:ss", {locale: de});
            case "en":
                return format(parseISO(date), "MM/dd/yyyy hh:mm:ss", {locale: en});
            default:
                return format(parseISO(date), "MM/dd/yyyy hh:mm:ss", {locale: en});
        }
    };

    render() {
        return (
            <div>
                <div><h5>{this.props.stats.identification}</h5></div>
                <div>{this.props.t("locale.long.de")}: {this.props.stats.dDe ? this.props.t("labels.yes") : this.props.t("labels.no")}</div>
                <div>{this.props.t("locale.long.en")}: {this.props.stats.dEn ? this.props.t("labels.yes") : this.props.t("labels.no")}</div>
                <div>{this.props.t("labels.time")}: {this.props.stats != null && this.props.stats.date_created && true ? this.formatDate(this.props.stats.date_created, this.props.locale): ""} </div>
            </div>
        )
    }
}

const feedbackTT = withTranslation(["common"])(FeedbackTooltip);
export default feedbackTT