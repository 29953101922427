import React, { Component } from 'react';
import { connect } from 'react-redux';
import ResultChartOverview from "../results/ResultChartOverview";
import HomeActions from "../home/HomeActions";
import ReactTable from "react-table-6/react-table"
import {Link} from "react-router-dom";
import {format, getTime, isAfter, parseISO} from "date-fns";
import {de} from "date-fns/locale";
import {withTranslation} from "react-i18next";
import {enGB as en} from "date-fns/locale";


const getLocalizedDateShort = (date, locale) => {
    switch(locale){
        case "de":
            return format(parseISO(date), "dd.MM.yyyy HH:mm:ss", {locale: de});
        case "en":
            return format(parseISO(date), "MM/dd/yyyy hh:mm:ss", {locale: en});
        default:
            return format(parseISO(date), "MM/dd/yyyy hh:mm:ss", {locale: en});
    }
};

 class Finished extends Component {
     constructor(props, context) {
         super(props, context);
         this.getTableEntries = this.getTableEntries.bind(this);
         this.state = {entries:3};
         this.setEntryCount = this.setEntryCount.bind(this);
     }

    tableHeaders = () => {
        return [
            {
                Header: this.props.t("tHeadLabels.logincode"),
                accessor: "ref.identification"
            },
            {
                Header: this.props.t("tHeadLabels.finished"),
                accessor: "ref.finishedAt",
                sortMethod: (a, b) => {
                    let oA = getTime(parseISO(a));
                    let oB = getTime(parseISO(b));
                    if(oA < oB)
                        return 1;
                    else
                        return -1;
                },
                Cell: row => {
                    return getLocalizedDateShort(row.original.ref.finishedAt, this.props.i18next.language)
                }

            },
            {
                Header: this.props.t("tHeadLabels.locale"),
                accessor: "ref.locale"
            },
            {
                Header: this.props.t("tHeadLabels.test"),
                accessor: "ref.testname"
            }
        ];
    };

     getTableEntries(){
         let arr = [];
         this.props.tableData.map(item => {
             if(isAfter(parseISO(item.ref.finishedAt), parseISO(this.props.lastLogin)))
                 arr.push(item.ref)
         });
         return arr;
     }

     setEntryCount(e){
         e.preventDefault();
         if(this.state.entries === 3)
            this.setState({entries: this.props.tableData.length > 3 ? this.props.tableData.length : 3})
         else
            this.setState({entries: 3})
     }

     render() {
         this.getTableEntries();
    return (
      <div className="card form-group">
          <div className="card-header"><strong>
              {this.props.t("homeCardHeader.finished")} <span className="float-right"><a href="#" onClick={(e) => this.setEntryCount(e)}>{  this.state.entries === 3 ? this.props.t("homeCardHeader.more") : this.props.t("homeCardHeader.less")}</a></span></strong>
          </div>
          <div className="card-body" style={{padding: "0"}}>
              <ReactTable
                  data={this.props.tableData}
                  columns={this.tableHeaders()}
                  defaultPageSize={this.state.entries}
                  pageSize={this.state.entries}
                  keyField='identification'
                  className="-striped -highlight"
                  previousText={this.props.t("tableControls.backward")}
                  nextText={this.props.t("tableControls.forward")}
                  loadingText={this.props.t("tableControls.loading") + '...'}
                  noDataText={this.props.t("tableControls.nodata") + "..."}
                  pageText={this.props.t("tableControls.page")}
                  ofText={this.props.t("tableControls.of")}
                  rowsText={this.props.t("tableControls.rows")}
                  expanded={this.props.expanded}
                  onExpandedChange = {(newExpanded, index, event) => this.props.setFinishedExpanded(newExpanded, index, event)}
                  SubComponent = { row => {
                      this.props.fetchRange(row.original.ref.identification);
                      return <div style={{paddingLeft:"35px"}}> <ResultChartOverview percentRanges={this.props.percentRanges} identification={row.original.ref.identification}/></div>
                  }
                  }
                  showPagination={false}
                  defaultSorted={[
                      {
                          id: "finishedAt",
                          desc: false
                      }
                  ]}
              />
          </div>
      </div>
    )
  }
}

const finished = (finishedState) => {
    let finArr = [];
    finishedState.map(finishedItem => {
        finArr.push(finishedItem.ref)
    });
    return finArr;
};


const mapStateToProps = (state) => ({
    tableData: state.home.finished.data,
    expanded: state.home.finishedExpanded,
    percentRanges: state.home.percentRanges,
    lastLogin: state.app.portal.userInfo.userSessions.mostRecentSessionStarted,
    i18next: state.locale.i18next
});

const mapDispatchToProps = (dispatch) => ({
    setFinishedExpanded: (newExpanded, index, event) => dispatch(HomeActions.setFinishedExpanded(newExpanded, index, event)),
    fetchRange: (identification) => dispatch(HomeActions.fetchRange(identification))
});

const wFinishedTrans = withTranslation(["common"])(Finished);
export default connect(mapStateToProps, mapDispatchToProps)(wFinishedTrans);
