import React, {Component, Suspense, useEffect} from 'react';
import './App.css';
import {connect, useDispatch} from "react-redux";
import AppActions from "./AppActions";
import Keycloak from "keycloak-js";
import Sidebar from "./components/Sidebar";
import Home from "./module/home/Home";
import Customer from "./module/customer/Customer";
import {Link, Route, withRouter, Redirect} from "react-router-dom";
import URLConstants from './api/URLConstants';
import Administration from "./module/admin/Administration";
import Results from "./module/results/Results";
import Breadcrumb from "./components/Breadcrumb";
import {useTranslation, withTranslation} from "react-i18next";
import 'rc-tooltip/assets/bootstrap_white.css';
import Tooltip from "rc-tooltip";
import 'flag-icon-css/css/flag-icon.css';
import 'animate.css';
import attendanceCRUD from "features/attendance/index"
import {fetchPositions} from "./bootstrap/slices/positionSlice";
import {loadPortalUserInfo} from "./bootstrap/api/portalUserInfoApi";
import {setAuthToken, setToken} from "./api/axiosConfig";
import {ImprintView} from "./features/static/ImprintView";
import {LegalView} from "./features/static/LegalView";
import {StaticFileView} from "./features/static/StaticFileView";
import {ContactView} from "./features/static/ContactView";
import {MissingPropertiesDialog} from "./module/results/view/MissingPropertiesDialog";
import ResultsActions from "./module/results/actions/ResultsActions";
import ReportState from "./const/ReportState";
import {ProfileView} from "./features/profile/ProfileView";
import {ProfileAdminUserView} from "./features/profile/ProfileAdminUserView";
import {ThemeProvider} from "@mui/material/styles";
import {defaultTheme} from "./theme";
import {ProfileDocumentModal} from "./features/profile/modal/ProfileDocumentModal";


const mapStateToProps = (state) => {
    return {
        keycloak: state.app.keycloak,
        cust: state.app.cust,
        loadingButton: state.app.loadingButton,
        sidebar: state.app.sidebar,
        sidebar_state: state.app.sidebar_state,
        portal: state.app.portal,
        i18next: state.locale.i18next,
        dialogOpen: state.results.dialog,

    }
};

const mapDispatchToProps = (dispatch) => {
    return {
      login: (keycloak) => dispatch(AppActions.login(keycloak)),
      customer: () => dispatch(AppActions.customer()),
      portalUserInfo: (portalUser) => dispatch(AppActions.portalUserInfo(portalUser)),
      sidebarOpenTopic: (topic, link) => dispatch(AppActions.sidebarOpenTopic(topic, link)),
      loadLocale: (locale) => dispatch(AppActions.loadLocale(locale)),
      logToServer: (error, info) => dispatch(AppActions.logToServer(error, info)),
      loadAllPositions: (token, customerId) => dispatch(fetchPositions(token, customerId)),
      closeDialog: (state) => dispatch(ResultsActions.closeDialog(state)),
      setLocale: (value, reportState) => dispatch(ResultsActions.setLocale(value, reportState)),
    }
};

const keycloak = new Keycloak({
    url: window.location.href.indexOf("abciportal.develability.net") > -1 ? "https://kc1.develability.net" :
        window.location.href.indexOf("portal-front-eval1.develability.net") > -1 ? "https://kc-eval1.develability.net" :
        window.location.href.indexOf("portal-front-eval2.develability.net") > -1 ? "https://kc-eval2.develability.net" :
            "http://localhost:8084/",
    realm: "Abci",
    clientId: "customerportal-frontend",
});

const SidebarComponent = withRouter(Sidebar);

class App extends Component {
    constructor(props){
      super(props);
      this.bootstrap = this.bootstrap.bind(this);
      this.props.loadLocale(this.props.i18n);
    }


    componentDidMount(){
        keycloak.init({onLoad: 'login-required', token: this.props.keycloak.token, refreshToken: this.props.keycloak.refreshToken}).then((authenticated) => {
            if (authenticated) {
                this.props.login(keycloak);
                    keycloak.updateToken(5).then(() => {
                           setToken(keycloak.token)
                           setAuthToken(keycloak.token)
                           this.props.login(keycloak);
                    }).catch(() => keycloak.logout());

                loadPortalUserInfo(keycloak.token).then( portalUser =>{
                    if(portalUser.customer){
                        this.props.loadAllPositions(keycloak.token, portalUser.customer.id)

                    }
                    this.props.portalUserInfo(portalUser);
                }).catch(reason => console.log(reason))
                this.bootstrap();
            }
        }).catch(reason => console.log(reason))
        keycloak.onTokenExpired = () => keycloak.updateToken(5).then(refreshed => {
            if(refreshed){
                console.log("Refresh")
                this.props.login(keycloak);
            }
        })

        if(this.props.i18n.language === "en"){
            this.props.setLocale({value:"EN", label: "English"}, ReportState.FINISHED)
        }else{
            this.props.setLocale({value:"DE", label: "Deutsch"}, ReportState.FINISHED)
        }
    }

    componentDidCatch(error, info) {
        //this.reactError = error;
        //this.setState({ catchError: true });
        //this.props.logToServer(error, info)
    }

    bootstrap(){

    }

    render() {
        return (

            <Suspense fallback="loading">
                <ThemeProvider theme={defaultTheme}>
                <div className="row flex-column flex-fill align-self-stretch align-items-stretch align-self-baseline" style={{minHeight:"100%", margin: "0 0"}}>
                    {
                        this.props.i18next != null ?
                            <div className="row flex-fill align-self-stretch align-items-stretch align-self-baseline" style={{minHeight:"100%", margin: "0 0"}}>
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12 col-xs-12 align-self-start sidebar-wrapper no-print">
                                    {
                                        typeof this.props.keycloak !== "undefined" ?
                                            (
                                                this.props.keycloak.hasOwnProperty("keycloak") ?
                                                    <SidebarComponent loadLocale={this.props.loadLocale} portal={this.props.portal} keycloak={this.props.keycloak} kinstance={keycloak} sidebar={this.props.sidebar} sidebarState={this.props.sidebar_state} handler={this.props.sidebarOpenTopic}/>
                                                    :
                                                    null
                                            )
                                            :
                                            null
                                    }

                                </div>
                                <div className="col-xl-10 col-lg-9 col-md-8 col-sm-12 col-xs-12 d-flex flex-column flex-grow-1" style={{paddingRight:"15px"}}>
                                    <div className="header align-self-start no-print">
                                        <div id="logowrapper"><img className="logo-image" src="/img/logo-ackerschott.gif"/></div>
                                    </div>
                                    <Breadcrumb location={this.props.location}/>
                                    {
                                        this.props.keycloak.keycloak != null && this.props.portal.userInfo != null ?
                                            <div className="flex-grow-1 align-self-stretch no-print">
                                                <Route exact path="/" render={() => (<Redirect to="/home"/>)}/>
                                                <Route path="/home" component={Home}/>
                                                <Route path="/customer" component={Customer}/>
                                                <Route path="/results" component={Results}/>

                                                    <Route path="/profile/overview" component={ProfileAdminUserView}/>
                                                    <Route path="/admin" component={Administration}/>
                                                    <Route path="/attendance/create" component={attendanceCRUD.views.Create}/>
                                                    <Route path="/attendance/manage" component={attendanceCRUD.views.Manage}/>

                                                <Route path="/misc/contact"><StaticFileView><ContactView i18next={this.props.i18next}/></StaticFileView></Route>
                                                <Route path="/misc/imprint"><StaticFileView><ImprintView i18next={this.props.i18next}/></StaticFileView></Route>
                                                <Route path="/misc/legal"><StaticFileView><LegalView i18next={this.props.i18next}/></StaticFileView></Route>
                                            </div>
                                            :
                                            null
                                    }
                                    <div className="footer align-self-end no-print">
                                  <span>

                                      <Link to={"/misc/contact"}>{this.props.t("footer.contact")}</Link>&nbsp;

                                  </span>
                                        <span> | </span>
                                        <span>
                                            <Link to={"/misc/imprint"}>{this.props.t("footer.imprint")}</Link>
                                  </span>
                                        <span> | </span>
                                        <span>
                                            <Link to={"/misc/legal"}>{this.props.t("footer.legal")}</Link>
                                  </span>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                    }
                </div>
                <MissingPropertiesDialog open={this.props.dialogOpen.open} missingProps={this.props.dialogOpen.missingProperties} close={this.props.closeDialog} />
                    <ProfileDocumentModal/>
                </ThemeProvider>
            </Suspense>
        );
    }
}
const tr = withTranslation(["common"])(App);
export default connect(mapStateToProps, mapDispatchToProps)(tr);
