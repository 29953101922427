import {Button} from "@mui/material";
import {useTranslation} from "react-i18next";
import {i18n} from "i18next";
import {useHistory} from "react-router";

interface Props {
    i18next:i18n
}

export function ContactView(props: Props){
    const {t} = useTranslation(["common"])

    return(
        <div>
            <div style={{paddingLeft:"20rem", paddingRight: "20rem"}}>
                <h3>{t("contact.header")}</h3>
                <strong>{t("imprint.operator")}</strong>
                <br/>
                <br/>
                <p>
                    <strong>{t("imprint.company")}</strong>
                    <br/>
                    {t("imprint.street")}
                    <br/>
                    {t("imprint.zip")}
                    <br/>
                    <br/>
                </p>
                <p>
                    {t("imprint.phone")}
                    <br/>
                    {t("imprint.email")}
                    <br/>
                    <a href="https://www.abci.de">{t("imprint.homepage")}</a>
                    <br/>
                    <br/>
                </p>
            </div>
        </div>
    )
}