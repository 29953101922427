import React, {Fragment, useEffect, useState} from "react";
import {
  Box,
  Button,
  FormGroup,
  Link, Tooltip, tooltipClasses, TooltipProps,
  useTheme
} from "@mui/material";
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import ReactTable from "react-table-6/react-table";
import {useTranslation} from "react-i18next";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import {useSelector} from "react-redux";
import {RootState} from "../../reducer/rootReducer";
import {DeepClone} from "../../utils/DeepClone";
import {format, parseISO} from 'date-fns'
import {de, enGB, eo, ru} from 'date-fns/locale'
import {Delete, DocumentScanner, Edit, ManageSearch, Remove, Share} from "@mui/icons-material";
import {ThemeProvider} from "@mui/material/styles";
import {defaultTheme} from "../../theme";
import {ProfileShareModal} from "./modal/ProfileShareModal";
import URLConstants from "../../api/URLConstants";
import {ProfileEditModal} from "./modal/ProfileEditModal";
import {ProfileDeleteModal} from "./modal/ProfileDeleteModal";
import {useProfileEdit} from "./state/ProfileEditModalState";
import {useProfileDelete} from "./state/ProfileDeleteModalState";
import {useProfileShare} from "./state/ProfileShareModalState";
import {useProfileAdmin} from "./state/ProfileAdminState";
import {ProfileEditToast} from "./components/ProfileEditToast";
import {SharedProfile} from "../../types";
import {ProfileDeleteToast} from "./components/ProfileDeleteToast";
import {ProfileShareToast} from "./components/ProfileShareToast";
import styled from "@emotion/styled";
import {LoadURLByPath} from "../../utils/LoadURLByPath";
import {AbciDocumentProfileShort, useAbciProfileDocument} from "./state/AbciProfileDocument";
import {ProfileDocumentModal} from "./modal/ProfileDocumentModal";
import {AbciProfileDocumentMenu} from "./components/document/profile/AbciProfileDocumentMenu";


const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#4f4f4f',
    color: 'rgba(255,255,255,0.87)',
    maxWidth: 220,
    border: '1px solid #4f4f4f',
  },
}));

export const ProfileAdminUserView = ():JSX.Element => {
  const keycloak = useSelector(
    (state:RootState) => state.app.portal.userInfo
  )
  const [openDocument] = useAbciProfileDocument((state) => [state.open])
  const [openEdit] = useProfileEdit((state) => [state.open])
  const [openDelete] = useProfileDelete((state) => [state.open])
  const [openShare] = useProfileShare((state) => [state.open])
  const [tableData, initial, fetch, setUserId] = useProfileAdmin((state) => [state.tableData, state.initial, state.fetch, state.setUserId])
  const [own, toggleOwn] = useState<boolean>(false)
  const {t, i18n} = useTranslation("common")

  const theme = useTheme()

  useEffect(() => {
    setUserId(keycloak.entity.authUserId)
  },[keycloak])

  useEffect(() => {
    fetch(keycloak.customer.id)
  }, [fetch, initial, keycloak]);

  function handleDelete(shares:SharedProfile[], userId:string):boolean {
    if(shares.length === 0){
      return false
    }else {
      if(shares.length > 0){
        //let canDelete:boolean[] = []
        //shares.map(it => canDelete.push(it.canDelete))
        //if(keycloak.entity.authUserId === userId){
        //  return canDelete.includes(false)
        //}else{
        //  let share = shares.find(it => it.userId === keycloak.entity.authUserId)
        //  if(share && share.canDelete){
        //    return canDelete.includes(false)
        //  }
        //  return true
        //}
      }
      return true
    }
  }

  const tableHeader = [
    {
      Header: "",
      accessor: "name",
      maxWidth: 65,
      Cell: (row:any) => {
        return <>
          {
            row.original.abci ?
              <span className={"abci-logo-24"}></span>
              :
              <span className={"openhr-logo-24"}></span>
          }
        </>
      }
    },
    {
      Header: t("profileTable.name"),
      accessor: "name",
      Cell: (row:any) => {
        return <Tooltip title={row.original.name} arrow={true} placement={"top-end"} key={`table-tt-n-${row.original.id}`}>
          <Box sx={{display:"flex", flexDirection: "row", alignItems:"center"}}>
            {
              row.original.name
            }
          </Box>
        </Tooltip>

      }
    },
    {
      Header: t("profileTable.description"),
      accessor: "description",
      maxWidth: 160,
      minWidth: 160,
      Cell: (row:any) => {
        return <Tooltip title={row.original.description} arrow={true} placement={"right"} key={`table-tt-${row.original.id}`}>
          <Box  sx={{cursor: "pointer", textOverflow: "ellipsis", overflow:"hidden", whiteSpace:"nowrap", width: "150px"}}
          >
            {row.original.description}
          </Box>
        </Tooltip>
      }
    },
    {
      Header: t("profileTable.username"),
      accessor: "username"
    },
    {
      Header: t("profileTable.dateCreated"),
      accessor: "dateCreated",
      minWidth: 180,
      maxWidth: 180,
      Cell: (row:any) => {
        if(row.original.dateCreated !== ""){
          let date = parseISO(row.original.dateCreated)
          return format(date, i18n.language === "de" ? "dd.MM.yyyy HH:mm" : "dd/MM/yyyy hh:mm aaaa",{locale: i18n.language === "de" ? de : enGB})
        }else {
          return "n/a"
        }
      }
    },
    {
      Header: t("profileTable.dateUpdated"),
      accessor: "dateUpdated",
      minWidth: 180,
      maxWidth: 180,
      Cell: (row:any) => {
        if(row.original.dateUpdated !== ""){
          let date = parseISO(row.original.dateUpdated)
          return format(date, i18n.language === "de" ? "dd.MM.yyyy HH:mm" : "dd/MM/yyyy hh:mm aaaa",{locale: i18n.language === "de" ? de : enGB})
        }else{
          return "n/a"
        }
      }
    },
    {
      Header:"",
      accessor: "",
      minWidth: 295,
      maxWidth: 295,
      Cell: (row:any) =>{
        return (
          <Box key={`table-btn-${row.original.id}`} sx={{display: "flex", flexDirection: "row"}}>
            <Box sx={{marginRight: "10px"}}>
                <AbciProfileDocumentMenu profile={{id:row.original.id, name:row.original.name} as AbciDocumentProfileShort}/>
            </Box>

            {
            row.original.userId !== undefined ?
              <Box sx={{display: "flex", flexDirection: "row"}}>

                {
                  row.original.userId === keycloak.entity.authUserId || row.original.shares.find(it => it.userId === keycloak.entity.authUserId)?.canEdit ?
                    <Tooltip title={t("tooltip.profileEdit.edit")} arrow={true} placement={"top"}>
                      <span>
                    <Button sx={{marginRight:"10px"}} variant={"contained"} size={"small"} color={"primary"} onClick={(e) => {
                      openEdit(row.original.profile);
                    }}
                    ><Edit/></Button>
                      </span>
                    </Tooltip>
                    :
                    null
                }
                {
                  row.original.userId === keycloak.entity.authUserId ?
                    <Tooltip title={t("tooltip.profileShare.share")} arrow={true}>
                      <span>
                    <Button
                      sx={{marginRight:"10px"}}
                      variant={"contained"}
                      size={"small"}
                      color={"info"}
                      onClick={() => {
                        openShare(row.original.profile, keycloak.entity.authUserId, keycloak.customer.id, row.original.shares)
                      }}
                    >
                      <Share/>
                    </Button>
                      </span>
                    </Tooltip>
                    :
                    null
                }
                {
                  row.original.userId === keycloak.entity.authUserId || row.original.shares.find(it => it.userId === keycloak.entity.authUserId)?.canDelete ?
                    <HtmlTooltip title={handleDelete(row.original.shares, row.original.userId) ?
                      <>
                      <div>
                        <h6>{t("tooltip.profileDelete.header")}</h6>
                      </div>
                      <div>
                        <p>{t("tooltip.profileDelete.context_no")}</p>
                        {
                          row.original.shares.map((s, idx) => <div key={`t-share-tt-${row.original.id}-${idx}`}>{s.username}</div>)
                        }
                      </div>
                      </>
                      :
                      <>
                        <div>
                          <h6>{t("tooltip.profileDelete.header")}</h6>
                        </div>
                      </>
                    }
                                 arrow={true}>
                      <span>
                    <Button
                      variant={"contained"}
                      size={"small"}
                      color={"warning"}
                      disabled={handleDelete(row.original.shares, row.original.userId)}
                      onClick={() => {openDelete(row.original.profile)}}
                    >
                      <Delete/>
                    </Button>
                      </span>
                    </HtmlTooltip>
                    :
                    null
                }
              </Box>
              :
              null
          }
          </Box>
        );
      }
    }
  ];



  return (
    <ThemeProvider theme={defaultTheme}>
    <Box>
      <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "5px 0px"}}>
        <FormGroup>
          <FormControlLabel control={<Switch color={"primary"} value={own} size={"small"} onChange={(e) => toggleOwn(!own)}/>} label={t("switch.only-own")} />
        </FormGroup>
        <Button variant={"contained"} color={"secondary"} startIcon={<span className={"openhr-logo-20"}></span>} href={`${LoadURLByPath.getOpenhr()}/account/owned`}>{t("button.hrefToOpenhr")}</Button>
      </Box>
      <Box>
        <ReactTable
          data={own ? tableData.filter(it => it.userId === keycloak.entity.authUserId) : tableData}
          columns={tableHeader}
          defaultPageSize={10}
          keyField='identification'
          className="-striped -highlight"
          previousText={t("tableControls.backward")}
          nextText={t("tableControls.forward")}
          loadingText={t("tableControls.loading") + '...'}
          noDataText={t("tableControls.nodata") + "..."}
          pageText={t("tableControls.page")}
          ofText={t("tableControls.of")}
          rowsText={t("tableControls.rows")}
          filterable
        />
      </Box>
      <ProfileEditModal/>
      <ProfileDeleteModal/>
      <ProfileShareModal/>
      <ProfileEditToast/>
      <ProfileDeleteToast/>
      <ProfileShareToast/>
    </Box>
    </ThemeProvider>
  );
}