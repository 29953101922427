import React from "react"
import BasicTable from "../../../../components/table/BasicTable";
import {Attendance, Attendance_Entity, AttendanceCreationProperties, PortalUser} from "../../../../types";
import {Cancel, Check, Close} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {Cell} from "react-table";
import {format, parseISO} from "date-fns"
import {de} from "date-fns/locale"

interface Data {
    data: Attendance[]
    raw: Attendance[],
    portalUser: any,
    creationProperties: AttendanceCreationProperties
}

export default function NewAttendanceTable(props: Data):JSX.Element{
    const {t} = useTranslation(["common"])
    const columns = [
            {
                Header: t("createAttendance.table.header.identification"),
                accessor: 'identification',
            },
            {
                Header: t("createAttendance.table.header.created"),
                accessor: 'createdAt',
                Cell: (cell:any) => {
                    return format(parseISO(cell.value), "dd-MM-yyyy hh:mm", {locale: de})
                }
            },
            {
                Header: t("createAttendance.table.header.new"),
                accessor: 'recent',
                Cell: (cell:any) => {
                    return cell.value ? <Check/> : <Close/>
                }

            }
        ]


    const getEntities = (data: Attendance[]) => {
        let attendances: Attendance_Entity[] = []
        props.data.forEach(entity => {
            attendances.push(entity.entity)
        })
        return attendances
    }

    return(
        <div>
            <BasicTable columns={columns} data={getEntities(props.data)} raw={props.raw} portalUser={props.portalUser} creationProperties={props.creationProperties}/>
        </div>
    )
}