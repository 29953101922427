import {useEffect} from "react";
import {useHistory} from "react-router";
import {History} from "history";
import {Button} from "@mui/material";
import {useTranslation} from "react-i18next";

interface Props {
    i18next: any
}

export function ImprintView(props:Props){
    const {t}= useTranslation(['common'])
    useEffect(() => console.log(props))

    return (
        <div style={{paddingLeft:"20rem", paddingRight: "20rem"}}>
            <h3>{t("imprint.header")}</h3>
            <strong>{t("imprint.operator")}</strong>
            <br/>
            <br/>
            <p>
                <strong>{t("imprint.company")}</strong>
                <br/>
                {t("imprint.street")}
                <br/>
                {t("imprint.zip")}
                <br/>
                <br/>
            </p>
            <p>
                {t("imprint.phone")}
                <br/>
                {t("imprint.email")}
                <br/>
                <a href="https://www.abci.de">{t("imprint.homepage")}</a>
                <br/>
                <br/>
            </p>
            <p>
                {t("imprint.court")}
                <br/>
                {t("imprint.hrb")}
                <br/>
                <br/>
            </p>
            <p>
                <strong>{t("imprint.tax")}</strong>
                <br/>
                {t("imprint.taxnum")}
                <br/>
                <br/>
            </p>
            <p>
                <strong>{t("imprint.ceohead")}</strong>
                <br/>
                {t("imprint.ceotitle")}
                <br/>
                {t("imprint.ceo")}
                <br/>
                <br/>
            </p>
            <div style={{whiteSpace: "pre-wrap"}}>
                {t("imprint.text")}
                <br/>
                <br/>
            </div>
            <div>
                <strong><i>abcÎ</i></strong>{t("imprint.copyright")}
                <br/>
                <br/>
            </div>
        </div>
    )
}